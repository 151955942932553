define("bottomline-execution/components/trip-all-remarks", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    trip: null,
    stopsWithRemarks: (0, _object.computed)('trip.sortedDisplayableTripStops', function () {
      return this.get('trip.sortedDisplayableTripStops').filterBy('hasRemarks');
    })
  });

  _exports.default = _default;
});