define("bottomline-execution/templates/components/toggle-show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "D7g5RLZ+",
    "block": "[[[11,0],[24,0,\"acoustic card-header\"],[4,[38,0],[[30,0],\"toggle\"],null],[12],[1,\"\\n\"],[41,[30,0,[\"translate\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"key\"],[[30,0,[\"title\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"title\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[10,\"label\"],[14,0,\"label-switch\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"showContent\"]]]],null],[1,\"\\n    \"],[10,0],[14,0,\"checkbox\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"card-content\"],[12],[1,\"\\n\"],[41,[30,0,[\"showContent\"]],[[[1,\"    \"],[10,0],[15,0,[29,[[52,[30,0,[\"padContent\"]],\"card-content-inner\"]]]],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&default\"],false,[\"action\",\"if\",\"editable-t\",\"input\",\"yield\"]]",
    "moduleName": "bottomline-execution/templates/components/toggle-show.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});