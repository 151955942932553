define("bottomline-execution/components/truck-inventory", ["exports", "@ember/object/computed", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _computed, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    appState: (0, _computed.alias)('shared.appState'),
    vehicleStorages: (0, _service.inject)(),
    currentTruckInventory: (0, _service.inject)(),
    truckInventoriesSorted: ['truck_storage_id'],
    trailerInventoriesSorted: ['truck_storage_id'],
    sortedTruckInventory: (0, _computed.sort)('truckInventories', 'truckInventoriesSorted'),
    sortedTrailerInventory: (0, _computed.sort)('trailerInventories', 'trailerInventoriesSorted'),
    hasTruckStorages: (0, _object.computed)('appState.currentTruck', function () {
      return this.get('vehicleStorages').hasTruckStorages();
    }),
    hasTrailerStorages: (0, _object.computed)('appState.currentTrailer', function () {
      return this.get('vehicleStorages').hasTrailerStorages();
    }),
    truckInventories: (0, _object.computed)('appState.currentTruck', function () {
      var truckId = this.get('appState.currentTruck');
      return this.get('currentTruckInventory').forVehicle(truckId);
    }),
    trailerInventories: (0, _object.computed)('appState.currentTrailer', function () {
      var trailerId = this.get('appState.currentTrailer');
      return this.get('currentTruckInventory').forVehicle(trailerId);
    })
  });

  _exports.default = _default;
});