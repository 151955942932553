define("bottomline-execution/mixins/trip-sorter", ["exports", "@ember/object/computed", "@ember/object/mixin", "@ember/object"], function (_exports, _computed, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    sortedTrips: (0, _object.computed)('sortedFinishedTrips', 'sortedUnfinishedTrips', function () {
      return this.get('sortedFinishedTrips').concat(this.get('sortedUnfinishedTrips'));
    }),

    /*
    NOTE: Do not use computed.filterBy for finishedTrips and unfinishedTrips!
      Views get acknowledged about trips and they get rendered only when they are fully loaded
    and filtered when using computed.filterBy. As computed.filterBy uses strict comparison,
    apparently models have some other values in filtered attribute while loading.
    https://github.com/emberjs/ember.js/blob/v2.16.4/packages/ember-runtime/lib/computed/reduce_computed_macros.js (check filterBy and filter)
      Instead of using computed.filterBy, js core functionality is used to implement filtering.
    When using strict comparison in these computed properties' callback functions
    (=== instead of == and !== instead of !=) then it behaves like computed.filterBy
    */
    finishedTrips: (0, _object.computed)('trips.isFulfilled', 'trips.@each.ConfirmedEndDT', function () {
      return this.get('trips').filter(function (trip) {
        return trip.get('ConfirmedEndDT') != null;
      });
    }),
    unfinishedTrips: (0, _object.computed)('trips.isFulfilled', 'trips.@each.ConfirmedEndDT', function () {
      return this.get('trips').filter(function (trip) {
        return trip.get('ConfirmedEndDT') == null;
      });
    }),
    sortedFinishedTrips: (0, _computed.sort)('finishedTrips', 'finishedTripsSortOrder'),
    sortedUnfinishedTrips: (0, _computed.sort)('unfinishedTrips', 'unfinishedTripsSortOrder'),
    finishedTripsSortOrder: ['ConfirmedEndDT'],
    unfinishedTripsSortOrder: ['PlannedStartDT']
  });

  _exports.default = _default;
});