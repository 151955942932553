define("bottomline-execution/instance-initializers/app-state", ["exports", "bottomline-execution/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(applicationInstance) {
    var store = applicationInstance.lookup('service:store');

    if (localStorage.state) {
      var persistedState = JSON.parse(localStorage.state).state.records[1];
      store.pushPayload({
        states: [persistedState]
      });
    } else {
      var stateRecord = store.createRecord('state', {
        id: 1,
        domain: _environment.default.host,
        language: _environment.default.i18n.defaultLocale
      });
      stateRecord.save();
    }

    var shared = applicationInstance.lookup('globals:shared');
    var appState = store.peekRecord('state', '1');
    shared.set('appState', appState);
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});