define("bottomline-execution/components/f7-navbar", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend({
    classNames: ['navbar'],
    didInsertElement: function didInsertElement() {
      this.$('.center').css('opacity', '0');

      var _this = this;

      setTimeout(function () {
        _this.get('f7').sizeNavbars();

        _this.$('.center').css('opacity', '1');
      }, 0);
    }
  });

  _exports.default = _default;
});