define("bottomline-execution/models/be/site", ["exports", "bottomline-execution/models/site", "ember-data"], function (_exports, _site, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;

  var _default = _site.default.extend({
    customer: belongsTo('customer', {
      async: false
    })
  });

  _exports.default = _default;
});