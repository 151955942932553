define("bottomline-execution/services/android-intents-scan", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    scan: function scan() {
      this._scanIntent();
    },
    _scanIntent: function _scanIntent(description, kind) {
      window.plugins.webintent.startActivity({
        address: 'nl.scangaroo.scanimage/nl.scangaroo.scanimage.ScanImageActivity',
        action: 'android.intent.action.GET_CONTENT',
        extras: {
          resolution: '150dpi',
          mode: 'color',
          output: '/mnt/sdcard/DCIM',
          size: 'Auto'
        }
      }, function (e) {
        console.log("Intent succeeded: ".concat(e, ", ").concat(kind, " ").concat(description));
      }, function (e) {
        console.log("Intent failed: ".concat(e, ", ").concat(kind, " ").concat(description));
      });
    }
  });

  _exports.default = _default;
});