define("bottomline-execution/models/answer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    confirmed: attr('boolean', {
      defaultValue: false
    }),
    reason_not_confirmed: attr('string'),
    questionable_id: attr('number'),
    questionable_type: attr('string'),
    question: belongsTo('question')
  });

  _exports.default = _default;
});