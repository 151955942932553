define("bottomline-execution/templates/components/f7-checkbox-tag", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ILLPMSD8",
    "block": "[[[10,\"label\"],[14,0,\"label-checkbox item-content\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,3,\"selection\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"checked\"]]]],null],[1,\"\\n  \"],[10,0],[14,0,\"item-media\"],[12],[10,\"i\"],[14,0,\"icon icon-form-checkbox\"],[12],[13],[13],[1,\"\\n  \"],[1,[30,0,[\"checkedChanged\"]]],[1,\"\\n\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"input\",\"yield\"]]",
    "moduleName": "bottomline-execution/templates/components/f7-checkbox-tag.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});