define("bottomline-execution/routes/questions", ["exports", "@ember/routing/route", "@ember/utils", "@ember/service"], function (_exports, _route, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    router: (0, _service.inject)(),
    store: (0, _service.inject)(),
    model: function model() {
      return this._getAnswers();
    },
    redirect: function redirect(model) {
      if ((0, _utils.isBlank)(model)) {
        this.router.transitionTo('trips');
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('answers', model);
    },
    _getAnswers: function _getAnswers() {
      var store = this.get('store');
      var tripStop = this.modelFor('trip-stops.show');
      return store.query('question', {
        trip_stop_id: tripStop.get('id')
      }).then(function (questions) {
        return questions.map(function (question) {
          var answer = question.get('answer');

          if (answer.get('id')) {
            return answer;
          }

          return store.createRecord('answer', {
            trip_stop: tripStop,
            question: question
          });
        });
      });
    }
  });

  _exports.default = _default;
});