define("bottomline-execution/models/compound-vehicle", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _model.default.extend({
    TruckID: attr('string'),
    TrailerID: attr('string') // BP-7011: The truck may have multiple trailers, this is currently not supported by BE

  });

  _exports.default = _default;
});