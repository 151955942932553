define("bottomline-execution/serializers/bx/pdf-document", ["exports", "bottomline-execution/serializers/pdf-document"], function (_exports, _pdfDocument) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pdfDocument.default.extend({
    normalizeResponse: function normalizeResponse(_store, _primaryModelClass, payload, _id, _requestType) {
      var mappedPdfDocuments = payload['documents'].map(function (document) {
        return {
          id: "".concat(document.documentType, "-").concat(document.modelId),
          type: 'pdf-document',
          attributes: {
            modelId: document.modelId,
            documentType: document.documentType
          }
        };
      });
      return {
        data: mappedPdfDocuments
      };
    }
  });

  _exports.default = _default;
});