define("bottomline-execution/templates/components/bl-components/icon-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "s0TaQ72+",
    "block": "[[[11,3],[24,6,\"javascript:void(0)\"],[16,0,[29,[[30,0,[\"classes\"]]]]],[4,[38,0],[[30,0],\"click\"],[[\"bubbles\"],[false]]],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"name\",\"size\"],[[30,0,[\"iconName\"]],[30,0,[\"iconSize\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[],false,[\"action\",\"material-design-icon\"]]",
    "moduleName": "bottomline-execution/templates/components/bl-components/icon-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});