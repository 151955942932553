define("bottomline-execution/components/f7-views", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: 'views',
    classNameBindings: 'invertedWidth',
    marginLeft: -400,
    width: 400,
    previousViewWidth: null,
    previousLeftViewWidth: null,
    invertedWidthDidChange: (0, _object.observer)('invertedWidth', function () {
      var view = this.$();
      var leftView = this.$().find('.view-left');
      var mainView = this.$().find('.view-main');

      if (this.get('invertedWidth') === true) {
        if (this.disableLeft) {
          leftView.addClass('is-disabled');
        }

        view.css({
          'margin-left': "".concat(this.get('marginLeft'), "px")
        });

        if (this.get('marginLeft') === 0) {
          this.set('previousViewWidth', view.css('width'));
          this.set('previousLeftViewWidth', leftView.css('width'));
          leftView.css({
            'width': "".concat(view.width() - mainView.width() - this.get('width'), "px")
          });
          view.css({
            'width': "".concat(view.width() + this.get('width'), "px")
          });
        }
      } else {
        if (this.disableLeft) {
          leftView.removeClass('is-disabled');
        }

        view.css({
          'margin-left': '0px'
        });

        if (this.get('marginLeft') === 0) {
          view.css({
            'width': this.get('previousViewWidth')
          });
          leftView.css({
            'width': this.get('previousLeftViewWidth')
          });
        }
      }
    })
  });

  _exports.default = _default;
});