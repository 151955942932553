define("bottomline-execution/mixins/trip-stop-material-on-truck", ["exports", "@ember/object/computed", "@ember/object/mixin", "@ember/service", "bottomline-execution/mixins/group-by"], function (_exports, _computed, _mixin, _service, _groupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_groupBy.default, {
    store: (0, _service.inject)(),
    appState: (0, _computed.alias)('shared.appState'),
    currentTruckInventory: (0, _service.inject)(),
    tripStopMaterialOnTruck: function tripStopMaterialOnTruck() {
      var _this = this;

      var state = this.get('appState');
      var vehicleIds = [state.get('currentTruck'), state.get('currentTrailer')];
      var targetTruckInventories = this.get('currentTruckInventory').forVehicle(vehicleIds);
      var groupedTargetTruckInventories = this.groupBy(targetTruckInventories, 'material_id', ['currentQty']).map(function (item) {
        var materialId = item.group;
        item.set('material', _this.get('store').peekRecord('material', materialId));
        return item;
      });
      return groupedTargetTruckInventories.filterBy('totalcurrentQty');
    }
  });

  _exports.default = _default;
});