define("bottomline-execution/serializers/load", ["exports", "ember-data", "active-model-adapter"], function (_exports, _emberData, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true
  });

  _exports.default = _default;
});