define("bottomline-execution/templates/edit-truck", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xcxNXCs3",
    "block": "[[[10,0],[14,0,\"views\"],[12],[1,\"\\n  \"],[3,\" Left view\"],[1,\"\\n  \"],[10,0],[14,0,\"view\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"page-content\"],[12],[1,\"\\n\"],[6,[39,0],null,null,[[\"default\"],[[[[1,\"        \"],[10,0],[14,0,\"center\"],[12],[1,\"\\n          \"],[1,[28,[35,1],null,[[\"key\"],[\"edit_truck.title\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]]],[1,\"      \"],[10,0],[14,0,\"card\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"card-content\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"card-content-inner\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"list-block\"],[12],[1,\"\\n              \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"sortedTrucks\"]]],null]],null],null,[[[1,\"                \"],[11,\"li\"],[16,0,[29,[\"item-content edit-truck__truck-item \",[52,[30,0,[\"offline\"]],\"disabled\"],\" \",[52,[28,[37,5],[[30,1,[\"id\"]],[30,0,[\"currentTruck\"]]],null],\"edit-truck__truck-item--selected\"]]]],[4,[38,6],[[30,0],\"select\",[30,1]],null],[12],[1,\"\\n                  \"],[10,0],[14,0,\"item-inner row\"],[12],[1,\"\\n                    \"],[1,[30,1,[\"identifier\"]]],[1,\"\\n                  \"],[13],[1,\"\\n                \"],[13],[1,\"\\n\"]],[1]],[[[1,\"                \"],[10,\"li\"],[12],[1,[28,[35,1],null,[[\"key\"],[\"no_trucks_found_try_another_user_or_host\"]]]],[13],[1,\"\\n\"]],[]]],[1,\"              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"truck\"],false,[\"f7-inner-navbar\",\"editable-t\",\"each\",\"-track-array\",\"if\",\"eq\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/edit-truck.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});