define("bottomline-execution/components/bl-components/form-button", ["exports", "bottomline-execution/components/bl-components/button-base"], function (_exports, _buttonBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _buttonBase.default.extend({
    typeName: undefined
  });

  _exports.default = _default;
});