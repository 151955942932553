define("bottomline-execution/mixins/planned-or-confirmed-trip-stop-storages", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    plannedOrConfirmedTripStopStorages: function plannedOrConfirmedTripStopStorages(_ref) {
      var tripStop = _ref.tripStop,
          tripStopStorages = _ref.tripStopStorages,
          storageGroupID = _ref.storageGroupID,
          includeUnplannedStorages = _ref.includeUnplannedStorages;
      return tripStopStorages.filter(function (tripStopStorage) {
        return includeUnplannedStorages || (Number(tripStopStorage.get('plannedQty')) > 0 || Number(tripStopStorage.get('confirmedQty')) > 0 || Number(tripStopStorage.get('orderQty')) > 0 && tripStop.get('isCreatedFromOrder')) && storageGroupID === tripStopStorage.get('storage.StorageGroupID');
      });
    }
  });

  _exports.default = _default;
});