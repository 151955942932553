define("bottomline-execution/mixins/session-management", ["exports", "@ember/object/mixin", "@ember/service"], function (_exports, _mixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    truckSelector: (0, _service.inject)(),
    invalidate: function invalidate() {
      var _this = this;

      return this.get('truckSelector').resetCurrentTruck().then(function () {
        return _this._super();
      });
    }
  });

  _exports.default = _default;
});