define("bottomline-execution/models/be/trip-stop", ["exports", "bottomline-execution/models/trip-stop", "@ember/object"], function (_exports, _tripStop, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tripStop.default.extend({
    plannedMaterials: (0, _object.computed)('plannedTripStopStorages', function () {
      return this.get('plannedTripStopStorages').map(function (tripStopStorage) {
        return tripStopStorage.get('material');
      }).uniq();
    })
  });

  _exports.default = _default;
});