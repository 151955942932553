define("bottomline-execution/utils/find-or-initialize-trip-stop-truck-storage", ["exports", "@ember/utils", "bottomline-execution/utils/storage-id-for-material-id-util"], function (_exports, _utils, _storageIdForMaterialIdUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findOrInitializeTripStopTruckStorage = findOrInitializeTripStopTruckStorage;

  function findOrInitializeTripStopTruckStorage(tripStop, storages, truckStorageID, truckInventory) {
    var tsts = tripStop.get('trip_stop_truck_storages').findBy('TruckStorageID', truckStorageID);

    if ((0, _utils.isEmpty)(tsts)) {
      tsts = tripStop.get('trip_stop_truck_storages').createRecord({
        id: tripStop.createGuid(),
        TripStopID: tripStop.id,
        ActivityCode: tripStop.get('ActivityCode'),
        StorageID: (0, _storageIdForMaterialIdUtil.storageIdForMaterialIDUtil)(truckInventory.get('material_id'), storages),
        TripID: tripStop.get('TripID'),
        TruckID: truckInventory.get('truck_id'),
        TruckStorageID: truckStorageID,
        MaterialID: truckInventory.get('material_id'),
        plannedQty: 0,
        confirmedQty: null,
        confirmed15Qty: null
      });
    }

    return tsts;
  }
});