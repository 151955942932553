define("bottomline-execution/utils/handle-response-error", ["exports", "@sentry/browser"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.handleResponseError = handleResponseError;

  function handleResponseError(status, headers, payload, requestData, appState) {
    Sentry.withScope(function (scope) {
      scope.setTag("alias", appState.get('alias'));
      scope.setTag("backendType", appState.get('backend_type'));
      scope.setTag("statuscode", status);
      Sentry.captureMessage({
        headers: headers,
        requestData: requestData,
        status: status,
        payload: payload
      });
    });
  }
});