define("bottomline-execution/controllers/shifts/index", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    activeTrip: null,
    activeShift: null,
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    actions: {
      navigateToTripPage: function navigateToTripPage(shift) {
        return this.router.transitionTo('trips.index', shift.id);
      },
      setActiveTrip: function setActiveTrip(itemId) {
        this.set('activeTrip', this.get('store').peekRecord('trip', itemId));
      },
      setActiveShift: function setActiveShift(itemId) {
        this.set('activeShift', this.get('store').peekRecord('shift', itemId));
      },
      unsetActiveTrip: function unsetActiveTrip() {
        this.set('activeTrip', null);
      },
      onShiftTripNavigateClick: function onShiftTripNavigateClick(trip) {
        trip.set('isShown', true);
        this.router.transitionTo('trips.index', trip.get('shift.id'));
      }
    }
  });

  _exports.default = _default;
});