define("bottomline-execution/templates/components/bl-ocr-validator", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qzyg3i7X",
    "block": "[[[41,[30,0,[\"value\"]],[[[1,\"  \"],[10,1],[14,0,\"color-green\"],[12],[1,[30,0,[\"value\"]]],[10,\"i\"],[14,0,\"mdi mdi-check\"],[12],[13],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"scannerValue\"]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"additionalCssClasses\",\"actionOnClick\",\"translationKey\"],[\"bl-scan-validation-button\",[28,[37,2],[[30,0],\"accept\"],null],\"accept\"]]]],[1,\"\\n\\n    \"],[1,[28,[35,1],null,[[\"additionalCssClasses\",\"actionOnClick\",\"translationKey\"],[\"bl-scan-validation-button\",[28,[37,2],[[30,0],\"overrule\"],null],\"overrule\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"additionalCssClasses\",\"actionOnClick\",\"translationKey\"],[\"bl-scan-validation-button\",[28,[37,2],[[30,0],\"overrule\"],null],\"overrule\"]]]],[1,\"\\n\"]],[]]]],[]]]],[],false,[\"if\",\"bl-components/button\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/components/bl-ocr-validator.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});