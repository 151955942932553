define("bottomline-execution/serializers/bx/inventory", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, _id, _requestType) {
      var mappedInventories = payload.map(function (json) {
        return {
          id: 'inventory_' + json.storage_id,
          type: 'inventory',
          attributes: {
            InventoryDT: json.measurement.moment,
            inventoryQty: json.measurement.quantity.quantity,
            StorageID: json.storage_id
          }
        };
      });
      return {
        data: mappedInventories
      };
    }
  });

  _exports.default = _default;
});