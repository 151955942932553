define("bottomline-execution/serializers/storage", ["exports", "ember-data", "active-model-adapter", "@ember/object"], function (_exports, _emberData, _activeModelAdapter, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      recent_inventory: {
        embedded: 'always'
      },
      recent_storage_material: {
        embedded: 'always'
      }
    },
    serialize: function serialize(snapshot) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (options.purpose === 'forReport') {
        return this.forReport(snapshot);
      } else {
        return this.forServer(snapshot);
      }
    },
    forReport: function forReport(snapshot) {
      return {
        SequenceNb: snapshot.attr('SequenceNb'),
        totalStorageQty: snapshot.attr('totalStorageQty')
      };
    },
    forServer: function forServer(snapshot) {
      var requiredAttributes = ['latitude', 'longitude'];
      var storageAttributes = (0, _object.getProperties)(snapshot.attributes(), requiredAttributes);
      return storageAttributes;
    }
  });

  _exports.default = _default;
});