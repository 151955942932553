define("bottomline-execution/initializers/active-model-adapter", ["exports", "active-model-adapter/initializers/active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _activeModelAdapter.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _activeModelAdapter.initialize;
    }
  });
});