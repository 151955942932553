define("bottomline-execution/helpers/is-boolean", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isBoolean = isBoolean;

  function isBoolean(values) {
    return !!values[0] == values[0];
  }

  var _default = (0, _helper.helper)(isBoolean);

  _exports.default = _default;
});