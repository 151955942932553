define("bottomline-execution/mixins/transition-to-trip-stop", ["exports", "@ember/object/mixin", "@ember/application", "@ember/object", "@ember/utils", "@ember/service"], function (_exports, _mixin, _application, _object, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    router: (0, _service.inject)(),
    applicationController: (0, _object.computed)(function () {
      return (0, _application.getOwner)(this).lookup('controller:application');
    }),
    _transitionToTripStop: function _transitionToTripStop(tripStop, page) {
      if ((0, _utils.isBlank)(page)) {
        page = this._getTripStopPage(tripStop);
      }

      this.router.transitionTo("trip-stops.".concat(page), tripStop.get('TripID'), tripStop.id);
    },
    _getTripStopPage: function _getTripStopPage(tripStop) {
      switch (tripStop.get('ActivityCode')) {
        case 'L':
          return 'load-stop';

        case 'U':
          return 'unload-stop';

        case 'E':
          return 'end-inventory';
      }
    }
  });

  _exports.default = _default;
});