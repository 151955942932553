define("bottomline-execution/utils/multi-click-handler", ["exports", "@ember/object", "@ember/utils"], function (_exports, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    counter: 0,
    runCallback: null,
    threshold: 7,
    timeout: null,
    registerClick: function registerClick() {
      this.counter += 1;

      if (this.counter < this.threshold) {
        if ((0, _utils.isBlank)(this.timeout)) {
          this._setupTimeout();
        }
      } else {
        this._resetCounter();

        this.runCallback();
      }
    },
    _setupTimeout: function _setupTimeout() {
      var _this = this;

      this.timeout = setTimeout(function () {
        _this._resetCounter();
      }, 10 * 1000);
    },
    _resetCounter: function _resetCounter() {
      clearTimeout(this.timeout);
      this.timeout = null;
      this.counter = 0;
    }
  });

  _exports.default = _default;
});