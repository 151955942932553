define("bottomline-execution/components/material-transfer-compartment", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['material-transfer-compartment'],
    classNameBindings: ['viewType', 'isSelected'],
    viewType: 'source',
    selectedStorage: null,
    truckStorage: null,
    isSelected: (0, _object.computed)('selectedStorage', 'truckStorage', function () {
      return this.get('selectedStorage') === this.get('truckStorage');
    })
  });

  _exports.default = _default;
});