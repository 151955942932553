define("bottomline-execution/components/truck-storage-end-inventory", ["exports", "@ember/component", "@ember/object/computed", "@ember/object", "@ember/utils", "@ember/service", "@ember/runloop"], function (_exports, _component, _computed, _object, _utils, _service, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    classNameBindings: ['isSelectedMaterial'],
    classNames: 'storage-compartment',
    tagName: 'td',
    tripStopStorage: null,
    truckSettings: null,
    isReadonly: true,
    showAmbientLiters: (0, _computed.alias)('truckSettings.show_ambient_liters_on_unload'),
    showStandardLiters: (0, _computed.alias)('truckSettings.show_standard_liters_on_unload'),
    confirmedQtyChanged: (0, _object.observer)('tripStopTruckStorage.confirmedQty', 'tripStopTruckStorage.confirmed15Qty', function () {
      this.updateCorrectionStop();
      this.totalizeCompartments();
    }),
    isSelectedMaterial: (0, _object.computed)('tripStopStorage', 'tripStopTruckStorage', function () {
      return Number(this.get('tripStopStorage.MaterialID')) === Number(this.get('truckStorage.material_id'));
    }),
    readOnly: (0, _object.computed)('isSelectedMaterial', function () {
      return !this.get('isSelectedMaterial');
    }),
    allTripStopTruckStorages: (0, _object.computed)('trip-stop.TripID', function () {
      var trip = this.get('store').peekRecord('trip', this.get('trip-stop.TripID'));
      return trip.get('trip_stops').map(function (ts) {
        return ts.get('trip_stop_truck_storages').toArray();
      }).flat().filterBy('TruckStorageID', this.get('truckStorage.TruckStorageID'));
    }),
    tripStopTruckStorage: (0, _object.computed)('tripStopStorage', 'tripStopTruckStorage', 'truckStorage', function () {
      var _this = this;

      var item = this.get('trip-stop.trip_stop_truck_storages').find((0, _runloop.bind)(this, function (tsts) {
        return tsts.get('TruckStorageID') === _this.get('truckStorage.truck_storage_id') && tsts.get('MaterialID') === _this.get('truckStorage.material_id');
      }));

      if ((0, _utils.isEmpty)(item)) {
        item = this.get('trip-stop.trip_stop_truck_storages').createRecord({
          id: this.get('trip-stop').createGuid('from truck storage end inventory using', this.get('truckStorage')),
          plannedQty: 0,
          TripStopID: this.get('trip-stop.id'),
          TripID: this.get('trip-stop.TripID'),
          TruckStorageID: this.get('truckStorage.truck_storage_id'),
          StorageID: this.get('tripStopStorage.StorageID'),
          TruckID: this.get('truckStorage.truck_id'),
          MaterialID: this.get('truckStorage.material_id'),
          ActivityCode: this.get('trip-stop.ActivityCode'),
          confirmedQty: this.get('totalConfirmedQtyBeforeEndStop'),
          confirmed15Qty: this.get('totalConfirmed15QtyBeforeEndStop')
        });
      }

      item.setProperties({
        MaterialID: this.get('truckStorage.material_id')
      });
      return item;
    }),
    totalConfirmedQtyBeforeEndStop: (0, _object.computed)(function () {
      return this._totalQtyBeforeEndStop('confirmedQty');
    }),
    totalConfirmed15QtyBeforeEndStop: (0, _object.computed)(function () {
      return this._totalQtyBeforeEndStop('confirmed15Qty');
    }),
    _totalQtyBeforeEndStop: function _totalQtyBeforeEndStop(targetQuantity) {
      var tripStopTruckStoragesBeforeEndStop = this.get('allTripStopTruckStorages').rejectBy('TripStopID', this.get('trip-stop.id'));
      return tripStopTruckStoragesBeforeEndStop.reduce(function (qty, item) {
        if (item.get('trip_stop.isCorrection')) {
          return qty;
        } else if (item.get('trip_stop.isUnload')) {
          return qty + -1 * item.get(targetQuantity);
        } else {
          return qty + 1 * item.get(targetQuantity);
        }
      }, 0);
    },
    correctionStop: (0, _object.computed)('truckStorage.truck_storage_id', function () {
      return this.get('trip-stop.trip').correctionStop();
    }),
    updateCorrectionStop: function updateCorrectionStop() {
      var _this2 = this;

      var tripStopTruckStorage = this.get('correctionStop.trip_stop_truck_storages').find(function (tsts) {
        return tsts.get('TruckStorageID') === _this2.get('truckStorage.truck_storage_id');
      });

      if ((0, _utils.isEmpty)(tripStopTruckStorage)) {
        tripStopTruckStorage = this.get('correctionStop.trip_stop_truck_storages').createRecord({
          id: this.get('trip-stop').createGuid('from truck storage end inventory for correction stop'),
          plannedQty: 0,
          confirmedQty: 0,
          confirmed15Qty: 0,
          TripStopID: this.get('correctionStop').id,
          TripID: this.get('trip-stop.TripID'),
          ActivityCode: this.get('trip-stop.ActivityCode'),
          TruckID: this.get('truckStorage.truck_id'),
          TruckStorageID: this.get('truckStorage.truck_storage_id'),
          MaterialID: this.get('truckStorage.material_id'),
          StorageID: this.get('tripStopStorage.StorageID')
        });
      }

      tripStopTruckStorage.setProperties({
        confirmedQty: -1 * (this.get('totalConfirmedQtyBeforeEndStop') - Number(this.get('tripStopTruckStorage.confirmedQty'))),
        confirmed15Qty: -1 * (this.get('totalConfirmed15QtyBeforeEndStop') - Number(this.get('tripStopTruckStorage.confirmed15Qty'))),
        StorageID: this.get('tripStopStorage.StorageID')
      });
    },
    totalizeCompartments: function totalizeCompartments() {
      var _this3 = this;

      var tripStop = this.get('trip-stop');
      var materialId = this.get('truckStorage.material_id');
      var totalQty = tripStop.tripStopTruckStorageTotalByMaterial(materialId, 'confirmedQty');
      var total15Qty = tripStop.tripStopTruckStorageTotalByMaterial(materialId, 'confirmed15Qty');
      var tripStopStorage = this.get('trip-stop.tripStopStorages').find(function (tripStopStorage) {
        return _this3.get('truckStorage.material_id') === tripStopStorage.get('MaterialID');
      });

      if (tripStopStorage) {
        tripStopStorage.set('confirmedQty', totalQty);
        tripStopStorage.set('confirmed15Qty', total15Qty);
      }
    },
    actions: {
      toggleLock: function toggleLock() {
        this.toggleProperty('isReadonly');
      }
    }
  });

  _exports.default = _default;
});