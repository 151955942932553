define("bottomline-execution/templates/trips/material", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qtFCBAWm",
    "block": "[[[10,\"table\"],[12],[1,\"\\n\"],[10,\"thead\"],[12],[1,\"\\n  \"],[10,\"th\"],[12],[1,[28,[35,0],null,[[\"key\"],[\"column.Material\"]]]],[13],[1,\"\\n  \"],[10,\"th\"],[12],[1,[28,[35,0],null,[[\"key\"],[\"column.CurrentQty\"]]]],[13],[1,\"\\n  \"],[10,\"th\"],[12],[1,[28,[35,0],null,[[\"key\"],[\"column.Site\"]]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[1,\"\\n  \"],[10,\"tr\"],[15,5,[33,3,[\"material\",\"style\"]]],[12],[1,\"\\n    \"],[10,\"td\"],[12],[1,\"\\n      \"],[1,[33,3,[\"material\",\"MaterialNameShort\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"td\"],[12],[1,\"\\n      \"],[1,[33,3,[\"qtyInTruck\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"td\"],[12],[1,\"\\n      \"],[1,[33,3,[\"totalplannedQty\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"tripStopInventory\"],false,[\"editable-t\",\"each\",\"-track-array\",\"storage\"]]",
    "moduleName": "bottomline-execution/templates/trips/material.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});