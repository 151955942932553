define("bottomline-execution/mixins/state-transition", ["exports", "@ember/object/mixin", "@ember/object", "@ember/object/computed"], function (_exports, _mixin, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    Although 'state machine' is a rather big word for this mixin, it best
    conveys the intent of it: Provide models Shift and Trip to manage their
    state transitions.
  
    This mixin depends on methods in the including objects. These dependencies
    can be recognized by their reference to 'model'.
  */
  var Transition = _object.default.extend({
    model: null,
    action: null,
    state: null,
    enabled: (0, _object.computed)('model', 'model.{canStart,canFinish}', function () {
      switch (this.get('state')) {
        case 'started':
          return this.get('model.canStart');

        case 'finished':
          return this.get('model.canFinish');

        default:
          return true;
      }
    }),
    disabled: (0, _computed.not)('enabled'),
    isHidden: (0, _object.computed)('model', 'model.hasStartedElements', function () {
      switch (this.get('state')) {
        case 'planned':
          return this.get('model.hasStartedElements');

        case 'finished':
          return !this.get('model.hasStartedElements');

        default:
          return false;
      }
    })
  });

  var _default = _mixin.default.create({
    transitions: (0, _object.computed)(function () {
      return {
        planned: [Transition.create({
          model: this,
          action: 'start',
          state: 'started'
        })],
        started: [Transition.create({
          model: this,
          action: 'cancel',
          state: 'planned'
        }), Transition.create({
          model: this,
          action: 'finish',
          state: 'finished'
        })],
        finished: [Transition.create({
          model: this,
          action: 'restart',
          state: 'started'
        })]
      };
    })
  });

  _exports.default = _default;
});