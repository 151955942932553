define("bottomline-execution/components/f7-swipeout", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend({
    tagName: 'li',
    classNames: ['swipeout'],
    willDestroyElement: function willDestroyElement() {
      var _this = this;

      var parent = this.$().parent();
      var index = parent.children().index(this.$());
      var clone = this.$().clone();
      clone.find('script').remove();

      _ember.default.run.scheduleOnce('afterRender', function () {
        var elem = parent.children()[index];

        if (elem) {
          $(parent.children()[index]).before(clone);
        } else {
          parent.append(clone);
        }

        _this.get('f7').swipeoutDelete(clone);
      });
    }
  });

  _exports.default = _default;
});