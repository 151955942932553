define("bottomline-execution/templates/components/load-indicator", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "CZK04Jyn",
    "block": "[[[10,0],[14,0,\"load-indicator\"],[12],[1,\"\\n  \"],[10,1],[15,0,[29,[\"preloader preloader-\",[30,0,[\"color\"]],\" preloader-\",[30,0,[\"size\"]]]]],[12],[1,\"\\n    \"],[10,1],[14,0,\"preloader-inner\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"preloader-inner-gap\"],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"preloader-inner-left\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"preloader-inner-half-circle\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"preloader-inner-right\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"preloader-inner-half-circle\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "bottomline-execution/templates/components/load-indicator.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});