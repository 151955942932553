define("bottomline-execution/authenticators/devise", ["exports", "@ember/object/computed", "ember-simple-auth/authenticators/devise", "bottomline-execution/config/environment", "@ember/runloop", "bottomline-execution/mixins/session-management", "@ember/object"], function (_exports, _computed, _devise, _environment, _runloop, _sessionManagement, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devise.default.extend(_sessionManagement.default, {
    appState: (0, _computed.alias)('shared.appState'),
    identificationAttributeName: _environment.default.devise.identificationAttributeName,
    tokenAttributeName: _environment.default.devise.tokenAttributeName,
    serverTokenEndpoint: (0, _object.computed)('host', function () {
      return "".concat(this.get('host'), "/").concat(_environment.default.api, "/users/sign_in");
    }),
    // Configure Devise to reject the promise in a way that allows
    // showing an error message when the domain is not set in the app
    rejectWithResponse: true,
    host: (0, _computed.alias)('appState.domain'),
    authenticate: function authenticate(identification, password) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        var useResponse = _this.get('rejectWithResponse');

        var _this$getProperties = _this.getProperties('resourceName', 'identificationAttributeName', 'tokenAttributeName'),
            resourceName = _this$getProperties.resourceName,
            identificationAttributeName = _this$getProperties.identificationAttributeName,
            tokenAttributeName = _this$getProperties.tokenAttributeName;

        var url = "".concat(_this.get('serverTokenEndpoint'));
        var options = {};

        if (_this.get('appState.domain')) {
          options = {
            url: url
          };
        }

        var data = {};
        data[resourceName] = {
          password: password
        };
        data[resourceName][identificationAttributeName] = identification;

        _this.makeRequest(data, options).then(function (response) {
          if (response.ok) {
            response.json().then(function (json) {
              if (_this._validate(json)) {
                var _resourceName = _this.get('resourceName');

                var _json = json[_resourceName] ? json[_resourceName] : json;

                (0, _runloop.run)(null, resolve, _json);
              } else {
                (0, _runloop.run)(null, reject, "Check that server response includes ".concat(tokenAttributeName, " and ").concat(identificationAttributeName));
              }
            });
          } else {
            if (useResponse) {
              (0, _runloop.run)(null, reject, response);
            } else {
              response.json().then(function (json) {
                return (0, _runloop.run)(null, reject, json);
              });
            }
          }
        }).catch(function (error) {
          return (0, _runloop.run)(null, reject, error);
        });
      });
    }
  });

  _exports.default = _default;
});