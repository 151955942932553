define("bottomline-execution/services/truck-selector", ["exports", "@ember/object/computed", "rsvp", "@ember/service"], function (_exports, _computed, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    appState: (0, _computed.alias)('shared.appState'),
    customerConfiguration: (0, _service.inject)(),
    cordovaPlatform: (0, _service.inject)('ember-cordova/platform'),
    select: function select(truck) {
      return this._setCurrentTruck(truck.get('id'));
    },
    resetCurrentTruck: function resetCurrentTruck() {
      return this._setCurrentTruck(null);
    },
    _setCurrentTruck: function _setCurrentTruck(truckId) {
      var appState = this.get('appState');
      appState.set('currentTruck', truckId);
      appState.set('currentTrailer', null);
      appState.save();
      return this._updateTabletConfiguration(truckId);
    },
    _updateTabletConfiguration: function _updateTabletConfiguration(truckId) {
      if (!this.get('cordovaPlatform.isCordova') || truckId == null) return _rsvp.Promise.resolve();
      return this.get('customerConfiguration').updateTabletConfiguration({
        truck_id: truckId
      }).catch(function (error) {
        console.error('Failed to update configuration service', error);
      });
    }
  });

  _exports.default = _default;
});