define("bottomline-execution/mixins/adapter-options", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    adapterAction: function adapterAction(action) {
      return {
        adapterOptions: {
          action: action
        }
      };
    }
  });

  _exports.default = _default;
});