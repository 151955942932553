define("bottomline-execution/routes/trip-stops/change-terminal", ["exports", "@ember/object/computed", "@ember/routing/route", "@ember/service"], function (_exports, _computed, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    appState: (0, _computed.alias)('shared.appState'),
    store: (0, _service.inject)(),
    model: function model() {
      var currentTruck = this.get('appState.currentTruck');
      return this.get('store').query('terminal', {
        filter: {
          truck_id: currentTruck
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('currentLoadStop', this.modelFor('trips.show').get('loadStop'));
    }
  });

  _exports.default = _default;
});