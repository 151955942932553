define("bottomline-execution/templates/components/f7-page-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QTTNwxxz",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"pull-to-refresh-layer\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"preloader\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"pull-to-refresh-arrow\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[18,1,null],[1,\"\\n\"],[41,[33,3],[[[1,\"  \"],[10,0],[14,0,\"infinite-scroll-preloader\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"preloader\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"hasPullToRefresh\",\"yield\",\"hasInfiniteScroll\"]]",
    "moduleName": "bottomline-execution/templates/components/f7-page-content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});