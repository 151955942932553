define("bottomline-execution/components/material-on-truck", ["exports", "@ember/object/computed", "@ember/template", "@ember/component", "@ember/object", "@ember/service", "@ember/utils"], function (_exports, _computed, _template, _component, _object, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    truckSettings: (0, _computed.alias)('shared.appState.truckSettings'),
    currentTruckInventory: (0, _service.inject)(),
    materialStyle: (0, _object.computed)('inventory.material', 'inventory', function () {
      return new _template.htmlSafe("color: ".concat(this.get('inventory.material.foreground_color'), "; ") + "background-color: ".concat(this.get('inventory.material.background_color'), ";"));
    }),
    materialInventoriesWithTripStopNeed: (0, _object.computed)('selectedTripStop', function () {
      var currentTruck = this.get('appState.currentTruck');
      var selectedTripStop = this.get('selectedTripStop');
      if ((0, _utils.isBlank)(selectedTripStop)) return [];
      var materialInventoriesWithNeed = this.get('currentTruckInventory').forVehicle(currentTruck).map(function (inventory) {
        inventory.set('neededQty', selectedTripStop.totalPlannedQty(inventory.get('material_id')));
        return inventory;
      });
      return materialInventoriesWithNeed.filter(function (inventory) {
        return inventory.get('currentQty') || inventory.get('neededQty');
      }).sortBy('material.MaterialNameShort', 'material_id');
    })
  });

  _exports.default = _default;
});