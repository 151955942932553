define("bottomline-execution/serializers/trip-stop", ["exports", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "ember-data", "active-model-adapter", "bottomline-execution/utils/json-util"], function (_exports, _object, _computed, _service, _utils, _emberData, _activeModelAdapter, _jsonUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    appState: (0, _computed.alias)('shared.appState'),
    session: (0, _service.inject)('session'),
    store: (0, _service.inject)(),
    authenticatedUser: (0, _computed.alias)('session.session.content.authenticated'),
    isNewSerializerAPI: true,
    attrs: {
      site: {
        embedded: 'always'
      },
      order: {
        embedded: 'always'
      },
      trip_stop_storages: {
        embedded: 'always'
      },
      trip_stop_truck_storages: {
        embedded: 'always'
      },
      pictures: {
        embedded: 'always'
      },
      all_driver_remarks: {
        embedded: 'always'
      }
    },
    serialize: function serialize(snapshot) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      switch (options.purpose) {
        case 'forReport':
          return this.forReport(snapshot);

        case 'forTrip':
          return this.forTrip(snapshot);

        default:
          return this.forServer(snapshot);
      }
    },
    forReport: function forReport(snapshot) {
      var trip = this._trip(snapshot);

      var resource = trip.get('resource') || _object.default.create();

      var loadStop = trip.get('loadStop') || _object.default.create();

      var loadSite = loadStop.get('SiteID') ? this.get('store').peekRecord('site', loadStop.get('SiteID')) : _object.default.create();

      var truck = this.get('appState').getTruck() || _object.default.create();

      var trailer = this.get('appState').getTrailer() || _object.default.create();

      var reportData = {
        appState: (0, _object.getProperties)(this.get('appState'), ['currentTruck', 'currentTrailer', 'language']),
        displayName: this.get('authenticatedUser.user_display_name') || this.get('authenticatedUser.user_name'),
        state: snapshot.attr('state'),
        siteID: snapshot.attr('SiteID'),
        tripID: snapshot.attr('TripID'),
        extOrderID: snapshot.attr('ExtOrderID'),
        orderID: snapshot.attr('OrderID'),
        orderRef: snapshot.attr('OrderRef'),
        confirmedQty: snapshot.attr('confirmedQty'),
        plannedQty: snapshot.attr('plannedQty'),
        driverRemarks: snapshot.attr('DriverRemarks'),
        allRemarks: this._allRemarks(snapshot),
        confirmedArrivalDT: snapshot.attr('ConfirmedArrivalDT'),
        resource: (0, _jsonUtil.toJSON)(resource),
        loadStop: (0, _jsonUtil.toJSON)(loadStop),
        loadSite: (0, _jsonUtil.toJSON)(loadSite),
        currentTruck: (0, _jsonUtil.toJSON)(truck),
        currentTrailer: (0, _jsonUtil.toJSON)(trailer),
        site: snapshot.belongsTo('site').attributes(),
        order: this._order(snapshot),
        tripStopTruckStorages: this._tripStopTruckStorages(snapshot, {
          purpose: 'forReport'
        }),
        tripStopStorages: this._tripStopStorages(snapshot, {
          purpose: 'forReport'
        })
      };
      return reportData;
    },
    _trip: function _trip(snapshot) {
      var trip = null;

      if ((0, _utils.isPresent)(snapshot.attr('TripID'))) {
        trip = this.get('store').peekRecord('trip', snapshot.attr('TripID'));
      }

      return trip || _object.default.create();
    },
    _allRemarks: function _allRemarks(snapshot) {
      return snapshot.record.get('allRemarks').map(function (remark) {
        return Object.assign({}, remark.get('data'), {
          remark: remark.get('body')
        });
      });
    },
    _order: function _order(snapshot) {
      var order = snapshot.belongsTo('order');

      if ((0, _utils.isPresent)(order)) {
        return order.serialize({
          purpose: 'forReport'
        });
      } else {
        return {};
      }
    },
    _tripStopStorages: function _tripStopStorages(snapshot) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return (snapshot.hasMany('trip_stop_storages') || []).map(function (tss) {
        return tss.serialize(options);
      });
    },
    _tripStopTruckStorages: function _tripStopTruckStorages(snapshot) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return (snapshot.hasMany('trip_stop_truck_storages') || []).map(function (tsts) {
        return tsts.serialize(options);
      });
    },
    forTrip: function forTrip(snapshot) {
      return {
        id: snapshot.id,
        ActivityCode: snapshot.attr('ActivityCode'),
        BarrelCount: snapshot.attr('BarrelCount'),
        ConfirmedArrivalDT: snapshot.attr('ConfirmedArrivalDT'),
        ConfirmedDepartureDT: snapshot.attr('ConfirmedDepartureDT'),
        confirmedQty: snapshot.attr('confirmedQty'),
        ConfirmedSequenceNb: snapshot.attr('ConfirmedSequenceNb'),
        DriverRemarks: snapshot.attr('DriverRemarks'),
        ExtOrderID: snapshot.attr('ExtOrderID'),
        Odometer: snapshot.attr('Odometer'),
        plannedQty: snapshot.attr('plannedQty'),
        ResourceID: snapshot.attr('ResourceID'),
        SequenceNb: snapshot.attr('SequenceNb'),
        state: snapshot.attr('state'),
        TripID: snapshot.attr('TripID'),
        TruckID: snapshot.attr('TruckID'),
        trip_stop_storage_attributes: this._tripStopStorages(snapshot),
        trip_stop_truck_storage_attributes: this._tripStopTruckStorages(snapshot),
        site_storage_inventory_attributes: this._siteStorageInventories(snapshot),
        trip_stop_site_attributes: this._siteAttributes(snapshot),
        DriverNotifiedCustomerOnArrivalYN: snapshot.attr('DriverNotifiedCustomerOnArrivalYN'),
        DriverNotifiedCustomerOnDepartureYN: snapshot.attr('DriverNotifiedCustomerOnDepartureYN')
      };
    },
    _siteStorageInventories: function _siteStorageInventories(snapshot) {
      var site = snapshot.belongsTo('site');

      if (snapshot.record.get('isUnload') && (0, _utils.isPresent)(site)) {
        var siteStorages = site.hasMany('storages') || [];
        var inventories = siteStorages.map(function (s) {
          return s.belongsTo('recent_inventory');
        }).compact();
        return inventories.filter(function (i) {
          return i.attr('inventoryQty') > 0;
        }).map(function (i) {
          return i.serialize();
        });
      }

      return null;
    },
    _siteAttributes: function _siteAttributes(snapshot) {
      var changedAttributes = Object.keys(snapshot.changedAttributes());

      if (changedAttributes.includes('latitude') || changedAttributes.includes('longitude')) {
        return {
          latitude: snapshot.attr('latitude'),
          longitude: snapshot.attr('longitude')
        };
      }

      return null;
    },
    forServer: function forServer(snapshot) {
      return {
        ActivityCode: snapshot.attr('ActivityCode'),
        ConfirmedArrivalDT: snapshot.attr('ConfirmedArrivalDT'),
        ConfirmedDepartureDT: snapshot.attr('ConfirmedDepartureDT'),
        ConfirmedSequenceNb: snapshot.attr('ConfirmedSequenceNb'),
        DriverRemarks: snapshot.attr('DriverRemarks'),
        ExtOrderID: snapshot.attr('ExtOrderID'),
        OrderID: snapshot.attr('OrderID'),
        plannedQty: snapshot.attr('plannedQty'),
        Remarks: snapshot.attr('Remarks'),
        ResourceID: snapshot.attr('ResourceID'),
        SequenceNb: snapshot.attr('SequenceNb'),
        SiteID: snapshot.attr('SiteID'),
        state: snapshot.attr('state'),
        StorageGroupID: snapshot.attr('StorageGroupID'),
        TripID: snapshot.attr('TripID'),
        TruckID: snapshot.attr('TruckID')
      };
    }
  });

  _exports.default = _default;
});