define("bottomline-execution/services/localization-ajax", ["exports", "@ember/object", "bottomline-execution/services/ajax", "bottomline-execution/config/environment"], function (_exports, _object, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    host: (0, _object.computed)(function () {
      return _environment.default.i18n.endpoint;
    })
  });

  _exports.default = _default;
});