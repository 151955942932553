define("bottomline-execution/components/bl-components/button-base", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    componentNamespace: 'bl-components',
    // INFO: the property is used for passing the html tag id
    idName: null,
    // INFO: the property set to `false` makes the button disabled
    isEnabled: true,
    // INFO: the property set to `false` makes the button not present in the document and not take any space
    isVisible: true,
    isHidden: (0, _computed.not)('isVisible').readOnly(),
    // INFO: the property is used to compute a css class of the button which defines appearance of the element
    buttonGroup: null,
    // INFO: the property is used for passing the button additional css classes which are usually defined outside the styles of this component
    additionalCssClasses: '',
    // INFO: the property is used for passing the button style groups names which are used for assigning them additional css classes
    styleGroups: '',
    // INFO: the property is used for indicating that there is an error (e.g. form validation error) which is connected with the button action or for warning a user that the action connected with the button is required
    warning: null,
    // INFO: the property is used for disabling the button while the button action being executed
    isActionOnClickInProgress: false,
    // INFO: the property is used for passing the button text translation key, if a block is passed to the button component then this property is ignored and the component renders content of the block instead of the translation for the key
    translationKey: null,
    componentMainCssClass: (0, _object.computed)(function () {
      return "".concat(this.get('componentNamespace'), "__button");
    }),
    canBeEnabled: (0, _object.computed)('isEnabled', 'isActionOnClickInProgress', function () {
      return this.get('isEnabled') && !this.get('isActionOnClickInProgress');
    }).readOnly(),
    classes: (0, _object.computed)('additionalCssClasses', 'canBeEnabled', 'isHidden', 'warning', 'styleGroups', function () {
      var classes = [this.get('componentMainCssClass')];
      classes.push(this._additionalClasses(this.get('additionalCssClasses')));
      classes.push(this._styleGroupsClasses(this.get('componentNamespace'), this.get('styleGroups')));
      classes.push(this._disabledModifierClasses());
      classes.push(this._warningModifierClasses());
      classes.push(this._hiddenModifierClasses());
      return classes.flat().join(' ');
    }),
    _stringToArray: function _stringToArray(classes) {
      if (typeof classes === 'string') return classes.split(' ').filter(Boolean);
      return classes;
    },
    _additionalClasses: function _additionalClasses(classes) {
      return this._stringToArray(classes);
    },
    _styleGroupsClasses: function _styleGroupsClasses(componentNamespace, styleGroups) {
      var modifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      return this._stringToArray(styleGroups).map(function (styleGroup) {
        return "".concat(componentNamespace, "__").concat(styleGroup).concat(modifier ? "--".concat(modifier) : '');
      });
    },
    _disabledModifierClasses: function _disabledModifierClasses() {
      var classes = [];

      if (!this.get('canBeEnabled')) {
        classes.push("".concat(this.get('componentMainCssClass'), "--disabled"));

        var styleGroupsStylesDisabled = this._styleGroupsClasses(this.get('componentNamespace'), this.get('styleGroups'), 'disabled');

        classes.push(styleGroupsStylesDisabled);
      }

      return classes;
    },
    _warningModifierClasses: function _warningModifierClasses() {
      var classes = [];

      if (this.get('warning')) {
        classes.push("".concat(this.get('componentMainCssClass'), "--warning"));
      }

      return classes;
    },
    _hiddenModifierClasses: function _hiddenModifierClasses() {
      var classes = [];
      if (this.get('isHidden')) classes.push('is-hidden');
      return classes;
    }
  });

  _exports.default = _default;
});