define("bottomline-execution/templates/components/signature-pad", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uNXpCFlv",
    "block": "[[[10,\"canvas\"],[15,\"width\",[36,0]],[15,\"height\",[36,1]],[12],[13],[1,\"\\n\"]],[],false,[\"width\",\"height\"]]",
    "moduleName": "bottomline-execution/templates/components/signature-pad.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});