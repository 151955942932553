define("bottomline-execution/serializers/bx/shift", ["exports", "bottomline-execution/serializers/shift"], function (_exports, _shift) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _shift.default.extend({
    mappedShiftStatus: {
      open: 'planned',
      started: 'started',
      finished: 'finished'
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, _id, _requestType) {
      var _this = this;

      var truckInventorySerializer = store.serializerFor('truck-inventory');
      var normalizedTruckInventories = truckInventorySerializer.mapResponse(payload.vehicleInventories);
      var mappedShifts = payload.shifts.map(function (json) {
        return {
          id: json.id,
          type: 'shift',
          attributes: {
            CompoundVehicleID: json.truck.id,
            ConfirmedStartDT: null,
            // TODO This needs a place in the API
            PlannedStartDT: json.start,
            ResourceID: json.driver.id,
            ResourceName: json.driver.name,
            // TODO Is this really what should be used for delivery ticket?
            state: _this.get('mappedShiftStatus')[json.status],
            TrailerID: json.truck.trailers[0],
            // BP-7011: The truck may have multiple trailers, this is currently not supported by BE
            TruckID: json.truck.drivable_vehicle_id
          },
          relationships: {
            trips: {
              data: json.trip_ids.map(function (id) {
                return {
                  type: 'trip',
                  id: id
                };
              })
            },
            'truck_inventories': {
              data: normalizedTruckInventories.data.map(function (_ref) {
                var type = _ref.type,
                    id = _ref.id;
                return {
                  type: type,
                  id: id
                };
              })
            }
          }
        };
      });
      var filteredShifts = mappedShifts.filter(function (shift) {
        return shift.relationships.trips.data.length > 0;
      });
      return {
        data: filteredShifts,
        included: normalizedTruckInventories.data
      };
    }
  });

  _exports.default = _default;
});