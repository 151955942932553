define("bottomline-execution/components/bl-components/link-button", ["exports", "bottomline-execution/components/bl-components/button", "@ember/object", "@ember/service"], function (_exports, _button, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _button.default.extend({
    router: (0, _service.inject)(),
    targetRoute: '',
    routeModels: [],
    queryParams: {},
    queryParameters: (0, _object.computed)('queryParams', function () {
      return {
        queryParams: this.get('queryParams')
      };
    }).readOnly(),
    isActive: (0, _object.computed)('href', 'router.currentURL', function () {
      return this.get('href') == "#".concat(this.get('router.currentURL'));
    }),
    classes: (0, _object.computed)('additionalCssClasses', 'isEnabled', 'isHidden', 'isActive', 'warning', 'styleGroups', function () {
      var classes = [this.get('componentMainCssClass')];
      classes.push(this._additionalClasses(this.get('additionalCssClasses')));
      classes.push(this._styleGroupsClasses(this.get('componentNamespace'), this.get('styleGroups')));
      classes.push(this._disabledModifierClasses());
      classes.push(this._warningModifierClasses());
      classes.push(this._hiddenModifierClasses());
      classes.push(this._activeModifierClasses());
      return classes.flat().join(' ');
    }),
    href: (0, _object.computed)('targetRoute', 'routeModels.@each', 'routeParams', 'queryParams', function () {
      var _this$get;

      var url = (_this$get = this.get('router')).urlFor.apply(_this$get, [this.get('targetRoute')].concat(_toConsumableArray([this.get('routeModels')].flat()), [this.get('queryParameters')]));

      return url;
    }),
    _activeModifierClasses: function _activeModifierClasses() {
      var classes = [];

      if (this.get('isActive')) {
        classes.push("".concat(this.get('componentMainCssClass'), "--active"));

        var styleGroupsStylesActive = this._styleGroupsClasses(this.get('componentNamespace'), this.get('styleGroups'), 'active');

        classes.push(styleGroupsStylesActive);
      }

      return classes;
    }
  });

  _exports.default = _default;
});