define("bottomline-execution/serializers/trip-stop-truck-storage", ["exports", "ember-data", "active-model-adapter", "@ember/utils"], function (_exports, _emberData, _activeModelAdapter, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      storage: {
        embedded: 'always'
      }
    },
    serialize: function serialize(snapshot) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (options.purpose === 'forReport') {
        return this.forReport(snapshot);
      } else {
        return this.forServer(snapshot);
      }
    },
    forReport: function forReport(snapshot) {
      var customAttributes = {
        id: snapshot.id,
        material: this._material(snapshot),
        materialID: snapshot.attr('MaterialID'),
        storageID: snapshot.attr('StorageID'),
        tripId: snapshot.attr('TripID'),
        tripStopId: snapshot.attr('TripStopID')
      };
      return Object.assign({}, snapshot.attributes(), customAttributes);
    },
    _material: function _material(snapshot) {
      var material = snapshot.record.get('material');

      if ((0, _utils.isPresent)(material)) {
        return material.get('data');
      } else {
        return {};
      }
    },
    forServer: function forServer(snapshot) {
      return {
        id: snapshot.id,
        confirmed15Qty: snapshot.attr('confirmed15Qty'),
        confirmedQty: snapshot.attr('confirmedQty'),
        Density: snapshot.attr('Density'),
        MaterialID: snapshot.attr('MaterialID'),
        plannedQty: snapshot.attr('plannedQty'),
        StorageID: snapshot.attr('StorageID'),
        Temperature: snapshot.attr('Temperature'),
        TripID: snapshot.attr('TripID'),
        TripStopID: snapshot.attr('TripStopID'),
        TripStopStorageID: snapshot.attr('TripStopStorageID'),
        TruckID: snapshot.attr('TruckID'),
        TruckStorageID: Number(snapshot.attr('TruckStorageID'))
      };
    }
  });

  _exports.default = _default;
});