define("bottomline-execution/services/compartment-based/current-truck-inventory", ["exports", "bottomline-execution/services/current-truck-inventory", "@ember/object/computed", "@ember/service", "@ember/utils"], function (_exports, _currentTruckInventory, _computed, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _currentTruckInventory.default.extend({
    store: (0, _service.inject)(),
    quantityForMaterial: function quantityForMaterial(materialId) {
      return this.compartmentInventories().filterBy('material_id', materialId).reduce(function (qty, inventory) {
        return qty + inventory.get('currentQty');
      }, 0);
    },
    forVehicle: function forVehicle(vehicleIds) {
      return this.compartmentInventories(vehicleIds);
    },
    _initialQuantityForMaterialInventory: function _initialQuantityForMaterialInventory(materialId) {
      return this.quantityForMaterial(materialId);
    },
    updateMaterialsIds: function updateMaterialsIds(loadStopTruckStorages) {
      var _this = this;

      var loadedTsts = loadStopTruckStorages.filter(function (t) {
        return t.get('confirmedQty') > 0 || t.get('confirmed15Qty') > 0;
      });
      this.compartmentInventories().forEach(function (inventory) {
        var tstsForInventory = loadedTsts.findBy('TruckStorageID', inventory.get('TruckStorageID'));
        if ((0, _utils.isEmpty)(tstsForInventory)) return;
        var loadedMaterialId = tstsForInventory.get('MaterialID');
        console.log("%cUpdate material for compartment ".concat(inventory.get('TruckStorageID'), " (loaded: ").concat(loadStopTruckStorages.findBy('TruckStorageID', inventory.get('TruckStorageID')).get('confirmedQty'), ") from ").concat(inventory.get('material.MaterialNameShort'), " to ").concat(_this.get('store').peekRecord('material', loadedMaterialId).get('MaterialNameShort')), "color:blue");
        inventory.set('material_id', loadedMaterialId);
      });
    },
    withTruckStorageId: (0, _computed.alias)('forCompartment'),
    forCompartment: function forCompartment(truckStorageId) {
      return this.compartmentInventories().findBy('truck_storage_id', truckStorageId);
    },
    byCompartment: (0, _computed.alias)('compartmentInventories'),
    compartmentInventories: function compartmentInventories() {
      var vehicleIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [this.get('truckId'), this.get('trailerId')];
      return this.allInventoriesForVehicle(vehicleIds).filter(function (inventory) {
        return inventory.get('isCompartmentInventory');
      });
    }
  });

  _exports.default = _default;
});