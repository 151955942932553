define("bottomline-execution/templates/components/material-on-truck", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "T7QSR82E",
    "block": "[[[41,[30,0,[\"truckSettings\",\"compartments\"]],[[[1,\"  \"],[10,\"table\"],[12],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"shift\"],[[30,0,[\"shift\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"table\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n    \"],[10,\"th\"],[12],[1,[28,[35,2],null,[[\"key\"],[\"column.Material\"]]]],[13],[1,\"\\n    \"],[10,\"th\"],[12],[1,[28,[35,2],null,[[\"key\"],[\"column.CurrentQty\"]]]],[13],[1,\"\\n    \"],[10,\"th\"],[12],[1,[28,[35,2],null,[[\"key\"],[\"column.Site\"]]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"materialInventoriesWithTripStopNeed\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,5],null,[[\"inventory\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"inventory\"],false,[\"if\",\"truck-inventory\",\"editable-t\",\"each\",\"-track-array\",\"material-on-truck/item\"]]",
    "moduleName": "bottomline-execution/templates/components/material-on-truck.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});