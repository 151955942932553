define("bottomline-execution/mixins/group-by", ["exports", "@ember/object", "@ember/array", "@ember/object/mixin"], function (_exports, _object, _array, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    groupBy: function groupBy(list, group, totals) {
      var groups = (0, _array.A)((0, _array.A)(list).getEach(group)).uniq();
      return groups.reduce(function (result, item) {
        var itemsForGroup = list.filterBy(group, item);
        var currentGroup = new _object.default();
        currentGroup.setProperties({
          items: itemsForGroup,
          group: item
        });
        totals.forEach(function (total) {
          var totalVal = itemsForGroup.reduce(function (prev, item) {
            return (isNaN(prev) ? 0 : parseInt(prev, 10)) + parseInt(item.get(total) || 0, 10);
          }, 0);
          currentGroup.set("total".concat(total), totalVal);
        });

        if (itemsForGroup.length > 0) {
          return result.concat([currentGroup]);
        }

        return result;
      }, []);
    }
  });

  _exports.default = _default;
});