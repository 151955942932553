define("bottomline-execution/utils/math-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.round = round;

  function round(number) {
    var digitsAfterComma = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

    if (Number.isInteger(number)) {
      return number;
    } else {
      var base = Math.pow(10, digitsAfterComma);
      return (Math.round(number * base) / base).toFixed(digitsAfterComma);
    }
  }
});