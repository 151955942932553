define("bottomline-execution/utils/is-zero-or-greater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isZeroOrGreater = isZeroOrGreater;

  function isZeroOrGreater(value) {
    return value === 0 || value > 0;
  }
});