define("bottomline-execution/controllers/questions", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    ajax: (0, _service.inject)(),
    session: (0, _service.inject)(),
    internetConnection: (0, _service.inject)(),
    router: (0, _service.inject)(),
    actions: {
      save: function save() {
        var _this = this;

        var data = this.get('answers').map(function (answer) {
          return _this.get('store').serialize(answer);
        });
        this.get('ajax').post('/answers', {
          data: JSON.stringify({
            answers: data
          })
        }).then(function () {
          if (_this.get('tripStop.showQuestionsOnStart')) {
            _this.router.transitionTo('trip-stops.show.index');
          } else {
            _this.router.transitionTo('trips');
          }
        }).catch(function (response) {
          _this.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});