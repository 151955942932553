define("bottomline-execution/components/bl-ocr-validator", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _computed, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    i18n: (0, _service.inject)(),
    userValue: null,
    scannerResponse: _object.default.create({}),
    scannerValue: (0, _computed.alias)('scannerResponse.scannedValue'),
    value: (0, _object.computed)('userValue', 'scannerValue', function () {
      return this.get('userValue') === this.get('scannerValue') ? this.get('scannerValue') : null;
    }),
    actions: {
      accept: function accept() {
        this.set('scannerResponse.isConfirmed', true);
        this.set('value', this.get('scannerValue'));
        this.set('userValue', this.get('scannerValue'));
      },
      overrule: function overrule() {
        this.set('value', this.get('userValue'));
        this.set('scannerResponse.isConfirmed', true);
        this.set('scannerResponse.isOverruled', true);
      }
    }
  });

  _exports.default = _default;
});