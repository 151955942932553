define("bottomline-execution/models/remark", ["exports", "ember-data", "@ember/object/computed"], function (_exports, _emberData, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    type: attr('string'),
    body: attr('string'),
    Remark: (0, _computed.alias)('body')
  });

  _exports.default = _default;
});