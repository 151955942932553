define("bottomline-execution/routes/trip-stops/trip-stops", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({});

  _exports.default = _default;
});