define("bottomline-execution/templates/components/edit-language", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dDPDb4vb",
    "block": "[[[41,[30,0,[\"isEditing\"]],[[[1,\"    \"],[10,\"td\"],[12],[1,\"\\n        \"],[1,[30,0,[\"language\",\"id\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"item-input\"],[12],[1,\"\\n            \"],[8,[39,1],[[24,\"placeholder\",\"Type description\"]],[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"td\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"buttons-row\"],[12],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"additionalCssClasses\",\"actionOnClick\",\"translationKey\"],[\"item-link\",[28,[37,3],[[30,0],\"editMode\"],null],\"cancel\"]]]],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"actionOnClick\",\"translationKey\"],[[28,[37,3],[[30,0],\"saveLocale\"],null],\"save\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"td\"],[12],[1,[30,0,[\"language\",\"id\"]]],[13],[1,\"\\n    \"],[10,\"td\"],[12],[1,[30,0,[\"language\",\"title\"]]],[13],[1,\"\\n    \"],[10,\"td\"],[12],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"actionOnClick\",\"translationKey\"],[[28,[37,3],[[30,0],\"editMode\"],null],\"edit\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[10,\"td\"],[12],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"actionOnClick\",\"translationKey\"],[[28,[37,3],[[30,0],\"deleteLocale\"],null],\"delete\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"input\",\"bl-components/button\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/components/edit-language.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});