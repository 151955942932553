define("bottomline-execution/serializers/truck-inventory", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    serialize: function serialize(snapshot) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (options.purpose === 'forReport') {
        return this.forReport(snapshot);
      } else {
        return this.forServer(snapshot);
      }
    },
    forReport: function forReport(snapshot) {
      var snapshotAttributes = Object.assign({}, snapshot.attributes(), {
        truck_storage_id: Number(snapshot.attr('truck_storage_id'))
      });
      var customAttributes = {
        id: snapshot.id,
        materialId: snapshot.attr('material_id'),
        truckId: snapshot.attr('truck_id'),
        truckStorageId: Number(snapshot.attr('truck_storage_id'))
      };
      return Object.assign({}, snapshotAttributes, customAttributes);
    },
    forServer: function forServer(snapshot) {
      return {
        id: snapshot.id,
        current_qty: snapshot.attr('currentQty'),
        material_id: snapshot.attr('material_id'),
        truck_id: snapshot.attr('truck_id'),
        truck_storage_id: Number(snapshot.attr('truck_storage_id'))
      };
    }
  });

  _exports.default = _default;
});