define("bottomline-execution/templates/components/time-format", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Ik0fesuf",
    "block": "[[[1,[28,[35,0],[[33,1]],null]],[1,\"\\n\"]],[],false,[\"moment-format\",\"yield\"]]",
    "moduleName": "bottomline-execution/templates/components/time-format.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});