define("bottomline-execution/components/bl-components/icon-button", ["exports", "bottomline-execution/components/bl-components/button", "@ember/object"], function (_exports, _button, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _button.default.extend({
    // INFO: the property is used for passing the button icon name (e.g. 'print'). If it is present then the button will be represented just by this icon and some of properties will be ignored (check the component template)
    iconName: null,
    // INFO: the property is used for passing the button icon size. Used in pair with `iconName` property
    iconSize: 'lg',
    componentMainCssClass: (0, _object.computed)(function () {
      return "".concat(this.get('componentNamespace'), "__icon-button");
    }),
    classes: (0, _object.computed)('additionalCssClasses', 'canBeEnabled', 'isHidden', 'styleGroups', function () {
      var classes = [this.get('componentMainCssClass')];
      classes.push(this._additionalClasses(this.get('additionalCssClasses')));
      classes.push(this._styleGroupsClasses(this.get('componentNamespace'), this.get('styleGroups')));
      classes.push(this._disabledModifierClasses());
      classes.push(this._hiddenModifierClasses());
      return classes.flat().join(' ');
    })
  });

  _exports.default = _default;
});