define("bottomline-execution/templates/components/input-number-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "fNb/zOQ9",
    "block": "[[[8,[39,0],null,[[\"@type\",\"@value\"],[\"number\",[30,0,[\"numericValue\"]]]],null],[1,\"\\n\"]],[],false,[\"input\"]]",
    "moduleName": "bottomline-execution/templates/components/input-number-text.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});