define("bottomline-execution/templates/components/input-number-base", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "W+s3d/QZ",
    "block": "[[[10,0],[14,0,\"input-number-base\"],[15,\"disabled\",[30,0,[\"disabled\"]]],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"input-number-base__input\"],[16,\"placeholder\",[30,0,[\"placeholder\"]]],[16,\"min\",[30,0,[\"min\"]]],[16,\"max\",[30,0,[\"max\"]]],[24,\"inputmode\",\"decimal\"],[4,[38,1],[\"keyup\",[28,[37,2],[[30,0],\"changeValue\"],null]],null],[4,[38,1],[\"focusout\",[28,[37,2],[[30,0],\"ensureNumericality\"],null]],null]],[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"input\",\"on\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/components/input-number-base.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});