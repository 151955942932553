define("bottomline-execution/components/standalone-option-switch", ["exports", "@ember/object", "@ember/component"], function (_exports, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['standalone-option-switch'],
    clickAction: null,
    value: null,
    label: null,
    showLabel: (0, _object.computed)('label', function () {
      return this.get('label') !== false;
    }),
    click: function click() {
      if (this.attrs['clickAction']) {
        var _this$attrs;

        (_this$attrs = this.attrs)['clickAction'].apply(_this$attrs, arguments);
      } else {
        this.toggleProperty('value');
      }

      return false;
    }
  });

  _exports.default = _default;
});