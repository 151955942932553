define("bottomline-execution/services/device-information", ["exports", "rsvp", "@ember/service"], function (_exports, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    cordovaPlatform: (0, _service.inject)('ember-cordova/platform'),
    getMacAddress: function getMacAddress() {
      var getMacAddressFunction;

      if (this.get('cordovaPlatform.isCordova')) {
        getMacAddressFunction = this._getRealMacAddress;
      } else {
        getMacAddressFunction = function getMacAddressFunction(_, reject) {
          reject('Not running on a device');
        };
      }

      return new _rsvp.Promise(getMacAddressFunction);
    },
    _getRealMacAddress: function _getRealMacAddress(resolve, reject) {
      window.MacAddress.getMacAddress(function (macAddress) {
        resolve(macAddress);
      }, function (failure) {
        reject(failure);
      });
    }
  });

  _exports.default = _default;
});