define("bottomline-execution/routes/trip-stops/end-inventory", ["exports", "@ember/routing/route", "bottomline-execution/mixins/trip-stop-type", "@ember/service"], function (_exports, _route, _tripStopType, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_tripStopType.default, {
    store: (0, _service.inject)(),
    model: function model(_ref, transition) {
      var end_stop_id = _ref.end_stop_id;
      var tripId = transition.routeInfos.findBy('name', 'trips.show').params['trip_id'];
      var recordType = this.tripStopType(tripId, end_stop_id);
      return this.get('store').peekRecord(recordType, end_stop_id);
    }
  });

  _exports.default = _default;
});