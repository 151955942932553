define("bottomline-execution/components/retail-site-form/authorize-row", ["exports", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'li',
    relatedTripStopTruckStorage: (0, _object.computed)('compartment', 'trip-stop', {
      get: function get() {
        return this.get('trip-stop.trip_stop_truck_storages').filterBy('TruckStorageID', this.get('compartment.truck_storage_id'))[0];
      }
    }),
    isCurrentQtyDefined: (0, _object.computed)('compartment.currentQty', function () {
      var currentQty = this.get('compartment.currentQty');
      return currentQty > 0;
    }),
    matchedTanks: (0, _object.computed)('tanks.@each.storageSequenceNb', function () {
      var _this = this;

      return this.get('tanks') // INFO: storages get loaded asynchronously, without that filtering the ui renders checkboxes without sequence numbers, if a user clicks such a checkbox before corresponding storage is loaded then things may be broken
      .filter(function (tank) {
        return (0, _utils.isPresent)(tank.get('storageSequenceNb'));
      }).filter(function (tank) {
        return tank.get('material.id') === _this.get('compartment.material_id');
      }).sortBy('storageSequenceNb');
    }),
    isCompartmentSelectionValid: (0, _object.computed)('compartmentsInvalidSelection.[]', 'compartment.id', function () {
      return !this.get('compartmentsInvalidSelection').includes(this.get('compartment.id'));
    }),
    actions: {
      toggleTank: function toggleTank(id) {
        var tankReference = this.get('compartment.tankReference');
        tankReference.find(function (tankId) {
          return tankId === id;
        }) ? tankReference.removeObject(id) : tankReference.addObject(id);
      }
    }
  });

  _exports.default = _default;
});