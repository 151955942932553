define("bottomline-execution/utils/timeout", ["exports", "@ember/runloop", "rsvp"], function (_exports, _runloop, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeout = timeout;

  function timeout(ms) {
    return new _rsvp.default.Promise(function (resolve) {
      return (0, _runloop.later)(function () {
        return resolve();
      }, ms);
    });
  }
});