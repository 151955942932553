define("bottomline-execution/serializers/truck-setting", ["exports", "ember-localstorage-adapter"], function (_exports, _emberLocalstorageAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberLocalstorageAdapter.LSSerializer.extend();

  _exports.default = _default;
});