define("bottomline-execution/components/input-number-spinner", ["exports", "bottomline-execution/components/input-number-base"], function (_exports, _inputNumberBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _inputNumberBase.default.extend({
    actions: {
      increaseValue: function increaseValue() {
        this._changeValue(+1);
      },
      decreaseValue: function decreaseValue() {
        this._changeValue(-1);
      }
    }
  });

  _exports.default = _default;
});