define("bottomline-execution/models/truck-inventory", ["exports", "@ember/object/computed", "@ember/object", "ember-data", "@ember/service"], function (_exports, _computed, _object, _emberData, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    store: (0, _service.inject)(),
    tankReference: attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    byConfirmedStartDT: ['ConfirmedStartDT:desc'],
    byConfirmedEndDT: ['ConfirmedEndDT:desc'],
    truck_storage_id: attr('string'),
    material_id: attr('string'),
    truck_id: attr('string'),
    maxQty: attr('number'),
    currentQty: attr('number'),
    minQty: attr('number'),
    isMaterialInventory: (0, _computed.equal)('truck_storage_id', '0'),
    isCompartmentInventory: (0, _computed.not)('isMaterialInventory'),
    startedTripsSorted: (0, _computed.sort)('startedTrips', 'byConfirmedStartDT'),
    finishedTripsSorted: (0, _computed.sort)('finishedTrips', 'byConfirmedEndDT'),
    tankReferenceArray: (0, _object.computed)('tankReference.[]', function () {
      return this.get('tankReference');
    }),
    material: (0, _object.computed)('material_id', 'isLoaded', function () {
      if (this.get('material_id')) {
        return this.get('store').peekRecord('material', this.get('material_id'));
      }
    }),
    materialAdr: (0, _object.computed)('material_id', function () {
      return this.get('store').peekRecord('adr-material', this.get('material_id'));
    }),
    TruckStorageID: (0, _computed.alias)('truck_storage_id'),
    truckStorage: (0, _object.computed)('truck_storage_id', function () {
      return this.get('store').peekRecord('truck-storage', this.get('truck_storage_id'));
    }),
    startedTrips: (0, _object.computed)(function () {
      return this.get('store').peekAll('trip').filter(function (item) {
        return item.get('state') === 'started';
      });
    }),
    finishedTrips: (0, _object.computed)(function () {
      return this.get('store').peekAll('trip').filter(function (item) {
        return item.get('state') === 'finished';
      });
    }),
    current15Qty: (0, _object.computed)('currentQty', function () {
      var _this = this;

      var qty15 = this.get('currentQty');
      var degreeFactor = 1.0;
      var trip = this.get('lastLoadTrip');

      if (trip) {
        trip.get('trip_stops').filterBy('ActivityCode', 'L').forEach(function (tripStop) {
          tripStop.get('trip_stop_truck_storages').filterBy('TruckStorageID', _this.get('truck_storage_id')).forEach(function (tripStopTruckStorage) {
            if (tripStopTruckStorage.get('confirmedQty') !== null && tripStopTruckStorage.get('confirmed15Qty') !== null && tripStopTruckStorage.get('confirmedQty') !== 0) {
              degreeFactor = tripStopTruckStorage.get('confirmed15Qty') / tripStopTruckStorage.get('confirmedQty');
            }
          });
        });
      }

      if (degreeFactor !== 1.0) {
        qty15 = this.get('currentQty') * degreeFactor;
      }

      return Math.round(qty15);
    }),
    lastLoadTrip: (0, _object.computed)(function () {
      if (this.get('startedTripsSorted').length > 0) {
        return this.get('startedTripsSorted')[0];
      } else if (this.get('finishedTripsSorted').length > 0) {
        return this.get('finishedTripsSorted')[0];
      }
    })
  });

  _exports.default = _default;
});