define("bottomline-execution/models/truck", ["exports", "ember-data", "bottomline-execution/mixins/vehicle-identifier"], function (_exports, _emberData, _vehicleIdentifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_vehicleIdentifier.default, {
    coalesceFindRequests: false,
    TruckID: attr('string'),
    License: attr('string'),
    HaulierID: attr('string'),
    MeterTypeID: attr('string'),
    VehicleType: attr('number'),
    haulier_description: attr('string'),
    truck_setting: belongsTo('truck-setting', {
      async: false
    }),
    truck_inventories: hasMany('truck-inventory')
  });

  _exports.default = _default;
});