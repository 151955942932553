define("bottomline-execution/services/location", ["exports", "@ember/object/computed", "@ember/utils", "@ember/service", "@ember/runloop", "bottomline-execution/utils/guid-util"], function (_exports, _computed, _utils, _service, _runloop, _guidUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    cordovaPlatform: (0, _service.inject)('ember-cordova/platform'),
    appState: (0, _computed.alias)('shared.appState'),
    currentLocation: null,
    lat: null,
    lng: null,
    accuracy: null,
    altitudeAccuracy: null,
    ownerCode: null,
    truckId: null,
    trackingInProgress: false,
    enableBackgroundModeTimeoutId: null,
    init: function init() {
      this._super.apply(this, arguments);

      this._initializeTracking();
    },
    _initializeBackgroundModeListeners: function _initializeBackgroundModeListeners() {
      document.addEventListener('pause', (0, _runloop.bind)(this, this.onPause), false);
      document.addEventListener('resume', (0, _runloop.bind)(this, this.onResume), false);
    },
    _initializeTracking: function _initializeTracking() {
      if (this.get('cordovaPlatform.isCordova')) {
        this._initializeBackgroundModeListeners();

        document.addEventListener('deviceready', (0, _runloop.bind)(this, this.startTracking), false);
      } else {
        this.startTracking();
      }
    },
    onResume: function onResume() {
      clearTimeout(this.get('enableBackgroundModeTimeoutId'));
      cordova.plugins.backgroundMode.disable();
    },
    onPause: function onPause() {
      var _this = this;

      var enableBackgroundModeTimeoutId = setTimeout(function () {
        _this.setupBackgroundMode();

        cordova.plugins.backgroundMode.enable();
      }, 5000);
      this.set('enableBackgroundModeTimeoutId', enableBackgroundModeTimeoutId);
    },
    setupBackgroundMode: function setupBackgroundMode() {
      // The plugin creates the object cordova.plugins.backgroundMode and is accessible after the deviceready event has been fired. (https://github.com/katzer/cordova-plugin-background-mode#usage)
      cordova.plugins.backgroundMode.setDefaults({
        title: 'Bottomline Execution',
        text: 'smarter ways',
        icon: 'icon'
      });
      cordova.plugins.backgroundMode.on('enable', function () {
        cordova.plugins.backgroundMode.disableWebViewOptimizations();
      });
    },
    startTracking: function startTracking() {
      if (!this.get('trackingInProgress')) {
        navigator.geolocation.getCurrentPosition((0, _runloop.bind)(this, this.geolocationSuccess), (0, _runloop.bind)(this, this.geolocationError), this.geolocationConfig);
        this.set('trackingInProgress', true);
      }
    },
    geolocationSuccess: function geolocationSuccess(location) {
      var currentTruck = this.get('appState').getTruck();
      if ((0, _utils.isEmpty)(currentTruck)) return;
      var truckPosition = this.get('store').createRecord('truck-position', {
        id: (0, _guidUtil.guidUtil)(),
        ownerCode: this.get('ownerCode'),
        truckId: this.get('truckId'),
        haulierId: currentTruck.get('HaulierID'),
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        speed: location.coords.speed,
        changeDt: new Date(location.timestamp)
      });
      truckPosition.saveAndUnload();
      this.set('currentLocation', truckPosition);
      this.set('lng', location.coords.longitude);
      this.set('lat', location.coords.latitude);
      setTimeout((0, _runloop.bind)(this, function () {
        this.set('trackingInProgress', false);
        this.startTracking();
      }), 60000);
    },
    geolocationError: function geolocationError() {
      this.resetWithDefaultValues();
      setTimeout((0, _runloop.bind)(this, function () {
        this.set('trackingInProgress', false);
        this.startTracking();
      }), 60000);
    },
    geolocationConfig: {
      enableHighAccuracy: true,
      timeout: 50000,
      maximumAge: 0
    },
    resetWithDefaultValues: function resetWithDefaultValues() {
      this.setProperties({
        currentLocation: null,
        lng: null,
        lat: null
      });
    }
  });

  _exports.default = _default;
});