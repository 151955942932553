define("bottomline-execution/services/framework7", ["exports", "@ember/service", "jquery"], function (_exports, _service, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var f7 = new Framework7({
    init: false,
    material: true,
    materialRipple: false,
    materialRippleElements: 'button'
  }); // f7.params.material = false; //ENABLE MATERIAL DESIGN
  // f7.theme = null; //THEME NAME, FOR EXAMPLE: 'theme-red'

  f7.f7Init = f7.init;

  f7.init = function () {
    if (f7.theme) {
      // SET THEME IF SPECIFIED
      (0, _jquery.default)('body').addClass(f7.theme);
    }

    return this._super();
  };

  var preloaderTimeout = null;

  var _default = _service.default.extend(f7, {
    showPreloader: function showPreloader(options) {
      if (options == null) {
        options = {};
      }

      if (options.delay) {
        preloaderTimeout = setTimeout(function () {
          preloaderTimeout = null;
          f7.showPreloader(options.statusMessage);
        }, options.delay);
      } else {
        f7.showPreloader(options.statusMessage);
      }
    },
    hidePreloader: function hidePreloader() {
      if (preloaderTimeout) {
        clearTimeout(preloaderTimeout);
        preloaderTimeout = null;
      } else {
        f7.hidePreloader();
      }
    },
    initSwipePanels: function initSwipePanels(panels) {
      f7.params.swipePanel = panels;
      f7.initSwipePanels();
    },
    extdPrompt: function extdPrompt(text, title, inputType, value, callbackOk, callbackCancel) {
      return f7.modal({
        text: text || '',
        title: typeof title === 'undefined' ? f7.params.modalTitle : title,
        afterText: '<div class="input-field"><input type="' + inputType + '" class="modal-text-input" value="' + value + '"></div>',
        buttons: [{
          text: f7.params.modalButtonCancel
        }, {
          text: f7.params.modalButtonOk,
          bold: true
        }],
        onClick: function onClick(modal, index) {
          if (index === 0 && callbackCancel) {
            callbackCancel(modal.find('.modal-text-input').val());
          }

          if (index === 1 && callbackOk) {
            callbackOk(modal.find('.modal-text-input').val());
          }
        }
      });
    }
  });

  _exports.default = _default;
});