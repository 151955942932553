define("bottomline-execution/components/f7-side-panel", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    f7: (0, _service.inject)('framework7'),
    data: null,
    side: 'right',
    actions: {
      open: function open() {
        this.get('f7').openPanel(this.get('side'));
      },
      close: function close() {
        this.get('f7').closePanel(this.get('side'));
      }
    }
  });

  _exports.default = _default;
});