define("bottomline-execution/templates/components/standalone-option-switch", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "J1ZeLz+L",
    "block": "[[[10,0],[14,0,\"standalone-option-switch item-content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"item-inner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item-title label\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item-input\"],[12],[1,\"\\n\"],[41,[30,0,[\"showLabel\"]],[[[1,\"        \"],[10,1],[12],[1,[28,[35,1],null,[[\"key\"],[[30,0,[\"label\"]]]]]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,\"label\"],[14,0,\"label-switch\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"value\"]]]],null],[1,\"\\n        \"],[10,0],[14,0,\"checkbox\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"editable-t\",\"input\"]]",
    "moduleName": "bottomline-execution/templates/components/standalone-option-switch.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});