define("bottomline-execution/serializers/bx/inventory-correction", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    mapResponse: function mapResponse(tripId) {
      // Analogous to createCorrectionStop() in the trip model
      var correctionStop = {
        id: "".concat(tripId, ":N"),
        type: 'inventory-correction',
        attributes: {
          ActivityCode: 'N',
          ConfirmedSequenceNb: 999,
          SequenceNb: 999,
          state: 'finished'
        }
      };
      return {
        tripStop: correctionStop
      };
    }
  });

  _exports.default = _default;
});