define("bottomline-execution/helpers/safe-css", ["exports", "@ember/component/helper", "@ember/template", "@ember/string"], function (_exports, _helper, _template, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.safeCss = safeCss;

  function safeCss(params, _ref) {
    var format = _ref.format;
    var processedParams = params.map(function (item) {
      return item || '';
    });
    var html = (0, _string.loc)(format, processedParams);
    return (0, _template.htmlSafe)(html);
  }

  var _default = (0, _helper.helper)(safeCss);

  _exports.default = _default;
});