define("bottomline-execution/models/truck-position", ["exports", "ember-data", "bottomline-execution/mixins/offline-errors"], function (_exports, _emberData, _offlineErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend(_offlineErrors.default, {
    ownerCode: attr('string'),
    truckId: attr('string'),
    haulierId: attr('string'),
    longitude: attr('number'),
    latitude: attr('number'),
    speed: attr('number'),
    changeDt: attr('date'),
    saveAndUnload: function saveAndUnload() {
      var _this = this;

      return this.save().then(function (truckPosition) {
        truckPosition.removeRecord();
      }).catch(function (error) {
        if (_this.isOfflineError(error)) {
          console.log("%cTruck position cached for later synchronization (".concat(error, ")"), 'color:red');
        } else {
          throw error;
        }
      });
    },
    removeRecord: function removeRecord() {
      this.deleteRecord();
      this.unloadRecord();
    }
  });

  _exports.default = _default;
});