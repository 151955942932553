define("bottomline-execution/helpers/increment", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.increment = increment;

  function increment(params) {
    return parseInt(params) + 1;
  }

  var _default = (0, _helper.helper)(increment);

  _exports.default = _default;
});