define("bottomline-execution/models/bx/customer", ["exports", "bottomline-execution/models/customer", "ember-data", "@ember/object", "@ember/object/computed"], function (_exports, _customer, _emberData, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _customer.default.extend({
    type: attr('string'),
    generateDeliveryTicket: (0, _computed.or)('_deliveryTicketCustomer', '_unrestrictedDeliveryTickets'),
    useFlowMeter: (0, _object.computed)('truckSettings.customer_type_with_flow_meter', 'type', function () {
      return this._caseInsensitiveCompare(this.get('truckSettings.customer_type_with_flow_meter'), this.get('type'));
    }),
    canAddStopFromOrder: (0, _computed.or)('_addStopFromOrderCustomer', '_unrestrictedAddStopFromOrder'),
    _deliveryTicketCustomer: (0, _object.computed)('truckSettings.restrict_delivery_tickets_for_customer_type', 'type', function () {
      return this._caseInsensitiveCompare(this.get('truckSettings.restrict_delivery_tickets_for_customer_type'), this.get('type'));
    }),
    _addStopFromOrderCustomer: (0, _object.computed)('truckSettings.customer_type_to_filter_orders', 'type', function () {
      return this._caseInsensitiveCompare(this.get('truckSettings.customer_type_to_filter_orders'), this.get('type'));
    }),
    _caseInsensitiveCompare: function _caseInsensitiveCompare(a, b) {
      /*
        The truck setting, passed-in as either the first or the second parameter,
        may be a boolean. In this case, return false to (a) leave the eventual
        feature setting to the _unrestricted<feature> CPs/default behavior, and
        (b) facilitate faster recognition (due to missing functionality) that the
        setting is incorrect.
      */
      if (typeof a !== 'string' || typeof b !== 'string') return false;
      return a.toUpperCase() === b.toUpperCase();
    }
  });

  _exports.default = _default;
});