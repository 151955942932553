define("bottomline-execution/serializers/bx/trip-stop-truck-storage", ["exports", "bottomline-execution/serializers/trip-stop-truck-storage", "@ember/utils"], function (_exports, _tripStopTruckStorage, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _tripStopTruckStorage.default.extend({
    mapResponse: function mapResponse(tripStopId, tripStopStorages, plannedActionsJson, executedActionsJson) {
      var _this = this;

      var plannedCompartmentIds = this._mergeCompartmentIds(plannedActionsJson, {});

      var compartmentIds = this._mergeCompartmentIds(executedActionsJson, plannedCompartmentIds);

      var actionDescriptors = Object.keys(compartmentIds).reduce(function (collector, vehicleId) {
        compartmentIds[vehicleId].forEach(function (compartmentId) {
          var plannedActions = _this._getActions(plannedActionsJson, vehicleId, compartmentId);

          var executedActions = _this._getActions(executedActionsJson, vehicleId, compartmentId);

          var siteStorageIds = Object.assign([], plannedActions.getEach('site_storage_id'), executedActions.getEach('site_storage_id'));
          siteStorageIds.forEach(function (siteStorageId) {
            return collector.push([vehicleId, compartmentId, siteStorageId]);
          });
        });
        return collector;
      }, []);
      var mappedTripStopTruckStorages = actionDescriptors.map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 3),
            vehicleId = _ref2[0],
            compartmentId = _ref2[1],
            storageId = _ref2[2];

        var planned = _this._getAction(plannedActionsJson, vehicleId, compartmentId, storageId);

        var executed = _this._getAction(executedActionsJson, vehicleId, compartmentId, storageId);

        var materialId = executed.product_id || planned.product_id;
        return {
          id: tripStopId + '-' + vehicleId + '-' + compartmentId + '-' + storageId,
          type: 'trip-stop-truck-storage',
          attributes: {
            confirmedQty: executed.volume_in_ambient_liters || null,
            confirmed15Qty: executed.volume_in_standard_liters || null,
            Density: executed.density_in_kilograms_per_cubic_meter || null,
            MaterialID: materialId,
            plannedQty: planned.volume_in_ambient_liters || 0,
            StorageID: storageId || _this._getStorageId(tripStopStorages, materialId),
            Temperature: executed.temperature_in_celsius || null,
            TripStopID: tripStopId,
            TruckID: vehicleId,
            TruckStorageID: compartmentId
          }
        };
      });
      return {
        data: mappedTripStopTruckStorages
      };
    },
    _mergeCompartmentIds: function _mergeCompartmentIds(actionsJson, initialCollection) {
      return actionsJson.reduce(function (collector, action) {
        (collector[action.vehicle_id] || (collector[action.vehicle_id] = [])).push(action.compartment_number);
        collector[action.vehicle_id] = collector[action.vehicle_id].uniq();
        return collector;
      }, initialCollection);
    },
    _getActions: function _getActions(actionsJson, vehicleId, compartmentId) {
      return actionsJson.filterBy('vehicle_id', vehicleId).filterBy('compartment_number', compartmentId) || {};
    },
    _getAction: function _getAction(actionsJson, vehicleId, compartmentId, storageId) {
      var actions = this._getActions(actionsJson, vehicleId, compartmentId);

      var action = (0, _utils.isPresent)(storageId) ? actions.findBy('site_storage_id', storageId) : actions.get('firstObject');
      return action || {};
    },
    _getStorageId: function _getStorageId(tripStopStorages, materialId) {
      var tripStopStorage = tripStopStorages.data.find(function (tss) {
        return tss.attributes.MaterialID == materialId;
      }).attributes;
      if ((0, _utils.isPresent)(tripStopStorage)) return tripStopStorage.StorageID;
    }
  });

  _exports.default = _default;
});