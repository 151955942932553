define("bottomline-execution/models/truck-storage", ["exports", "@ember/object", "@ember/service", "ember-data"], function (_exports, _object, _service, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    currentTruckInventory: (0, _service.inject)(),
    maxTruckStorageQty: attr('string'),
    minTruckStorageQty: attr('string'),
    TruckStorageID: attr('string'),
    truck_id: attr('string'),
    vehicle_type: attr('string'),
    truckInventory: (0, _object.computed)('TruckStorageID', function () {
      return this.get('currentTruckInventory').forVehicle(this.get('truck_id')).findBy('truck_storage_id', this.get('TruckStorageID'));
    })
  });

  _exports.default = _default;
});