define("bottomline-execution/utils/json-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toJSON = toJSON;

  function toJSON(object) {
    return JSON.parse(JSON.stringify(object));
  }
});