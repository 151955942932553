define("bottomline-execution/components/f7-page-content/f7-pull-to-refresh", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Mixin.create({
    setupPullToRefresh: function () {
      var _this = this,
          action = this.get('onPullToRefresh');

      if (action) {
        this.$().addClass('pull-to-refresh-content');
        this.set('hasPullToRefresh', true);
        this.get('f7').initPullToRefresh(this.$());
        this.$().on('refresh', function () {
          var deferred = _ember.default.RSVP.defer();

          deferred.promise.finally(function () {
            _this.get('f7').pullToRefreshDone();
          });

          _this.sendAction('onPullToRefresh', deferred);
        });
      }
    }.on('didInsertElement')
  });

  _exports.default = _default;
});