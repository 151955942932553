define("bottomline-execution/components/atg-information", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/service", "bottomline-execution/mixins/storages-loader"], function (_exports, _computed, _component, _object, _service, _storagesLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_storagesLoader.default, {
    store: (0, _service.inject)(),
    appState: (0, _computed.alias)('shared.appState'),
    online: (0, _computed.alias)('appState.online'),
    tripStopStorages: [],
    allShownTanks: (0, _object.computed)('tripStopStorages.[]', function () {
      return this.get('tripStopStorages').map(function (tss) {
        return tss.get('storage');
      });
    }),
    areDetailsShown: false,
    isAtgInfoLoading: false,
    detailsButtonTranslationKey: (0, _object.computed)('areDetailsShown', function () {
      if (this.get('areDetailsShown')) return 'problem-atg-sites.hideDetails';
      return 'problem-atg-sites.showDetails';
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('online')) {
        this.updateAtgInformation();
      }
    },
    updateAtgInformation: function updateAtgInformation() {
      var _this = this;

      this.set('isAtgInfoLoading', true);

      this._loadRecentInventories(this.get('allShownTanks')).finally(function () {
        if (!_this.isDestroyed) {
          _this.set('isAtgInfoLoading', false);
        }
      });
    },
    actions: {
      toggleDetails: function toggleDetails() {
        this.toggleProperty('areDetailsShown');
      }
    }
  });

  _exports.default = _default;
});