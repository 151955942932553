define("bottomline-execution/components/editable-t", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _computed, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _component.default.extend({
    session: (0, _service.inject)(),
    i18n: (0, _service.inject)(),
    localizationAjax: (0, _service.inject)(),
    f7: (0, _service.inject)('framework7'),
    tagName: '',
    canEdit: (0, _computed.readOnly)('session.session.content.authenticated.user_translator'),
    value: (0, _object.computed)('key', function () {
      return this.get('i18n').t(this.get('key'));
    }),
    isMissing: (0, _object.computed)('key', function () {
      return /^(~)/.test(this.get('i18n').t(this.get('key')).toString());
    }),
    save: function save() {
      var _this = this;

      var locale = this.get('i18n').locale;
      var data = {
        key: this.get('key'),
        value: this.get('value').toString(),
        namespace: 'deliveryplus'
      };
      this.set('key', '');
      this.get('localizationAjax').put("/localizations/".concat(locale), {
        contentType: 'application/json',
        data: JSON.stringify(data)
      }).then(function () {
        _this.get('i18n').addTranslations(locale, _defineProperty({}, data.key, data.value));

        _this.set('key', data.key);
      });
    },
    actions: {
      toggleEdit: function toggleEdit() {
        var __this = this;

        this.get('f7').prompt(this.get('key'), "Translation to ".concat(this.get('i18n').locale), function (s) {
          if (s.length > 0) {
            __this.set('value', s);

            __this.save();
          }
        });
      }
    }
  });

  _exports.default = _default;
});