define("bottomline-execution/components/end-inventory/trip-stop-storage-line", ["exports", "@ember/component", "@ember/object/computed", "@ember/object"], function (_exports, _component, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['trip-stop-storage'],
    classNameBindings: ['isSelected'],
    tagName: 'tr',
    activeTripStopStorageId: null,
    isSelected: (0, _object.computed)('activeTripStopStorageId', function () {
      return this.get('activeTripStopStorageId') === this.get('tripStopStorage.id');
    }),
    isEnabledCompartments: (0, _computed.alias)('truckSettings.compartments'),
    showAmbientLiters: (0, _computed.alias)('truckSettings.show_ambient_liters_on_unload'),
    showStandardLiters: (0, _computed.alias)('truckSettings.show_standard_liters_on_unload'),
    actions: {
      emptyTripStopTruckStorages: function emptyTripStopTruckStorages(tripStopStorage) {
        tripStopStorage.setProperties({
          'confirmedQty': 0,
          'confirmed15Qty': 0
        });
        var tripStopTruckStorages = tripStopStorage.get('trip_stop.trip_stop_truck_storages').filter(function (tsts) {
          return tsts.get('MaterialID') === tripStopStorage.get('MaterialID');
        });
        tripStopTruckStorages.forEach(function (tsts) {
          tsts.setProperties({
            'confirmedQty': 0,
            'confirmed15Qty': 0
          });
        });
      }
    }
  });

  _exports.default = _default;
});