define("bottomline-execution/controllers/trip-stops/material-transfer", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    internetConnection: (0, _service.inject)(),
    router: (0, _service.inject)(),
    actions: {
      returnToTripIndex: function returnToTripIndex() {
        this.router.transitionTo('trips.index');
      }
    }
  });

  _exports.default = _default;
});