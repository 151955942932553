define("bottomline-execution/mixins/trip-stop-type", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    tripStopType: function tripStopType() {
      var tripId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('TripID');
      var tripStopId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('TripStopID');
      var activityCode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.get('ActivityCode');
      var endInventoryByActivityCode = activityCode == 'E';
      var endInventoryById = tripStopId == "".concat(tripId, ":E");
      var endInventory = endInventoryByActivityCode || endInventoryById;
      return endInventory ? 'end-inventory' : 'trip-stop';
    }
  });

  _exports.default = _default;
});