define("bottomline-execution/adapters/be/picture", ["exports", "bottomline-execution/adapters/be/base-adapter", "@ember/service", "bottomline-execution/mixins/offline-errors", "bottomline-execution/utils/data-uri-to-blob-util", "bottomline-execution/utils/form-data-util", "bottomline-execution/utils/file-to-b64-util"], function (_exports, _baseAdapter, _service, _offlineErrors, _dataUriToBlobUtil, _formDataUtil, _fileToB64Util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseAdapter.default.extend(_offlineErrors.default, {
    formMultipart: (0, _service.inject)(),
    pictureSaver: (0, _service.inject)(),
    createRecord: function createRecord(store, type, snapshot) {
      var _this = this;

      var pictureData = {};
      var serializer = store.serializerFor('pictures');
      serializer.serializeIntoHash(pictureData, type, snapshot);

      var pictureDataForm = function pictureDataForm(pictureData, pictureImageData) {
        return (0, _formDataUtil.formDataBuilder)({
          picture: Object.assign({}, pictureData, {
            image_data: (0, _dataUriToBlobUtil.dataURItoBlobUtil)("data:image/jpeg;base64,".concat(pictureImageData))
          })
        });
      };

      var request = function request(pictureDataForm) {
        var imageSize = pictureDataForm.get('picture[image_data]').size;

        if (imageSize <= 0) {
          throw 'Picture file missing data';
        }

        _this.checkConnectivity();

        return _this.get('formMultipart').request('/pictures/', {
          processData: false,
          contentType: false,
          method: 'POST',
          data: pictureDataForm
        }).then(function (data) {
          return data;
        }).catch(function (originalError) {
          return _this.adaptForOffline(originalError).then(function (error) {
            throw error;
          });
        });
      };

      if (window.cordova) {
        return this.get('pictureSaver').findPictureFileByGuid(snapshot.record.get('BE_PictureGUID')).then(function (entry) {
          return (0, _fileToB64Util.fileToB64Util)(entry.nativeURL).then(function (base64String) {
            return request(pictureDataForm(pictureData.picture, base64String.split('base64,')[1]));
          });
        });
      } else {
        return request(pictureDataForm(pictureData.picture, pictureData.picture.image_data));
      }
    }
  });

  _exports.default = _default;
});