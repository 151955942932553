define("bottomline-execution/controllers/trip-stops/end-inventory", ["exports", "bottomline-execution/controllers/trip-stops/show", "@ember/object/computed", "@ember/object"], function (_exports, _show, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _show.default.extend({
    init: function init() {
      this.set('showMaterials', !this.get('truckSettings.compartments'));

      this._super.apply(this, arguments);
    },
    isCaptureRequired: (0, _object.computed)('model.hasPictures', function () {
      return this.get('truckSettings.on_end_inventory_mandatory_picture') && this.get('truckSettings.on_end_inventory_capture') && !this.get('model.hasPictures');
    }),
    isScanRequired: (0, _object.computed)('model.hasPictures', function () {
      return this.get('truckSettings.on_end_inventory_mandatory_picture') && this.get('truckSettings.on_end_inventory_scan') && !this.get('model.hasPictures');
    }),
    showAmbientLiters: (0, _computed.alias)('truckSettings.show_ambient_liters_on_unload'),
    showStandardLiters: (0, _computed.alias)('truckSettings.show_standard_liters_on_unload'),
    showTruckDetails: true,
    showTrailerDetails: true,
    actions: {
      truckIsEmpty: function truckIsEmpty() {
        this.get('model.trip_stop_truck_storages').setEach('confirmedQty', 0);
        this.get('model.trip_stop_truck_storages').setEach('confirmed15Qty', 0);
        this.get('model.trip_stop_storages').setEach('confirmedQty', 0);
        this.get('model.trip_stop_storages').setEach('confirmed15Qty', 0);
        this.get('model.truckInventories').setEach('currentQty', 0);
        this.get('model.trailerInventories').setEach('currentQty', 0);
        return false;
      }
    }
  });

  _exports.default = _default;
});