define("bottomline-execution/components/trip-state-updater", ["exports", "@ember/object/computed", "@ember/component"], function (_exports, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    action: null,
    isDisabled: false,
    allTrips: (0, _computed.alias)('trip.shift.trips'),
    nextStates: (0, _computed.alias)('trip.nextStates'),
    notifyAllTrips: function notifyAllTrips() {
      var _this = this;

      this.get('allTrips').reject(function (trip) {
        return trip === _this.get('trip');
      }).forEach(function (trip) {
        trip.notifyPropertyChange('state');
      });
    },
    showTrip: function showTrip(trip) {
      trip.set('isShown', true);
    },
    actions: {
      start: function start(state, model) {
        this.get('update-state')(state, model);
        this.notifyAllTrips();
        this.showTrip(model);
      },
      cancel: function cancel(state, model) {
        this.get('update-state')(state, model);
        this.notifyAllTrips();
      },
      restart: function restart(state, model) {
        this.get('update-state')(state, model);
        this.notifyAllTrips();
      },
      finish: function finish(state, model) {
        this.get('update-state')(state, model);
        this.notifyAllTrips();
      }
    }
  });

  _exports.default = _default;
});