define("bottomline-execution/templates/trip-stops/material-transfer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QqFE0q63",
    "block": "[[[1,[28,[35,0],null,[[\"startedTrip\",\"returnToTripIndex\"],[[30,0,[\"model\"]],[28,[37,1],[[30,0],\"returnToTripIndex\"],null]]]]],[1,\"\\n\"]],[],false,[\"material-transfer\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/trip-stops/material-transfer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});