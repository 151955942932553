define("bottomline-execution/adapters/bx/material", ["exports", "bottomline-execution/adapters/bx/base-adapter"], function (_exports, _baseAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseAdapter.default.extend({
    findAll: function findAll(_store, _type) {
      return this.get('ajaxService').request("".concat(this.get('siteService'), "/products"));
    }
  });

  _exports.default = _default;
});