define("bottomline-execution/components/odometer-field", ["exports", "@ember/component", "@ember/object", "bottomline-execution/config/environment"], function (_exports, _component, _object, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ATX_ENDPOINT = 'content://com.groeneveldictsolutions.android.atxapi.provider.VehicleInfoProvider/STATUS';

  var _default = _component.default.extend({
    tagName: 'li',
    odometerValue: '',
    hasError: false,
    isAtx: (0, _object.computed)(function () {
      return _environment.default.APP.target === 'atx' && typeof plugins !== 'undefined';
    }),
    didInsertElement: function didInsertElement() {
      if (this.get('isAtx')) {
        this.getMileageFromAtx();
      }
    },
    getMileageFromAtx: function getMileageFromAtx() {
      var _this = this;

      plugins.contentproviderplugin.query({
        contentUri: ATX_ENDPOINT
      }, function (data) {
        _this.set('odometerValue', Number(data[0].mileage));
      });
    },
    actions: {
      getMileage: function getMileage() {
        this.getMileageFromAtx();
      }
    }
  });

  _exports.default = _default;
});