define("bottomline-execution/routes/trip-stops/show/scan", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    setupController: function setupController(controller) {
      controller.set('model', this.modelFor('trip-stops.show'));
      controller.send('scan');
    }
  });

  _exports.default = _default;
});