define("bottomline-execution/routes/application", ["exports", "@ember/object/computed", "@ember/routing/route", "@ember/utils", "@ember/service", "jquery", "moment", "rsvp", "ember-simple-auth/mixins/application-route-mixin", "bottomline-execution/config/environment", "@sentry/browser"], function (_exports, _computed, _route, _utils, _service, _jquery, _moment, _rsvp, _applicationRouteMixin, _environment, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ATX_USERS_ENDPOINT = 'content://com.groeneveldictsolutions.android.atxapi.provider.UserInfoProvider/USERS';

  var _default = _route.default.extend(_applicationRouteMixin.default, {
    androidPermissions: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    truckSettingsAjax: (0, _service.inject)(),
    i18n: (0, _service.inject)(),
    hpScanner: (0, _service.inject)(),
    router: (0, _service.inject)(),
    session: (0, _service.inject)(),
    localization: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    f7: (0, _service.inject)('framework7'),
    store: (0, _service.inject)(),
    state: (0, _computed.alias)('controller.model'),
    isAtx: function isAtx() {
      return _environment.default.APP.target !== 'atx';
    },
    beforeModel: function beforeModel() {
      var _this = this;

      this.get('f7').showPreloader();
      return this.get('localization').load().then(function () {
        return _this._loadDataIfAuthenticated();
      }).finally(function () {
        _this.get('f7').hidePreloader();
      });
    },
    model: function model() {
      var state = this.get('store').peekRecord('state', 1);
      var language = state.get('language');

      if ((0, _utils.isPresent)(language)) {
        this.set('i18n.locale', language);
      }

      _moment.default.locale(language || _environment.default.i18n.defaultLocale);

      Sentry.setTag('alias', state.get('alias'));

      if ((0, _utils.isPresent)(state.get('currentTruck'))) {
        Sentry.setTag('currentTruck', state.get('currentTruck'));
      }

      if (this.get('session.isAuthenticated')) {
        Sentry.setUser({
          userData: this.get('session.session.content.authenticated'),
          applicationSettings: state.serialize(),
          appVersion: _environment.default.APP.version
        });
      } else {
        Sentry.setUser({
          userData: "Not authenticated",
          applicationSettings: state.serialize(),
          appVersion: _environment.default.APP.version
        });
      }

      return _rsvp.Promise.resolve(state);
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      this._confirmOrRequestPermissions();

      if (this.get('session.isAuthenticated')) {
        this.get('truckSettingsAjax').loadSettings(model.get('currentTruck')).then(function (truckSettings) {
          var errorKey = truckSettings.validate();

          if ((0, _utils.isPresent)(errorKey)) {
            _this2.get('toast').error(_this2.get('i18n').t(errorKey));
          }
        });
      }

      this._authenticateIfAtx().then(function (data) {
        if (data.length > 0) {
          _this2._loadDataIfAuthenticated().then(function () {
            _this2.router.transitionTo(data);
          });
        }
      }, function () {
        return _this2.router.transitionTo('login');
      });
    },
    _confirmOrRequestPermissions: function _confirmOrRequestPermissions() {
      this.get('androidPermissions').confirmPermission('EXTERNAL_STORAGE');
    },
    sessionInvalidated: function sessionInvalidated() {
      if (_environment.default.environment === 'test') {
        return;
      }

      if (!!window.cordova || window.location.port === '4200') {
        document.location = 'index.html';
      } else {
        document.location = '';
      }
    },
    routeAfterAuthentication: 'edit-truck',
    _authenticateIfAtx: function _authenticateIfAtx() {
      var _this3 = this;

      var deferred = _rsvp.default.defer();

      if (this.get('session.isAuthenticated') && _environment.default.APP.target === 'atx') {
        console.warn("while starting it's authenticated, first make sure to log out");
      }

      if (_environment.default.APP.target !== 'atx') {
        deferred.resolve('');
        return deferred.promise;
      }

      if (typeof plugins === 'undefined') {
        deferred.reject();
        return deferred.promise;
      }

      if (plugins == null || plugins.contentproviderplugin == null) {
        this.set('errorMessage', this.get('i18n').t('plugin_not_found'));
        deferred.reject();
        return deferred.promise;
      }

      plugins.contentproviderplugin.query({
        contentUri: ATX_USERS_ENDPOINT
      }, function (data) {
        // NOTE: Yes. Every driver with an ATX has the same password. I cried too.
        _this3.get('session').authenticate('authenticator:devise', data[0].user_code, 'schenk1234').then(function () {
          var currentTruck = _this3.get('state.currentTruck');

          var truckDefined = currentTruck && currentTruck.length > 0;
          var targetRoute = truckDefined ? 'shifts' : 'edit-truck';

          _this3.set('state.scanner_brand', 'canon');

          console.info("Truck found: ", currentTruck);
          console.info("Navigating: ", targetRoute);
          return deferred.resolve(targetRoute);
        }).catch(function (error) {
          // logging in with the ATX provided credentials failed.
          _this3.set('errorMessage', _this3.get('i18n').t(error));

          deferred.reject();
        });
      }, function (error) {
        _this3.set('errorMessage', _this3.get('i18n').t(error.info));

        deferred.reject();
      });
      return deferred.promise;
    },
    _loadDataIfAuthenticated: function _loadDataIfAuthenticated() {
      var store = this.get('store');

      if (this.get('session.isAuthenticated')) {
        return _rsvp.default.all([store.findAll('material', {
          reload: true
        }), store.findAll('material-seller', {
          reload: true
        }), store.findAll('adr-material', {
          reload: true
        }), store.findAll('otc-material', {
          reload: true
        }), store.findAll('truck', {
          reload: true
        }), store.findAll('trailer', {
          reload: true
        })]);
      }

      return _rsvp.default.Resolve;
    },
    actions: {
      willTransition: function willTransition() {
        (0, _jquery.default)('.map').remove();
      },
      closePanel: function closePanel(pos) {
        this.get('f7').closePanel(pos);
      },
      openPanel: function openPanel(pos) {
        this.get('f7').openPanel(pos);
      }
    }
  });

  _exports.default = _default;
});