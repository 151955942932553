define("bottomline-execution/templates/components/end-inventory/trip-stop-storage-line", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gO8y4xao",
    "block": "[[[10,\"td\"],[15,5,[30,0,[\"tripStopStorage\",\"material\",\"style\"]]],[12],[1,\"\\n  \"],[1,[30,0,[\"tripStopStorage\",\"material\",\"MaterialNameShort\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,\"\\n  \"],[1,[30,0,[\"tripStopStorage\",\"currentInventory\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"list-block\"],[12],[1,\"\\n\"],[41,[30,0,[\"showAmbientLiters\"]],[[[1,\"    \"],[10,0],[14,0,\"item-input\"],[12],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"numericValue\",\"disabled\"],[[30,0,[\"tripStopStorage\",\"confirmedQty\"]],[30,0,[\"isEnabledCompartments\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"list-block\"],[12],[1,\"\\n\"],[41,[30,0,[\"showStandardLiters\"]],[[[1,\"    \"],[10,0],[14,0,\"item-input\"],[12],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"numericValue\",\"disabled\"],[[30,0,[\"tripStopStorage\",\"confirmed15Qty\"]],[30,0,[\"isEnabledCompartments\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[41,[30,0,[\"isEnabledCompartments\"]],[[[1,\"  \"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"actionOnClick\",\"translationKey\"],[[28,[37,3],[[30,0],\"emptyTripStopTruckStorages\",[30,0,[\"tripStopStorage\"]]],null],\"empty_compartments\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"input-number\",\"bl-components/button\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/components/end-inventory/trip-stop-storage-line.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});