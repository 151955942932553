define("bottomline-execution/routes/login", ["exports", "@ember/routing/route", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _route, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_unauthenticatedRouteMixin.default, {});

  _exports.default = _default;
});