define("bottomline-execution/services/request-headers", ["exports", "rsvp", "@ember/service", "bottomline-execution/mixins/version", "bottomline-execution/config/environment"], function (_exports, _rsvp, _service, _version, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend(_version.default, {
    deviceInformation: (0, _service.inject)(),
    session: (0, _service.inject)(),
    headers: function headers() {
      var _this = this;

      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$authorize = _ref.authorize,
          authorize = _ref$authorize === void 0 ? false : _ref$authorize;

      return new _rsvp.Promise(function (resolve) {
        var headers = _this.defaultHeaders({
          authorize: authorize
        });

        _this.get('deviceInformation').getMacAddress().then(function (macaddress) {
          headers['device-guid'] = macaddress;
          resolve(headers);
        }, function (_failure) {
          resolve(headers); // Do not set 'device-guid' if there is no MAC address
        });
      });
    },
    defaultHeaders: function defaultHeaders() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref2$authorize = _ref2.authorize,
          authorize = _ref2$authorize === void 0 ? false : _ref2$authorize;

      var session = this.get('session');
      var headers = {
        'App-Version': this.get('appVersionNumber'),
        'Content-Type': 'application/json'
      };

      if (authorize) {
        var authorizers = {
          devise: this._deviseAuthorizer,
          auth0: this._auth0Authorizer
        };
        var authorizerName = session.get('session.authenticator').split(':')[1];
        headers['Authorization'] = authorizers[authorizerName](session);
      }

      return headers;
    },
    _deviseAuthorizer: function _deviseAuthorizer(session) {
      var userToken = session.get("data.authenticated.".concat(_environment.default.devise.tokenAttributeName));
      var userName = session.get("data.authenticated.".concat(_environment.default.devise.identificationAttributeName));
      return "Token user_token=\"".concat(userToken, "\", user_name=\"").concat(userName, "\"");
    },
    _auth0Authorizer: function _auth0Authorizer(session) {
      var accessToken = session.get('data.authenticated.access_token');
      return "Bearer ".concat(accessToken);
    }
  });

  _exports.default = _default;
});