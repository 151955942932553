define("bottomline-execution/serializers/bx/truck-storage", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    mapResponse: function mapResponse(vehicleType, compartmentsJson) {
      var mappedCompartments = compartmentsJson.map(function (json) {
        return {
          id: json.vehicle_id + '_' + json.number,
          type: 'truck-storage',
          attributes: {
            maxTruckStorageQty: json.maximum_quantity,
            truck_id: json.vehicle_id,
            TruckStorageID: json.number,
            vehicle_type: vehicleType
          }
        };
      });
      return {
        data: mappedCompartments
      };
    }
  });

  _exports.default = _default;
});