define("bottomline-execution/models/shift", ["exports", "@ember/object/computed", "@ember/object", "@ember/utils", "ember-data", "bottomline-execution/mixins/trip-sorter", "bottomline-execution/mixins/state-transition", "bottomline-execution/mixins/adapter-options", "bottomline-execution/mixins/offline-errors", "bottomline-execution/mixins/timestamp", "@ember/service"], function (_exports, _computed, _object, _utils, _emberData, _tripSorter, _stateTransition, _adapterOptions, _offlineErrors, _timestamp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_stateTransition.default, _tripSorter.default, _adapterOptions.default, _timestamp.default, _offlineErrors.default, {
    store: (0, _service.inject)(),
    startable: true,
    ResourceID: attr('string'),
    ResourceName: attr('string'),
    PlannedStartDT: attr('date'),
    ConfirmedStartDT: attr('date'),
    ConfirmedEndDT: attr('date'),
    state: attr('string'),
    remarks: attr('string'),
    TruckID: attr('string'),
    TruckLicense: attr('string'),
    TrailerID: attr('string'),
    RegionID: attr('string'),
    TrailerLicense: attr('string'),
    OdometerStart: attr('number'),
    OdometerEnd: attr('number'),
    question_list_before: attr('number'),
    question_list_after: attr('number'),
    adr_report_template_name: attr('string'),
    truck: attr(),
    trailer: attr(),
    CompoundVehicleID: attr('string'),
    truck_inventories: hasMany('truck-inventory', {
      async: false
    }),
    truck_storages: hasMany('truck-storage', {
      async: false
    }),
    trips: hasMany('trips', {
      async: true
    }),
    appState: (0, _computed.alias)('shared.appState'),
    nextStates: (0, _object.computed)('state', 'canStart', 'canFinish', function () {
      return this.get('transitions')[this.get('state')];
    }),
    hasStartedElements: (0, _computed.alias)('hasStartedTrips'),
    hasStartedTrips: (0, _object.computed)('trips.isFulfilled', 'trips.@each.state', function () {
      return !this.get('trips').every(function (trip) {
        return trip.get('state') === 'planned';
      });
    }),
    started: (0, _computed.equal)('state', 'started'),
    finished: (0, _computed.equal)('state', 'finished'),
    shifts: (0, _object.computed)(function () {
      return this.get('store').peekAll('shift').filterBy('TruckID', this.get('appState.currentTruck'));
    }),
    startedShift: (0, _object.computed)('shifts.@each.state', function () {
      return this.get('shifts').findBy('state', 'started');
    }),
    startedTrip: (0, _object.computed)('trips.@each.state', function () {
      return this.get('trips').findBy('state', 'started');
    }),
    showQuestions: (0, _computed.readOnly)('appState.truckSettings.questionaire'),
    canStart: (0, _object.computed)('startable', 'startedShift.id', function () {
      if (this.get('startable') && (!this.get('startedShift') || this.get('startedShift.id') === this.get('id'))) {
        return true;
      }

      return false;
    }),
    canFinish: (0, _computed.not)('hasUnfinishedTrips'),
    hasUnfinishedTrips: (0, _object.computed)('trips.isFulfilled', 'trips.@each.state', function () {
      return this.get('trips').any(function (trip) {
        return trip.get('state') !== 'finished';
      });
    }),
    canReorderTripStops: (0, _computed.alias)('hasUnfinishedTrips'),
    tripStops: (0, _object.computed)('trips.isFulfilled', 'isFulfilled', function () {
      var coll = this.get('trips').map(function (trip) {
        return trip.get('trip_stops').toArray();
      });
      return [].concat.apply([], coll);
    }),
    activeTruck: (0, _object.computed)('TruckID', function () {
      return this.get('store').peekAll('truck').findBy('TruckID', this.get('TruckID'));
    }),
    activeTrailer: (0, _object.computed)('TrailerID', function () {
      return this.get('store').peekAll('trailer').findBy('TruckID', this.get('TrailerID'));
    }),
    meterType: (0, _object.computed)('TrailerID', 'activeTruck.@each', 'activeTrailer.@each', function () {
      if ((0, _utils.isPresent)(this.get('TrailerID')) && (0, _utils.isPresent)(this.get('activeTrailer.MeterTypeID'))) {
        return this.get('activeTrailer.MeterTypeID');
      }

      if ((0, _utils.isPresent)(this.get('TruckID')) && (0, _utils.isPresent)(this.get('activeTruck.MeterTypeID'))) {
        return this.get('activeTruck.MeterTypeID');
      }

      return null;
    }),
    getMaxTripSequenceNb: function getMaxTripSequenceNb() {
      return Math.max.apply(null, this.get('trips').map(function (trip) {
        return trip.get('ConfirmedSequenceNb') !== undefined ? trip.get('ConfirmedSequenceNb') : -1;
      }));
    },
    start: function start() {
      this.setTimeIfEmpty('ConfirmedStartDT');
      this.set('state', 'started');
      return this.save(this.adapterAction('start'));
    },
    restart: function restart() {
      this.set('state', 'started');
      return this.save(this.adapterAction('restart'));
    },
    finish: function finish() {
      this.setTimeIfEmpty('ConfirmedEndDT');
      this.set('state', 'finished');
      return this.save(this.adapterAction('finish'));
    },
    cancel: function cancel() {
      this.set('ConfirmedStartDT', null);
      this.set('state', 'planned');
      return this.save(this.adapterAction('cancel'));
    },
    reload: function reload() {
      throw '.reload() should not be called on the Shift model (see EP-565)';
    },
    save: function save() {
      var _this = this;

      return this._super.apply(this, arguments).catch(function (error) {
        if (_this.isOfflineError(error)) {
          console.log("%cShift cached for later synchronization (".concat(error, ")"), 'color:red');
        } else {
          throw error;
        }
      });
    }
  });

  _exports.default = _default;
});