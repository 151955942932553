define("bottomline-execution/templates/components/trip-stops/show/unload/postdip-ullage", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gNA7sRdJ",
    "block": "[[[1,[28,[35,0],null,[[\"numericValue\"],[[30,0,[\"postDipUllage\"]]]]]],[1,\"\\n\"]],[],false,[\"input-number\"]]",
    "moduleName": "bottomline-execution/templates/components/trip-stops/show/unload/postdip-ullage.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});