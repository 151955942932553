define("bottomline-execution/utils/delivery-ticket-nb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deliveryTicketNb = deliveryTicketNb;

  function deliveryTicketNb(truckId, meterIndex, ticketNb) {
    return "".concat(truckId).concat(String(new Date().getFullYear()).substring(2)).concat(Number(meterIndex) + 1).concat(String(ticketNb).slice(-4));
  }
});