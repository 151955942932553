define("bottomline-execution/serializers/bx/truck-inventory", ["exports", "@ember/object", "@ember/service"], function (_exports, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    store: (0, _service.inject)(),
    mapResponse: function mapResponse(truckInventoriesJson) {
      var _this = this;

      var mappedTruckInventories = truckInventoriesJson.map(function (json) {
        var vehicleId = json.vehicle_id;
        var inventoriesJson = json.compartment_inventories;
        return inventoriesJson.map(function (json) {
          var truckStorageId = json.number;
          return {
            id: vehicleId + '_' + truckStorageId,
            type: 'truck-inventory',
            attributes: {
              currentQty: json.volume_in_liters,
              material_id: json.product_id,
              maxQty: _this._truckStorageMaxQty(vehicleId, truckStorageId),
              truck_id: vehicleId,
              truck_storage_id: truckStorageId
            }
          };
        });
      }).flat();
      return {
        data: mappedTruckInventories
      };
    },
    _truckStorageMaxQty: function _truckStorageMaxQty(vehicleId, truckStorageId) {
      return this.get('store').peekAll('truck-storage').filterBy('truck_id', vehicleId).findBy('TruckStorageID', truckStorageId).get('maxTruckStorageQty') * 1000;
    }
  });

  _exports.default = _default;
});