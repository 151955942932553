define("bottomline-execution/components/f7-checkbox-tag", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['option-group'],
    checkedChanged: (0, _object.computed)('checked', function () {
      if (this.get('parentController')) {
        var selectedStorages = this.get('parentController.selectedSite.storages').filter(function (storage) {
          return storage.get('selected') === true;
        });
        this.set('parentController.selectedStorages', selectedStorages);
      }
    })
  });

  _exports.default = _default;
});