define("bottomline-execution/components/f7-page-content", ["exports", "ember", "bottomline-execution/components/f7-page-content/f7-pull-to-refresh", "bottomline-execution/components/f7-page-content/f7-infinite-scroll"], function (_exports, _ember, _f7PullToRefresh, _f7InfiniteScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend(_f7PullToRefresh.default, _f7InfiniteScroll.default, {
    classNames: ['page-content'],
    hasInfiniteScroll: false,
    hasPullToRefresh: false
  });

  _exports.default = _default;
});