define("bottomline-execution/templates/dashboard", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lexDES9O",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"row no-gutter dashboard\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-50 left\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n      \"],[10,\"img\"],[14,\"src\",\"assets/bottomline-green.png\"],[14,\"width\",\"250px\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"col-50 right\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"src\",\"assets/be.png\"],[14,\"width\",\"100px\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n      \"],[10,0],[14,0,\"title\"],[12],[1,\"execution\"],[13],[1,\"\\n      Bottomline Execution is part of the Bottomline Software Suite\\n      \"],[10,\"br\"],[12],[13],[1,\"\\n      © 2016 Bottomline\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[],false,[]]",
    "moduleName": "bottomline-execution/templates/dashboard.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});