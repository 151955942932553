define("bottomline-execution/serializers/bx/site-remark", ["exports", "@ember/object", "@ember/utils"], function (_exports, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    mapResponse: function mapResponse(json) {
      if ((0, _utils.isEmpty)(json.remarks)) return {
        data: []
      };
      var mappedSiteRemarks = [{
        id: json.site_id + '-1',
        type: 'site-remark',
        attributes: {
          Remark: json.remarks
        }
      }];
      return {
        data: mappedSiteRemarks
      };
    }
  });

  _exports.default = _default;
});