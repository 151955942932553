define("bottomline-execution/utils/crypto-utils", ["exports", "bottomline-execution/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function checkEqualityWithVerificationHash(str) {
    if (!str) return false;
    var hash = CryptoJS.SHA256(str).toString();
    return _environment.default.adminUIPasswordHashes.includes(hash);
  }

  var _default = {
    checkEqualityWithVerificationHash: checkEqualityWithVerificationHash
  };
  _exports.default = _default;
});