define("bottomline-execution/templates/components/details-vehicle-unload", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iq3yK3Go",
    "block": "[[[10,\"table\"],[12],[1,\"\\n  \"],[10,\"tr\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"truckInventories\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,2],null,[[\"truckInventory\",\"trip-stop\",\"tripStopStorage\",\"truckSettings\",\"showAllCompartments\",\"showStandardLiters\"],[[30,1],[30,0,[\"trip-stop\"]],[30,0,[\"tripStopStorage\"]],[30,0,[\"truckSettings\"]],[30,0,[\"showAllCompartments\"]],[30,0,[\"showStandardLiters\"]]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"truckInventory\"],false,[\"each\",\"-track-array\",\"trip-stop-truck-storage-unload\"]]",
    "moduleName": "bottomline-execution/templates/components/details-vehicle-unload.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});