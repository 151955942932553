define("bottomline-execution/templates/components/trip-stops/show/unload/predip-ullage", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xeJSqGAg",
    "block": "[[[1,[28,[35,0],null,[[\"numericValue\",\"disabled\"],[[30,0,[\"preDipUllage\"]],[28,[37,1],[[30,0,[\"isEditable\"]]],null]]]]],[1,\"\\n\"]],[],false,[\"input-number\",\"not\"]]",
    "moduleName": "bottomline-execution/templates/components/trip-stops/show/unload/predip-ullage.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});