define("bottomline-execution/models/terminal", ["exports", "bottomline-execution/models/site"], function (_exports, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _site.default.extend({});

  _exports.default = _default;
});