define("bottomline-execution/models/trip-stop-storage", ["exports", "@ember/object/computed", "@ember/object", "@ember/utils", "@ember/service", "ember-data", "moment", "bottomline-execution/validations/trip-stop-storage-validations", "bottomline-execution/models/base", "bottomline-execution/utils/math-util", "@sentry/browser", "bottomline-execution/utils/is-zero-or-greater"], function (_exports, _computed, _object, _utils, _service, _emberData, _moment, _tripStopStorageValidations, _base, _mathUtil, Sentry, _isZeroOrGreater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _base.default.extend(_tripStopStorageValidations.Validations, {
    appState: (0, _computed.alias)('shared.appState'),
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    currentTruckInventory: (0, _service.inject)(),
    store: (0, _service.inject)(),
    disableStorageScanButton: true,
    TripStopID: attr('string'),
    plannedQty: attr('number'),
    confirmedQty: attr('number', {
      defaultValue: 0
    }),
    confirmed15Qty: attr('number', {
      defaultValue: 0
    }),
    TicketNb: attr('string'),
    MeterDeliveryInfo: attr('string'),
    meterQty: attr('number'),
    meter15Qty: attr('number'),
    MaterialID: attr('string'),
    MaterialBuyerCode: attr('string'),
    StorageID: attr('number'),
    confirmedWeight: attr('number'),
    meterWeight: attr('number'),
    Density: attr('number'),
    Temperature: attr('string'),
    TripID: attr('number'),
    startInventoryQty: attr('number'),
    endInventoryQty: attr('number'),
    DropCount: attr('number'),
    TankFilledYN: attr('boolean', {
      defaultValue: false
    }),
    orderQty: attr('number'),
    PreDeliveryPct: attr('number'),
    PreDeliveryPressure: attr('number'),
    PreDeliveryTemp: attr('number'),
    PostDeliveryPct: attr('number'),
    PostDeliveryPressure: attr('number'),
    PostDeliveryTemp: attr('number'),
    postDipUllage: (0, _object.computed)('endInventoryQty', 'storage.usableStorageQty', function () {
      var usableStorageQty = this.get('storage.usableStorageQty');
      var endInventoryQty = this.get('endInventoryQty');

      if ((0, _utils.isPresent)(usableStorageQty) && (0, _isZeroOrGreater.isZeroOrGreater)(endInventoryQty)) {
        return usableStorageQty - endInventoryQty;
      }

      return null;
    }),
    preDipUllage: (0, _object.computed)('startInventoryQty', 'storage.usableStorageQty', function () {
      var usableStorageQty = this.get('storage.usableStorageQty');
      var startInventoryQty = this.get('startInventoryQty');

      if ((0, _utils.isPresent)(usableStorageQty) && (0, _isZeroOrGreater.isZeroOrGreater)(startInventoryQty)) {
        return usableStorageQty - startInventoryQty;
      }

      return null;
    }),
    tssMarker: null,
    processDeliveryFlag: true,
    processDelivery: (0, _object.computed)('processDeliveryFlag', 'plannedQty', 'trip_stop.hasSeningMeter', function () {
      return this.get('processDeliveryFlag') === true && this.get('plannedQty') > 0 && this.get('trip_stop.hasSeningMeter');
    }),
    trip: belongsTo('trip', {
      async: false
    }),
    trip_stop: belongsTo('trip-stop', {
      async: false
    }),
    storage: belongsTo('storage', {
      async: false
    }),
    deliveryTolerance: (0, _computed.alias)('material.material_delivery_tolerance'),
    derive_delivery_tank_15qty: (0, _computed.alias)('truckSettings.derive_delivery_tank_15qty'),
    confirmedQtyChanged: (0, _object.observer)('confirmedQty', function () {
      this.set('disableStorageScanButton', !this.get('confirmedQty'));
    }),
    storageSequenceNb: _object.computed.alias('storage.SequenceNb'),
    unitInKgChanged: (0, _object.observer)('trip_stop.unitInKg', function () {
      if ((0, _utils.isEmpty)(this.get('material'))) {
        return;
      }

      if ((0, _utils.isEmpty)(this.get('Density')) && this.get('isLoaded')) {
        this.set('Density', this.get('material').get('MaterialDensity'));
      }

      if (this.get('trip_stop') && this.get('trip_stop').get('unitInKg')) {
        this.set('confirmedWeight', Math.round(this.get('confirmedQty') * this.get('Density')) || 0);
      }
    }),
    changedConfirmedWeight: (0, _object.observer)('confirmedWeight', function () {
      if (this.get('trip_stop') && this.get('trip_stop').get('unitInKg')) {
        this.set('confirmedQty', Math.round(this.get('confirmedWeight') / this.get('Density')));
      }
    }),
    changedConfirmedQty: (0, _object.observer)('confirmedQty', function () {
      if (this.get('trip_stop') && !this.get('trip_stop').get('unitInKg')) {
        this.set('confirmedWeight', Math.round(this.get('confirmedQty') * this.get('Density')));
      }
    }),
    dropCountObserver: (0, _object.observer)('DropCount', function () {
      if (this.get('DropCount') >= 1 && (0, _utils.isEmpty)(this.get('confirmedQty'))) {
        this.set('confirmedQty', 0);
      }
    }),
    material: (0, _object.computed)('MaterialID', 'material.@each.isLoaded', function () {
      if (this.get('MaterialID')) {
        return this.get('store').peekRecord('material', this.get('MaterialID'));
      }
    }),
    inventoryDataIsRecent: (0, _object.computed)('trip_stop.ConfirmedArrivalDT', 'trip_stop.PlannedArrivalDT', 'storage.recent_inventory.InventoryDT', function () {
      var inventoryDate = this.get('storage.recent_inventory.InventoryDT');

      if (inventoryDate) {
        var targetDate = this.get('trip_stop.ConfirmedArrivalDT') || this.get('trip_stop.PlannedArrivalDT');
        return (0, _moment.default)(inventoryDate).add(4, 'hours').isAfter(targetDate);
      }
    }),
    ATGSignalsProblem: (0, _object.computed)('storage.ATGDataAvailable', function () {
      if (!this.get('storage.ATGDataAvailable')) return false;
      return this.get('isPlannedQtyExceedsAvailableQty');
    }),
    hasOutdatedInventory: (0, _computed.alias)('storage.recent_inventory.isOutdated'),
    truckId: (0, _object.computed)('TripStopID', 'StorageID', 'appState.truckSettings.compartments', function () {
      var _this = this;

      var compartmentsEnabled = this.get('appState.truckSettings.compartments');
      var tripStopTruckStorages = this.get('store').peekAll('trip-stop-truck-storage').filter(function (tsts) {
        return tsts.get('TripStopID') === _this.get('TripStopID') && tsts.get('StorageID') === _this.get('StorageID');
      });
      var tripStopTruckStorage = compartmentsEnabled ? tripStopTruckStorages.findBy('isCompartmentRecord') : tripStopTruckStorages.findBy('isTssSummaryRecord');

      if (tripStopTruckStorage) {
        return tripStopTruckStorage.get('TruckID');
      }

      return this.get('trip.shift.TrailerID') || this.get('trip.shift.TruckID');
    }),
    meterType: (0, _object.computed)('truckId', function () {
      var truckId = this.get('truckId');

      if (truckId) {
        var meterTarget = this.get('store').peekRecord('truck', truckId) || this.get('store').peekAll('trailer').compact().find(function (trailer) {
          return trailer.get('TruckID') === truckId;
        });
        return meterTarget.get('MeterTypeID');
      }
    }),
    startInventoryQtyPercentage: (0, _object.computed)('startInventoryQty', {
      get: function get() {
        if (!this.get('storage')) {
          return 0;
        }

        var val = this.get('startInventoryQty') / this.get('storage').get('totalStorageQty') * 100;
        return (0, _mathUtil.round)(val, 0);
      },
      set: function set(key, val) {
        var total = this.get('storage').get('totalStorageQty');
        var result = Number(val) / 100 * total;
        this.set('startInventoryQty', (0, _mathUtil.round)(result, 0));
        return val;
      }
    }),
    totalCurrentQty: (0, _object.computed)(function () {
      var targetQuantity = this.get('unitOfCalculationQty');
      return this.startInventory() ? this.startInventory().get(targetQuantity) : 0;
    }),
    totalQty: (0, _object.computed)('confirmedQty', 'confirmed15Qty', function () {
      var targetQuantity = this.get('unitOfCalculationQty');
      var confirmedQty = Number(this.get(targetQuantity));
      return isNaN(confirmedQty) ? this.get('totalCurrentQty') : this.get('totalCurrentQty') + confirmedQty;
    }),
    currentInventory: (0, _object.computed)(function () {
      return this.get('confirmedQty');
    }),
    currentCompartmentInventory: (0, _object.computed)('trip_stop.shift.truck_inventories.@each.currentQty', function () {
      var _this2 = this;

      var truckInventories = this.get('currentTruckInventory').byCompartment().filter(function (truckInventory) {
        return truckInventory.get('material_id') === _this2.get('MaterialID');
      });

      if (truckInventories.length === 0) {
        return {
          totalcurrentQty: 0
        };
      }

      return this.groupBy(truckInventories, 'MaterialID', ['currentQty'])[0];
    }),
    hasQty: (0, _computed.or)('confirmedQty', 'plannedQty'),
    plannedQtyInWeight: (0, _object.computed)('Density', 'plannedQty', function () {
      return Math.round(this.get('Density') * this.get('plannedQty'));
    }),
    currentQtyInWeight: (0, _object.computed)('Density', 'totalCurrentQty', function () {
      return Math.round(this.get('Density') * this.get('totalCurrentQty'));
    }),
    totalQtyInWeight: (0, _object.computed)('Density', 'totalQty', function () {
      return Math.round(this.get('Density') * this.get('totalQty'));
    }),
    isPlannedQtyExceedsAvailableQty: (0, _object.computed)('plannedQty', 'storage.availableQty', function () {
      if ((0, _utils.isBlank)(this.get('storage.availableQty'))) return false;
      return this.get('plannedQty') > this.get('storage.availableQty');
    }),
    tripStopTruckStorages: function tripStopTruckStorages() {
      return this.get('trip_stop').tripStopTruckStoragesForStorage(this.get('StorageID')).filterBy('isCompartmentRecord');
    },
    startInventory: function startInventory() {
      var tripStop = this.get('trip_stop.trip').startInventoryStop();
      return tripStop.get('trip_stop_storages').findBy('MaterialID', this.get('MaterialID'));
    },
    _setConfirmedQty: function _setConfirmedQty(targetQuantity) {
      if (!this.get('truckSettings.compartments')) return;

      var sum = function sum(_sum, current) {
        return _sum + Number(current);
      }; // Define reducer function


      var total = null;

      if (targetQuantity === 'confirmed15Qty' && this._confirmed15QtyDerivationRequired()) {
        total = (0, _mathUtil.round)(this._derivedConfirmed15Qtys().reduce(sum, 0));
      } else {
        total = this.tripStopTruckStorages().mapBy(targetQuantity).reduce(sum, 0);
      }

      if (isFinite(total)) this.set(targetQuantity, total);
    },
    _confirmed15QtyDerivationRequired: function _confirmed15QtyDerivationRequired() {
      return this.get('derive_delivery_tank_15qty') && this.get('truckSettings.show_standard_liters_on_load') // Required to meaningfully derive from load data
      && this.get('trip_stop.isUnload');
    },
    _derivedConfirmed15Qtys: function _derivedConfirmed15Qtys() {
      var loadStopCompartments = this.get('trip.loadStop.trip_stop_truck_storages');
      var compartments = this.tripStopTruckStorages();
      return compartments.map(function (compartment) {
        var loadStopCompartment = loadStopCompartments.findBy('TruckStorageID', compartment.get('TruckStorageID'));

        if ((0, _utils.isEmpty)(loadStopCompartment)) {
          Sentry.captureMessage("derivedConfirmed15Qtys: failed looking up loadStopCompartment for compartment ".concat(compartment.get('TruckStorageID')));
          return;
        }

        return compartment.deriveConfirmed15Qty(loadStopCompartment);
      });
    },
    setTemperatureAverage: function setTemperatureAverage() {
      var storages = this.tripStopTruckStorages().filter(function (storage) {
        return Number(storage.get('Temperature')) !== 0;
      });

      if (storages.length === 0) {
        return;
      }

      var totalTemperature = storages.reduce(function (currentValue, storage) {
        var _storage$getPropertie = storage.getProperties(['Temperature', 'confirmed15Qty']),
            temperature = _storage$getPropertie.Temperature,
            volume = _storage$getPropertie.confirmed15Qty;

        return Number(currentValue) + Number(temperature * volume);
      }, null);
      var temperatureAverage = (totalTemperature / this.get('confirmed15Qty')).toFixed(2);

      if (typeof temperatureAverage === 'number' && isFinite(temperatureAverage)) {
        this.set('Temperature', temperatureAverage);
      }
    },
    getLoad: function getLoad() {
      var _this3 = this;

      this.get('store').query('load', {
        StorageID: this.get('StorageID'),
        TicketNb: this.get('trip_stop.ExtOrderID')
      }).then(function (result) {
        var results = result.toArray();

        if (results.length) {
          var _results$reduce = results.reduce(function (loadDetails, loadItem) {
            var compartment = _this3.get('trip_stop.trip_stop_truck_storages').find(function (compartment) {
              return compartment.get('TruckStorageID') === loadItem.get('CompartmentCode');
            });

            var compartmentData = {
              confirmedQty: loadItem.get('ActualQty'),
              confirmed15Qty: loadItem.get('Deg15Qty'),
              Temperature: loadItem.get('Temperature'),
              Density: loadItem.get('Density'),
              StorageID: _this3.get('StorageID'),
              MaterialID: _this3.get('MaterialID'),
              TripStopStorageID: _this3.get('id')
            };
            compartment ? compartment.setProperties(compartmentData) : null;
            return Object.assign({}, loadDetails, {
              actual: loadDetails.actual + loadItem.get('ActualQty'),
              deg15: loadDetails.deg15 + loadItem.get('Deg15Qty'),
              avgTmp: loadDetails.avgTmp + loadItem.get('Temperature') * loadItem.get('ActualQty'),
              avgDensity: loadDetails.avgDensity + loadItem.get('Density') * loadItem.get('ActualQty')
            });
          }, {
            actual: 0,
            deg15: 0,
            avgTmp: 0,
            avgDensity: 0
          }),
              actual = _results$reduce.actual,
              deg15 = _results$reduce.deg15,
              avgTmp = _results$reduce.avgTmp,
              avgDensity = _results$reduce.avgDensity;

          _this3.set('Temperature', Math.round(avgTmp / actual * 100) / 100);

          _this3.set('Density', Math.round(avgDensity / actual * 10000) / 10000);

          _this3.set('confirmedQty', actual);

          _this3.set('confirmed15Qty', deg15);
        }
      });
    },
    resetProcessDeliveryFlag: function resetProcessDeliveryFlag() {
      this.set('processDeliveryFlag', true);
    },
    updateConfirmedQty: function updateConfirmedQty() {
      var setConfirmedQty = this.get('trip_stop.isLoad') && this.get('truckSettings.show_ambient_liters_on_load') || this.get('trip_stop.isUnload') && this.get('truckSettings.show_ambient_liters_on_unload');
      if (setConfirmedQty) this._setConfirmedQty('confirmedQty');
      var setConfirmed15Qty = this.get('trip_stop.isLoad') && this.get('truckSettings.show_standard_liters_on_load') || this.get('trip_stop.isUnload') && this.get('truckSettings.show_standard_liters_on_unload');
      if (setConfirmed15Qty) this._setConfirmedQty('confirmed15Qty');

      if (this.get('truckSettings.show_temperature_on_load')) {
        // TODO Condition still valid??
        this.setTemperatureAverage();
      }
    }
  });

  _exports.default = _default;
});