define("bottomline-execution/serializers/bx/trip-stop-storage", ["exports", "@ember/object/computed", "@ember/object", "@ember/utils", "@ember/service", "bottomline-execution/serializers/trip-stop-storage"], function (_exports, _computed, _object, _utils, _service, _tripStopStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tripStopStorage.default.extend({
    store: (0, _service.inject)(),
    tripStopJson: {},
    isLoadStop: (0, _computed.equal)('tripStopJson.type', 'load'),
    actionFilterKey: (0, _object.computed)('isLoadStop', function () {
      return this.get('isLoadStop') ? 'product_id' : 'site_storage_id';
    }),
    storageFilterKey: (0, _object.computed)('isLoadStop', function () {
      return this.get('isLoadStop') ? 'MaterialID' : 'id';
    }),
    plannedActions: [],
    executedActions: [],
    mapResponse: function mapResponse(tripStopJson, plannedActions, executedActions) {
      var _this = this;

      this.set('tripStopJson', tripStopJson);
      this.set('plannedActions', plannedActions);
      this.set('executedActions', executedActions);
      var storages = this.get('store').peekAll('storage').filterBy('SiteID', tripStopJson.location.id);
      var tripStopStorages = storages.map(function (storage) {
        var planned = _this._filterActions('plannedActions', storage);

        var executed = _this._filterActions('executedActions', storage);

        return {
          id: tripStopJson.id + '_' + storage.id,
          type: 'trip-stop-storage',
          attributes: {
            confirmedQty: _this._calculateTotal(executed, 'volume_in_ambient_liters'),
            confirmed15Qty: _this._calculateTotal(executed, 'volume_in_standard_liters'),
            Density: _this._calculateTotal(executed, 'density_in_kilograms_per_cubic_meter'),
            MaterialID: storage.MaterialID,
            plannedQty: _this._calculateTotal(planned, 'volume_in_ambient_liters'),
            StorageID: storage.id,
            Temperature: _this._calculateTotal(executed, 'temperature_in_celsius') || undefined,
            TripStopID: tripStopJson.id
          },
          relationships: {
            storage: {
              data: {
                type: 'storage',
                id: storage.id
              }
            }
          }
        };
      });
      return {
        data: tripStopStorages
      };
    },
    _filterActions: function _filterActions(actionsType, storage) {
      var targetValue = storage.get(this.get('storageFilterKey'));
      return this.get(actionsType).filterBy(this.get('actionFilterKey'), targetValue);
    },
    _calculateTotal: function _calculateTotal(actions, attribute) {
      if ((0, _utils.isEmpty)(actions)) return null;
      return actions.reduce(function (sum, action) {
        return sum + Number(action[attribute]);
      }, 0);
    }
  });

  _exports.default = _default;
});