define("bottomline-execution/utils/i18n/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(_locale, key, context) {
    if (context.default) {
      return context.default;
    } else {
      return "~".concat(key);
    }
  }
});