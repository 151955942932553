define("bottomline-execution/initializers/framework7-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'f7', 'service:framework7');
    application.inject('controller', 'f7', 'service:framework7');
    application.inject('component', 'f7', 'service:framework7');
    application.inject('view', 'f7', 'service:framework7');
  }

  var _default = {
    name: 'framework7-service',
    initialize: initialize
  };
  _exports.default = _default;
});