define("bottomline-execution/models/pdf-document", ["exports", "ember-data", "@ember/runloop", "@ember/service", "bottomline-execution/utils/guid-util"], function (_exports, _emberData, _runloop, _service, _guidUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    cordovaPlatform: (0, _service.inject)('ember-cordova/platform'),
    ajax: (0, _service.inject)(),
    store: (0, _service.inject)(),
    description: attr('string'),
    documentType: attr('string'),
    modelId: attr('string'),
    open: function open() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        signalOpening: null,
        showWarning: null
      };
      var signalOpening = options.signalOpening,
          showWarning = options.showWarning;

      if (this.get('cordovaPlatform.isCordova')) {
        this._openOnTablet(signalOpening, showWarning);
      } else {
        this._openInBrowser(signalOpening, showWarning);
      }
    },
    _openInBrowser: function _openInBrowser(signalOpening, showWarning) {
      var url = this._getDownloadUrl();

      var headers = this.get('ajax.headers');
      if (signalOpening) signalOpening(true);
      fetch(url, {
        headers: headers
      }).then(function (res) {
        return res.blob();
      }).then(function (blob) {
        if (blob.size === 0) {
          throw new Error('size is 0');
        }

        window.open(window.URL.createObjectURL(blob), '_blank', 'location=no');
      }).catch(function (error) {
        console.warn("Error retrieving document from backend:", error);
        var errorMessage = error.http_status || error.message;
        if (showWarning) showWarning({
          message: "Cannot open file (".concat(errorMessage, ")")
        });
      }).finally(function () {
        if (signalOpening) signalOpening(false);
      });
    },
    _openOnTablet: function _openOnTablet(signalOpening, showWarning) {
      if (signalOpening) signalOpening(true);

      var url = this._getDownloadUrl();

      var fileName = "".concat((0, _guidUtil.guidUtil)(), ".pdf");
      var filePath = cordova.file.externalDataDirectory + fileName;
      var fileTransfer = new FileTransfer();
      fileTransfer.download(encodeURI(url), filePath, function (fileEntry) {
        (0, _runloop.later)(function () {
          if (signalOpening) signalOpening(false);
        }, 2000);
        cordova.plugins.fileOpener2.open(fileEntry.toInternalURL(), 'application/pdf');
      }, function (error) {
        if (signalOpening) signalOpening(false);
        console.warn("Error retrieving document from backend:", error);
        if (showWarning) showWarning({
          message: "Cannot open file (".concat(error.http_status, ")")
        });
      }, null, {
        headers: this.get('ajax.headers')
      });
    },
    _getDownloadUrl: function _getDownloadUrl() {
      var snapshot = this._internalModel.createSnapshot();

      return this.get('store').adapterFor('pdf-document').urlForDownloadRecord(snapshot);
    }
  });

  _exports.default = _default;
});