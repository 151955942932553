define("bottomline-execution/instance-initializers/browser-console", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(applicationInstance) {
    window.app = applicationInstance;
    window.store = applicationInstance.lookup('service:store');
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});