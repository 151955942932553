define("bottomline-execution/serializers/order-remark", ["exports", "ember-data", "active-model-adapter"], function (_exports, _emberData, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    serialize: function serialize(snapshot) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (options.purpose === 'forReport') {
        return this.forReport(snapshot);
      } else {
        return this._super.apply(this, arguments);
      }
    },
    forReport: function forReport(snapshot) {
      var customAttributes = {
        remark: snapshot.attr('Remark'),
        target: snapshot.attr('Target')
      };
      return Object.assign({}, snapshot.attributes(), customAttributes);
    }
  });

  _exports.default = _default;
});