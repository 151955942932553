define("bottomline-execution/templates/components/bl-components/form-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PgK8cvCJ",
    "block": "[[[10,\"button\"],[15,1,[30,0,[\"idName\"]]],[15,0,[29,[\"button button-fill \",[30,0,[\"classes\"]]]]],[15,4,[30,0,[\"typeName\"]]],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,3],null,[[\"key\"],[[30,0,[\"translationKey\"]]]]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"editable-t\"]]",
    "moduleName": "bottomline-execution/templates/components/bl-components/form-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});