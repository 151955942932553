define("bottomline-execution/components/f7-text-field", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'li',
    hasErrors: (0, _object.computed)('hasError', function () {
      if (this.get('hasError')) {
        return 'has-error';
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});