define("bottomline-execution/controllers/truck-settings", ["exports", "@ember/object/computed", "@ember/object", "@ember/controller", "@ember/service"], function (_exports, _computed, _object, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    router: (0, _service.inject)(),
    appState: (0, _computed.alias)('shared.appState'),
    online: (0, _computed.alias)('appState.online'),
    sortedTruckSettings: (0, _object.computed)('appState.truckSettings', function () {
      var truckSettings = this.get('appState.truckSettings');
      var sortedTruckSettingsKeys = Object.keys(truckSettings.serialize()).sort();
      return sortedTruckSettingsKeys.map(function (key) {
        return {
          label: key,
          value: truckSettings[key]
        };
      });
    }),
    actions: {
      return: function _return() {
        this.get('online') && this.router.transitionTo('shifts.index');
      }
    }
  });

  _exports.default = _default;
});