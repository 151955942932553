define("bottomline-execution/serializers/bx/trip-stop", ["exports", "@ember/object", "@ember/service", "@ember/utils", "bottomline-execution/serializers/trip-stop", "bottomline-execution/mixins/adapter-options", "bottomline-execution/mixins/unit-of-calculation"], function (_exports, _object, _service, _utils, _tripStop, _adapterOptions, _unitOfCalculation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tripStop.default.extend(_adapterOptions.default, _unitOfCalculation.default, {
    store: (0, _service.inject)(),
    activityCode: {
      load: 'L',
      unload: 'U',
      park: 'P'
    },
    mappedTripStopStatus: {
      open: 'planned',
      started: 'started',
      finished: 'finished'
    },
    endInventorySerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('end-inventory');
    }),
    inventoryCorrectionSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('inventory-correction');
    }),
    pictureSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('picture');
    }),
    siteSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('site');
    }),
    startInventorySerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('start-inventory');
    }),
    tssSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('trip-stop-storage');
    }),
    tstsSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('trip-stop-truck-storage');
    }),
    mapResponse: function mapResponse(tripId, tripStopsJson, startInventory, endInventory) {
      var _this = this;

      var includedSites = [];
      var includedTripStopStorages = [];
      var includedTripStopTruckStorages = [];
      var includedPictures = [];
      var mappedTripStops = tripStopsJson.map(function (json) {
        var normalizedSite = _this.get('siteSerializer').mapResponse(json.location);

        includedSites.push(normalizedSite);

        var tripStopStorages = _this._mapTripStopStorages(json);

        includedTripStopStorages.push(tripStopStorages);

        var normalizedTripStopTruckStorages = _this._mapTripStopTruckStorage(json, tripStopStorages);

        includedTripStopTruckStorages.push(normalizedTripStopTruckStorages);

        var normalizedPictures = _this._mapPictures(json);

        includedPictures.push(normalizedPictures);
        var mappedSite = Object.assign({
          id: normalizedSite.data.id
        }, normalizedSite.data.attributes);

        var planned = _this._plannedActions(json);

        var executed = _this._executedActions(json);

        var mappedTripStop = {
          id: json.id,
          type: 'trip-stop',
          attributes: {
            ActivityCode: _this.get('activityCode')[json.type],
            ConfirmedArrivalDT: json.executed_start_time,
            ConfirmedDepartureDT: json.executed_end_time,
            confirmedQty: _this._calculateTotal(executed, _this.get('unitOfCalculationVolume')),
            DriverRemarks: json.driver_remarks,
            latitude: mappedSite.latitude,
            ExtOrderID: _this._executedLoad(json).external_load_id,
            longitude: mappedSite.longitude,
            Odometer: json.kilometer_reading,
            PlannedArrivalDT: json.planned_arrival_time,
            plannedQty: _this._calculateTotal(planned, 'volume_in_ambient_liters'),
            SequenceNb: json.sequence_nr,
            sitename: mappedSite.Name,
            state: _this.get('mappedTripStopStatus')[json.status.toLowerCase()],
            TripID: tripId
          },
          relationships: {
            pictures: {
              data: normalizedPictures.data.map(function (_ref) {
                var type = _ref.type,
                    id = _ref.id;
                return {
                  type: type,
                  id: id
                };
              })
            },
            site: {
              data: {
                type: 'site',
                id: mappedSite.id
              }
            },
            'trip_stop_storages': {
              data: tripStopStorages.data.map(function (_ref2) {
                var type = _ref2.type,
                    id = _ref2.id;
                return {
                  type: type,
                  id: id
                };
              })
            },
            'trip_stop_truck_storages': {
              data: normalizedTripStopTruckStorages.data.map(function (_ref3) {
                var type = _ref3.type,
                    id = _ref3.id;
                return {
                  type: type,
                  id: id
                };
              })
            }
          }
        };

        var loadUUID = _this._executedLoad(json).id;

        if ((0, _utils.isPresent)(loadUUID)) {
          // Add only when present, avoid overriding model attr with default UUID generation
          mappedTripStop.attributes.loadUUID = loadUUID;
        }

        return mappedTripStop;
      }); // Add start inventory

      var startInventoryStop = this.get('startInventorySerializer').mapResponse(tripId, startInventory, includedTripStopStorages);
      mappedTripStops.push(startInventoryStop.tripStop);
      includedTripStopStorages.push(startInventoryStop.tripStopStorages);
      includedTripStopTruckStorages.push(startInventoryStop.tripStopTruckStorages); // Add end inventory

      var endInventoryStop = this.get('endInventorySerializer').mapResponse(tripId, endInventory);
      mappedTripStops.push(endInventoryStop.tripStop);
      includedTripStopStorages.push(endInventoryStop.tripStopStorages); // Add inventory correction

      var inventoryCorrection = this.get('inventoryCorrectionSerializer').mapResponse(tripId);
      mappedTripStops.push(inventoryCorrection.tripStop);
      var siteData = includedSites.flatMap(function (s) {
        return s.data;
      });
      var siteSideloadedData = includedSites.flatMap(function (s) {
        return s.included;
      });
      var tripStopStorageData = includedTripStopStorages.flatMap(function (t) {
        return t.data;
      });
      var tripStopTruckStorageData = includedTripStopTruckStorages.flatMap(function (t) {
        return t.data;
      });
      var picturesData = includedPictures.flatMap(function (t) {
        return t.data;
      });
      var included = [].concat(siteData, tripStopStorageData, tripStopTruckStorageData, picturesData, siteSideloadedData).compact();
      return {
        data: mappedTripStops,
        included: included
      };
    },
    _mapTripStopTruckStorage: function _mapTripStopTruckStorage(tripStopJson, tripStopStorages) {
      var tripStopId = tripStopJson.id;
      return this.get('tstsSerializer').mapResponse(tripStopId, tripStopStorages, this._plannedActions(tripStopJson), this._executedActions(tripStopJson));
    },
    _mapTripStopStorages: function _mapTripStopStorages(tripStopJson) {
      return this.get('tssSerializer').mapResponse(tripStopJson, this._plannedActions(tripStopJson), this._executedActions(tripStopJson));
    },
    _mapPictures: function _mapPictures(tripStopJson) {
      var tripStopId = tripStopJson.id;
      var picturesJson = tripStopJson.load_tickets || tripStopJson.delivery_tickets || [];
      return this.get('pictureSerializer').mapResponse(picturesJson, tripStopId);
    },
    _calculateTotal: function _calculateTotal(actions, attribute) {
      if ((0, _utils.isEmpty)(actions)) return null;
      return actions.reduce(function (sum, action) {
        return sum + Number(action[attribute]);
      }, 0);
    },
    _plannedActions: function _plannedActions(tripStopJson) {
      return tripStopJson.planned_actions.filterBy('type', tripStopJson.type);
    },
    _executedActions: function _executedActions(tripStopJson) {
      var executedActions = null;

      if (tripStopJson.type == 'load') {
        executedActions = this._executedLoad(tripStopJson).transactions;
      } else {
        executedActions = tripStopJson.executed_actions.unload_transactions;
      }

      return executedActions || [];
    },
    _executedLoad: function _executedLoad(tripStopJson) {
      return tripStopJson.executed_actions.load || {};
    },
    serialize: function serialize(snapshot) {
      var attrs = snapshot.attributes();

      switch (snapshot.adapterAction()) {
        case 'confirm-load':
          return {
            external_load_id: attrs.ExtOrderID,
            load_id: attrs.loadUUID,
            remarks: attrs.DriverRemarks,
            transactions: this._mapLoadTransactions(snapshot)
          };

        case 'confirm-unload-transactions':
          return {
            remarks: attrs.DriverRemarks,
            transactions: this._mapUnloadTransactions(snapshot)
          };

        case 'finish':
          return {
            kilometer_reading: attrs.Odometer
          };

        default:
          return {};
      }
    },
    _mapLoadTransactions: function _mapLoadTransactions(snapshot) {
      var qualifiedTripStopTruckStorages = this._filterTripStopTruckStorages(snapshot);

      return qualifiedTripStopTruckStorages.map(function (snapshot) {
        var attrs = snapshot.attributes();
        return {
          compartment_number: attrs.TruckStorageID,
          density_in_kilograms_per_cubic_meter: attrs.Density,
          product_id: attrs.MaterialID,
          temperature_in_celsius: attrs.Temperature,
          vehicle_id: attrs.TruckID,
          volume_in_ambient_liters: attrs.confirmedQty,
          volume_in_standard_liters: attrs.confirmed15Qty
        };
      });
    },
    _mapUnloadTransactions: function _mapUnloadTransactions(snapshot) {
      var qualifiedTripStopTruckStorages = this._filterTripStopTruckStorages(snapshot);

      return qualifiedTripStopTruckStorages.map(function (snapshot) {
        var attrs = snapshot.attributes();
        return {
          compartment_number: attrs.TruckStorageID,
          product_id: attrs.MaterialID,
          site_storage_id: attrs.StorageID,
          vehicle_id: attrs.TruckID,
          volume_in_ambient_liters: attrs.confirmedQty,
          volume_in_standard_liters: attrs.confirmed15Qty
        };
      });
    },
    _filterTripStopTruckStorages: function _filterTripStopTruckStorages(snapshot) {
      return snapshot.hasMany('trip_stop_truck_storages').filter(function (tsts) {
        return tsts.record.get('validations.isValid');
      });
    }
  });

  _exports.default = _default;
});