define("bottomline-execution/templates/components/input-number-spinner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rJY8ethP",
    "block": "[[[10,0],[14,0,\"input-number-spinner\"],[15,\"disabled\",[30,0,[\"disabled\"]]],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"actionOnClick\",\"iconSize\",\"iconName\",\"styleGroups\"],[[28,[37,1],[[30,0],\"decreaseValue\"],null],\"sd\",\"remove\",\"spinner-button\"]]]],[1,\"\\n  \"],[8,[39,2],[[24,0,\"input-number-spinner__input\"],[16,\"placeholder\",[30,0,[\"placeholder\"]]],[16,\"min\",[30,0,[\"min\"]]],[16,\"max\",[30,0,[\"max\"]]],[24,\"inputmode\",\"decimal\"],[4,[38,3],[\"keyup\",[28,[37,1],[[30,0],\"changeValue\"],null]],null],[4,[38,3],[\"focusout\",[28,[37,1],[[30,0],\"ensureNumericality\"],null]],null]],[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"actionOnClick\",\"iconSize\",\"iconName\",\"styleGroups\"],[[28,[37,1],[[30,0],\"increaseValue\"],null],\"sd\",\"add\",\"spinner-button\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"bl-components/icon-button\",\"action\",\"input\",\"on\"]]",
    "moduleName": "bottomline-execution/templates/components/input-number-spinner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});