define("bottomline-execution/templates/components/terminal-summary", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VHHJPM8h",
    "block": "[[[10,0],[14,0,\"card-header terminal\"],[12],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,0],[12],[1,[30,0,[\"terminal\",\"Name\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"terminal small\"],[12],[1,\"(\"],[1,[30,0,[\"terminal\",\"id\"]]],[1,\")\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"card-content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-content-inner\"],[12],[1,\"\\n    \"],[10,\"table\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n          \"],[1,[28,[35,0],null,[[\"name\",\"size\"],[\"domain\",\"lg\"]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n          \"],[1,[30,0,[\"terminal\",\"Address\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n          \"],[1,[30,0,[\"terminal\",\"ZipCode\"]]],[1,\" \"],[1,[30,0,[\"terminal\",\"City\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"material-design-icon\"]]",
    "moduleName": "bottomline-execution/templates/components/terminal-summary.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});