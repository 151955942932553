define("bottomline-execution/components/add-language", ["exports", "@ember/component", "@ember/utils", "@ember/service", "jquery", "bottomline-execution/config/environment"], function (_exports, _component, _utils, _service, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    i18n: (0, _service.inject)(),
    value: '',
    actions: {
      add: function add() {
        var _this = this;

        if (this.get('languages').indexOf(this.get('value')) >= 0 || (0, _utils.isEmpty)(this.get('value'))) {
          return;
        }

        return _jquery.default.post(_environment.default.i18n.endpoint, {
          namespace: 'deliveryplus',
          locale: this.get('value'),
          options: {
            description_language: this.get('description')
          }
        }, 'json').then(function () {
          _this.get('languages').pushObject({
            id: _this.get('value'),
            title: _this.get('description') || _this.get('value')
          });

          _this.get('i18n').addTranslations(_this.get('value'), {});

          _this.set('value', '');

          _this.set('description', '');
        });
      }
    }
  });

  _exports.default = _default;
});