define("bottomline-execution/components/questionable-item", ["exports", "@ember/object/computed", "@ember/component", "@ember/utils", "@ember/object"], function (_exports, _computed, _component, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    questionListId: null,
    state: null,
    afterAction: '',
    item: null,
    disabled: false,
    showQuestions: (0, _computed.alias)('shared.appState.truckSettings.questionaire'),
    hasQuestionnaire: (0, _object.computed)('state', 'item.{question_list_before,question_list_after}', function () {
      var showQuestions = this.get('showQuestions');
      var question_list_after = this.get('item.question_list_after');
      var question_list_before = this.get('item.question_list_before');
      var action = this.get('action'); // Can be start or finish

      if (showQuestions) {
        if (action === 'start' && (0, _utils.isPresent)(question_list_before)) {
          this.set('questionListId', question_list_before);
          return true;
        }

        if (action === 'finish' && (0, _utils.isPresent)(question_list_after)) {
          this.set('questionListId', question_list_after);
          return true;
        }
      }

      return false;
    }),
    actions: {
      openQuestionnaire: function openQuestionnaire() {
        this.set('questionnaireModal', true);
      },
      cancelQuestionnaire: function cancelQuestionnaire() {
        this.set('questionnaireModal', false);
      }
    }
  });

  _exports.default = _default;
});