define("bottomline-execution/components/bl-components/button", ["exports", "bottomline-execution/components/bl-components/button-base", "bottomline-execution/utils/ensure-promise", "@ember/object"], function (_exports, _buttonBase, _ensurePromise, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _buttonBase.default.extend({
    _actionOnClick: function _actionOnClick() {
      return console.warn("Button action is not set");
    },
    actionOnClick: (0, _object.computed)({
      get: function get() {
        return this._actionOnClick;
      },
      set: function set(_propertyName, newValue) {
        this.set('_actionOnClick', newValue);
        return newValue;
      }
    }),
    actions: {
      click: function click() {
        var _this = this;

        if (this.get('isActionOnClickInProgress')) return;
        this.set('isActionOnClickInProgress', true);
        var action = this.get('actionOnClick');
        var actionResult = action();
        (0, _ensurePromise.ensurePromise)(actionResult).finally(function () {
          _this.set('isActionOnClickInProgress', false);
        });
        return false;
      }
    }
  });

  _exports.default = _default;
});