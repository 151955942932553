define("bottomline-execution/templates/components/odometer-field", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Cs4qoYt+",
    "block": "[[[10,0],[14,0,\"item-content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"item-inner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item-title label\"],[12],[1,\"\\n      \"],[1,[28,[35,0],null,[[\"key\"],[[30,0,[\"label\"]]]]]],[1,\"\\n\"],[41,[30,0,[\"isAtx\"]],[[[1,\"        \"],[6,[39,2],null,[[\"additionalCssClasses\",\"actionOnClick\"],[\"get-mileage\",[28,[37,3],[[30,0],\"getMileage\"],null]]],[[\"default\"],[[[[1,[28,[35,0],null,[[\"key\"],[\"odometer-field.getMileage\"]]]]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"item-input\"],[12],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"numericValue\",\"hasError\"],[[30,0,[\"odometerValue\"]],[30,0,[\"hasError\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"editable-t\",\"if\",\"bl-components/button\",\"action\",\"input-number\"]]",
    "moduleName": "bottomline-execution/templates/components/odometer-field.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});