define("bottomline-execution/services/android-intents-atx", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    START: 1,
    STOP: 2,
    stop: function stop(activityCode) {
      var description = "".concat(this._toDescription(activityCode), " gestopt");
      var kind = this.get('STOP');

      this._fireIntent(description, kind);
    },
    start: function start(activityCode) {
      var description = "".concat(this._toDescription(activityCode), " gestart");
      var kind = this.get('START');

      this._fireIntent(description, kind);
    },
    _fireIntent: function _fireIntent(description, kind) {
      window.plugins.webintent.startActivity({
        address: 'com.groeneveldictsolutions.android.atxapi',
        action: 'com.groeneveldictsolutions.android.atxapi.ACTION_REGISTER_ACTIVITY',
        extras: {
          EXTRA_SOURCE: 'BottomLineExecution',
          EXTRA_DESCRIPTION: description,
          EXTRA_TIMESTAMP: Date.now(),
          EXTRA_TYPE: kind
        }
      }, function (e) {
        console.log("Intent succeeded: ".concat(e, ", ").concat(kind, " ").concat(description));
      }, function (e) {
        console.log("Intent failed: ".concat(e, ", ").concat(kind, " ").concat(description));
      });
    },
    _toDescription: function _toDescription(activityCode) {
      var description;

      switch (activityCode) {
        case 'L':
          description = 'loading';
          break;

        case 'U':
          description = 'unloading';
          break;

        case 'E':
          description = 'left on vehicle';
          break;

        default:
          description = "undefined ActivityCode '".concat(activityCode, "'");
      }

      return description;
    }
  });

  _exports.default = _default;
});