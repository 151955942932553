define("bottomline-execution/helpers/round", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.round = round;

  function round(number) {
    return Math.round(number);
  }

  var _default = (0, _helper.helper)(round);

  _exports.default = _default;
});