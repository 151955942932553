define("bottomline-execution/utils/objects-deep-merge-util", ["exports", "@sentry/browser"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = deepMerge;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function isObject(item) {
    return item && _typeof(item) === 'object' && !Array.isArray(item);
  }

  function deepMerge(target, source) {
    var output = Object.assign({}, target);

    if (isObject(target) && isObject(source)) {
      Object.keys(source).forEach(function (key) {
        if (isObject(source[key])) {
          if (!(key in target)) Object.assign(output, _defineProperty({}, key, source[key]));else output[key] = deepMerge(target[key], source[key]);
        } else {
          Object.assign(output, _defineProperty({}, key, source[key]));
        }
      });
    } else {
      Sentry.captureMessage({
        function: 'deepMerge',
        arguments: arguments
      });
    }

    return output;
  }
});