define("bottomline-execution/components/trip-stops/show/unload/predip-ullage", ["exports", "@ember/component", "@ember/object", "@ember/utils", "bottomline-execution/utils/is-zero-or-greater"], function (_exports, _component, _object, _utils, _isZeroOrGreater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    preDipUllage: (0, _object.computed)('tank.preDipUllage', {
      get: function get() {
        return this.get('tank.preDipUllage');
      },
      set: function set(_key, value) {
        var startInventoryQty = null;
        var usableStorageQty = this.get('tank.storage.usableStorageQty');

        if ((0, _utils.isPresent)(usableStorageQty) && (0, _isZeroOrGreater.isZeroOrGreater)(value)) {
          startInventoryQty = usableStorageQty - value;
        }

        this.set('tank.startInventoryQty', startInventoryQty);
        return value;
      }
    })
  });

  _exports.default = _default;
});