define("bottomline-execution/templates/components/new-stop-map-osm", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iJgvPOUT",
    "block": "[[[10,0],[14,0,\"new-stop-map-osm\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"new-stop-map-osm__map-container\"],[14,1,\"leafletmap\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "bottomline-execution/templates/components/new-stop-map-osm.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});