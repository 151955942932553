define("bottomline-execution/serializers/bx/trip", ["exports", "bottomline-execution/serializers/trip", "@ember/object", "@ember/service", "@ember/utils"], function (_exports, _trip, _object, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _trip.default.extend({
    store: (0, _service.inject)(),
    mappedTripStatus: {
      published: 'planned',
      started: 'started',
      finished: 'finished'
    },
    tripStopSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('trip-stop');
    }),
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, _id, _requestType) {
      if ((0, _utils.isEmpty)(payload)) return {
        data: []
      };
      return this._super.apply(this, arguments);
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, _id, _requestType) {
      var _this$_mapTrip = this._mapTrip(payload),
          normalizedTrip = _this$_mapTrip.normalizedTrip,
          normalizedTripStops = _this$_mapTrip.normalizedTripStops;

      return this._buildNormalizedResponse(normalizedTrip, normalizedTripStops);
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, _id, _requestType) {
      var _this = this;

      var includedTripStops = [];
      var normalizedTrips = payload.map(function (json) {
        var _this$_mapTrip2 = _this._mapTrip(json),
            normalizedTrip = _this$_mapTrip2.normalizedTrip,
            normalizedTripStops = _this$_mapTrip2.normalizedTripStops;

        includedTripStops.push(normalizedTripStops);
        return normalizedTrip;
      });
      return this._buildNormalizedResponse(normalizedTrips, includedTripStops);
    },
    _buildNormalizedResponse: function _buildNormalizedResponse(normalizedTrips, includedTripStops) {
      includedTripStops = [].concat(includedTripStops);
      var tripStopData = includedTripStops.flatMap(function (t) {
        return t.data;
      });
      var tripStopSideLoadedData = includedTripStops.flatMap(function (t) {
        return t.included;
      });
      var included = [].concat(tripStopData, tripStopSideLoadedData);
      return {
        data: normalizedTrips,
        included: included
      };
    },
    _mapTrip: function _mapTrip(json) {
      var startInventory = {
        inventories: json.start_inventory,
        timestamp: json.executed_start_time
      };
      var endInventory = Object.assign(json.end_inventory, {
        kilometer_reading: json.end_kilometer_reading,
        driver_remarks: json.end_driver_remarks
      });
      var normalizedTripStops = this.get('tripStopSerializer').mapResponse(json.id, json.stops, startInventory, endInventory);
      var normalizedTrip = {
        id: json.id,
        type: 'trip',
        attributes: {
          ConfirmedEndDT: json.executed_end_time,
          ConfirmedStartDT: json.executed_start_time,
          OdometerStart: json.odometer_start,
          PlannedStartDT: json.planned_start_time,
          SequenceNb: json.sequence_nr,
          state: this.get('mappedTripStatus')[json.status.toLowerCase()]
        },
        relationships: {
          'trip_stops': {
            data: normalizedTripStops.data.map(function (_ref) {
              var type = _ref.type,
                  id = _ref.id;
              return {
                type: type,
                id: id
              };
            })
          }
        }
      };
      return {
        normalizedTrip: normalizedTrip,
        normalizedTripStops: normalizedTripStops
      };
    },
    normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, primaryModelClass, payload, id, _requestType) {
      return {
        data: {
          id: id
        }
      };
    },
    serialize: function serialize(snapshot) {
      return {
        odometer: snapshot.attr('OdometerStart') || 0
      };
    }
  });

  _exports.default = _default;
});