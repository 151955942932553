define("bottomline-execution/mixins/ask-odometer", ["exports", "@ember/object/mixin", "rsvp", "@ember/service"], function (_exports, _mixin, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    f7: (0, _service.inject)('framework7'),
    _askForOdometer: function _askForOdometer(model, prompt, fieldName) {
      var _this = this;

      var askForOdometer = this.get('askForOdometer');
      if (!askForOdometer) return _rsvp.Promise.resolve();
      return new _rsvp.Promise(function (resolve, reject) {
        var currentValue = model.get(fieldName);

        _this.get('f7').extdPrompt(prompt, _this.get('i18n').t('form_odometer'), 'number', currentValue, function (userInput) {
          if (userInput !== '') {
            model.set(fieldName, userInput);
            resolve();
          }
        }, function () {
          reject();
        });
      });
    }
  });

  _exports.default = _default;
});