define("bottomline-execution/services/internet-connection", ["exports", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/utils"], function (_exports, _service, _object, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    online: true,
    offline: (0, _computed.not)('online'),
    currentState: (0, _object.computed)('online', function () {
      return this.get('online') ? 'online' : 'offline';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('online', navigator.onLine);

      if (window.cordova) {
        this._installTabletEventListeners();
      } else {
        this._installBrowserEventListener();
      }
    },
    _installTabletEventListeners: function _installTabletEventListeners() {
      var _this = this;

      document.addEventListener("online", function () {
        _this.set('online', true);
      }, false);
      document.addEventListener("offline", function () {
        _this.set('online', false);
      }, false);
    },
    _installBrowserEventListener: function _installBrowserEventListener() {
      var _this2 = this;

      if ((0, _utils.isPresent)(navigator.connection)) {
        navigator.connection.addEventListener('change', function () {
          _this2.set('online', navigator.onLine);
        });
      }
    }
  });

  _exports.default = _default;
});