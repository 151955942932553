define("bottomline-execution/services/ajax", ["exports", "@ember/object/computed", "@ember/object", "@ember/service", "ember-ajax/services/ajax"], function (_exports, _computed, _object, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    appState: (0, _computed.alias)('shared.appState'),
    requestHeaders: (0, _service.inject)(),
    session: (0, _service.inject)(),
    host: (0, _object.computed)('appState.domain', function () {
      return "".concat(this.get('appState.domain'), "/").concat(this.get('appState.apiPath'));
    }),
    headers: (0, _object.computed)('session.isAuthenticated', 'session.reAuthenticated', 'session.reAuthenticating', function () {
      var authorize = this.get('session.isAuthenticated') && !this.get('session.reAuthenticating');
      return this.get('requestHeaders').defaultHeaders({
        authorize: authorize
      });
    }),
    contentType: 'application/json; charset=utf-8'
  });

  _exports.default = _default;
});