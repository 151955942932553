define("bottomline-execution/services/form-multipart", ["exports", "@ember/object", "@ember/service", "bottomline-execution/services/ajax"], function (_exports, _object, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    requestHeaders: (0, _service.inject)(),
    // INFO: When such header set here explicitly it does not send data as mulipart form data.
    // headers['content-type'] = 'multipart/form-data';
    // These params { processData: false, contentType: false } in the request method call fixes this problem.
    // Since ember-ajax is a wrapper over JQuery.ajax this worked well:
    // https://stackoverflow.com/a/39716628/237646
    headers: (0, _object.computed)('session.isAuthenticated', function () {
      var authorize = this.get('session.isAuthenticated');
      var formMultipartHeaders = this.get('requestHeaders').defaultHeaders({
        authorize: authorize
      });
      delete formMultipartHeaders['Content-Type'];
      return formMultipartHeaders;
    })
  });

  _exports.default = _default;
});