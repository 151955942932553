define("bottomline-execution/routes/pictures/index", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    stotr: (0, _service.inject)(),
    model: function model(em, data) {
      return this.get('store').find('picture', {
        TableKey: data.params['trip-stops.show']['trip_stop_id'],
        TableName: 'TripStop'
      });
    }
  });

  _exports.default = _default;
});