define("bottomline-execution/templates/components/f7-side-panel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rdUort6m",
    "block": "[[[10,0],[14,0,\"panel-overlay\"],[12],[13],[1,\"\\n\"],[10,0],[15,0,[29,[\"panel panel-\",[30,0,[\"side\"]],\" panel-reveal\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"content-block\"],[12],[1,\"\\n    \"],[10,2],[12],[46,[30,0,[\"componentName\"]],null,[[\"data\"],[[30,0,[\"data\"]]]],null],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"actionOnClick\",\"translationKey\"],[[28,[37,2],[[30,0],\"close\",[30,0,[\"side\"]]],null],\"close\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"component\",\"bl-components/button\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/components/f7-side-panel.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});