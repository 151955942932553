define("bottomline-execution/models/trip-stop-authorization", ["exports", "ember-data", "bottomline-execution/mixins/offline-errors"], function (_exports, _emberData, _offlineErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_offlineErrors.default, {
    phase: attr('string'),
    // preAuthorization or postAuthorization
    tripStopId: attr('string'),
    tripStopStorages: hasMany('trip-stop-storage', {
      async: false
    }),
    save: function save() {
      var _this = this;

      return this._super.apply(this, arguments).catch(function (error) {
        if (_this.isOfflineError(error)) {
          console.log("%cTrip stop ".concat(_this.get('phase'), " cached for later synchronization (").concat(error, ")"), 'color:red');
        } else {
          throw error;
        }
      });
    }
  });

  _exports.default = _default;
});