define("bottomline-execution/serializers/bx/truck", ["exports", "@ember/object", "@ember/service", "bottomline-execution/serializers/truck"], function (_exports, _object, _service, _truck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _truck.default.extend({
    store: (0, _service.inject)(),
    vehicleType: {
      tractor: 'truck',
      trailer: 'trailer',
      rigid: 'truck'
    },
    truckStorageSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('truck-storage');
    }),
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, _id, _requestType) {
      var _this = this;

      var mappedCompoundVehicles = payload.compoundVehicles.map(function (json) {
        return {
          id: json.id,
          type: 'compound-vehicle',
          attributes: {
            TruckID: json.drivable_vehicle_id,
            TrailerID: json.trailers[0] // BP-7011: The truck may have multiple trailers, this is currently not supported by BE

          }
        };
      });
      var includedTruckStorages = [];
      var mappedVehicles = payload.vehicles.map(function (json) {
        var vehicleType = _this.get('vehicleType')[json.type];

        if (json.type != 'tractor') {
          var normalizedTruckStorages = _this.get('truckStorageSerializer').mapResponse(vehicleType, json.compartments);

          includedTruckStorages.push(normalizedTruckStorages);
        }

        return {
          id: json.id,
          type: vehicleType,
          attributes: {
            HaulierID: json.carrier_id,
            License: json.license_plate,
            TruckID: json.id
          }
        };
      });
      var truckStorageData = includedTruckStorages.flatMap(function (t) {
        return t.data;
      });
      var included = [].concat(mappedCompoundVehicles, truckStorageData);
      return {
        data: mappedVehicles,
        included: included
      };
    }
  });

  _exports.default = _default;
});