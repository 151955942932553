define("bottomline-execution/mixins/truck-settings-id", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    truckSettingsId: function truckSettingsId(truckId) {
      return "".concat(truckId, "settings");
    }
  });

  _exports.default = _default;
});