define("bottomline-execution/services/android-permissions", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    EXTERNAL_STORAGE: "android.permission.WRITE_EXTERNAL_STORAGE",
    READ_EXTERNAL_STORAGE: "android.permission.READ_EXTERNAL_STORAGE",
    confirmPermission: function confirmPermission(permission) {
      var _this = this;

      var onDeviceReady = function onDeviceReady() {
        if (cordova.plugins) {
          var permissions = cordova.plugins.permissions;
          permissions.hasPermission(_this.get(permission), _this._checkPermissionCallback.bind(_this, _this.get(permission)), null);
        } else {
          alert('Could not check permission, plugin is not loaded.');
        }
      };

      document.addEventListener('deviceready', onDeviceReady, false);
    },
    _checkPermissionCallback: function _checkPermissionCallback(permission, status) {
      if (cordova.plugins) {
        var permissions = cordova.plugins.permissions;

        if (!status.hasPermission) {
          var errorCallback = function errorCallback() {
            console.warn(permission + ' is not turned on');
          };

          permissions.requestPermission(permission, function (status) {
            if (!status.hasPermission) {
              errorCallback();
            }
          }, errorCallback);
        }
      }
    }
  });

  _exports.default = _default;
});