define("bottomline-execution/utils/guid-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.guidRegex = void 0;
  _exports.guidUtil = guidUtil;
  var identifier = 'ember-';

  function guidUtil() {
    return (identifier + 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx').replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : r & 0x3 | 0x8;
      return v.toString(16);
    });
  }

  var guidRegex = new RegExp("^".concat(identifier));
  _exports.guidRegex = guidRegex;
});