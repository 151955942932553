define("bottomline-execution/components/edit-language", ["exports", "@ember/component", "@ember/object", "@ember/service", "jquery", "bottomline-execution/config/environment"], function (_exports, _component, _object, _service, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    i18n: (0, _service.inject)(),
    f7: (0, _service.inject)('framework7'),
    isEditing: false,
    tagName: 'tr',
    value: (0, _object.computed)('language', function () {
      return this.get('language.title');
    }),
    actions: {
      editMode: function editMode() {
        this.toggleProperty('isEditing');
      },
      saveLocale: function saveLocale() {
        var _this = this;

        return _jquery.default.ajax({
          url: _environment.default.i18n.endpoint + "/".concat(this.get('language').id),
          type: 'PUT',
          dataType: 'json',
          data: {
            namespace: 'deliveryplus',
            key: 'description_language',
            value: this.get('value')
          }
        }).then(function () {
          _this.set('language.title', _this.get('value'));

          _this.toggleProperty('isEditing');
        });
      },
      deleteLocale: function deleteLocale() {
        var _this2 = this;

        this.get('f7').confirm('Are you sure?', 'Delete language: ' + this.get('language').id, function () {
          return _jquery.default.ajax({
            url: _environment.default.i18n.endpoint + "/".concat(_this2.get('language').id),
            type: 'DELETE',
            dataType: 'json',
            data: {
              namespace: 'deliveryplus'
            }
          }).then(function () {
            return _this2.get('languages').removeObject(_this2.get('language'));
          });
        });
      }
    }
  });

  _exports.default = _default;
});