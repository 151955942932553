define("bottomline-execution/serializers/bx/picture", ["exports", "active-model-adapter", "@ember/object", "@ember/service"], function (_exports, _activeModelAdapter, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    store: (0, _service.inject)(),
    pictureAdapter: (0, _object.computed)(function () {
      return this.get('store').adapterFor('picture');
    }),
    mapResponse: function mapResponse(picturesJson, tripStopId) {
      var _this = this;

      var mappedPictures = picturesJson.map(function (json) {
        return {
          id: json.file_reference,
          type: 'picture',
          attributes: {
            TableKey: tripStopId,
            TableName: 'TripStop',
            url: _this.get('pictureAdapter').urlForDownloadRecord(json.file_reference)
          }
        };
      });
      return {
        data: mappedPictures
      };
    },
    normalizeQueryResponse: function normalizeQueryResponse(_store, _primaryModelClass, payload, _id, _requestType) {
      var pictureUpdates = payload.compact().map(function (entry) {
        return {
          id: entry.pictureId,
          type: 'picture',
          attributes: {
            imageData: entry.imageData.split('base64,')[1]
          }
        };
      });
      return {
        data: pictureUpdates
      };
    }
  });

  _exports.default = _default;
});