define("bottomline-execution/models/order-remark", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    OrderID: attr('string'),
    Remark: attr('string'),
    Target: attr('string')
  });

  _exports.default = _default;
});