define("bottomline-execution/adapters/no-op", ["exports", "ember-data", "rsvp"], function (_exports, _emberData, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.RESTAdapter.extend({
    findAll: function findAll() {
      var response = {
        "no-op": null
      };
      return _rsvp.Promise.resolve(response);
    },
    query: function query(store, type) {
      var response = _defineProperty({}, type.modelName, []);

      return _rsvp.Promise.resolve(response);
    },
    createRecord: function createRecord(_store, _type, _snapshot) {
      // No-op to trigger flushing of changed attributes and marking record as clean
      return _rsvp.Promise.resolve();
    },
    updateRecord: function updateRecord(_store, _type, _snapshot) {
      // No-op to trigger flushing of changed attributes and marking record as clean
      return _rsvp.Promise.resolve();
    }
  });

  _exports.default = _default;
});