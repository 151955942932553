define("bottomline-execution/components/f7-page-content/f7-infinite-scroll", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Mixin.create({
    setupInfiniteScroll: function () {
      var _this = this,
          action = this.get('onInfiniteScroll');

      if (action) {
        this.$().addClass('infinite-scroll');
        this.set('hasInfiniteScroll', true);
        this.get('f7').attachInfiniteScroll(this.$());
        this.$().on('infinite', function () {
          if (_this.get('loading')) return;

          _this.$().find('.infinite-scroll-preloader').show();

          _this.set('loading', true);

          var deferred = _ember.default.RSVP.defer();

          deferred.promise.finally(function () {
            _this.set('loading', false);

            _this.$().find('.infinite-scroll-preloader').hide();
          });

          _this.sendAction('onInfiniteScroll', deferred, _this);
        });
      }
    }.on('didInsertElement'),
    detachInfiniteScroll: function detachInfiniteScroll() {
      this.get('f7').detachInfiniteScroll(this.$());
      this.$().find('.infinite-scroll-preloader').hide();
    }
  });

  _exports.default = _default;
});