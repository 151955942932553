define("bottomline-execution/templates/components/questionable-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "77GvU0Uw",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,3],[14,6,\"javascript:void(0)\"],[15,0,[29,[[36,2],\" \",[52,[33,3],[33,4],\"disabled\"]]]],[15,\"onclick\",[28,[37,5],[[30,0],\"openQuestionnaire\"],null]],[12],[1,[28,[35,6],null,[[\"key\"],[[33,5]]]]],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[33,8],[[[6,[39,9],null,[[\"to\"],[\"wormhole\"]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,10],null,[[\"item\",\"questionListId\",\"afterAction\",\"cancelAction\",\"state\"],[[33,11],[33,12],[33,13],[28,[37,5],[[30,0],\"cancelQuestionnaire\"],null],[33,14]]]]],[1,\"\\n\"]],[]]]]]],[]],null]],[\"&default\"],false,[\"if\",\"hasQuestionnaire\",\"itemClass\",\"enabled\",\"color\",\"action\",\"editable-t\",\"yield\",\"questionnaireModal\",\"ember-wormhole\",\"questionnaire-for\",\"item\",\"questionListId\",\"afterAction\",\"state\"]]",
    "moduleName": "bottomline-execution/templates/components/questionable-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});