define("bottomline-execution/models/sening-queue", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    requestTime: attr('date'),
    reportTime: attr('date'),
    requestMessage: attr('string'),
    reportMessage: attr('string'),
    consumed: attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});