define("bottomline-execution/utils/simple-template-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.simpleTemplateUtil = simpleTemplateUtil;

  function simpleTemplateUtil(template, variables) {
    return template.replace(/%{(.*?)}/g, function (match, key) {
      return variables[key];
    });
  }
});