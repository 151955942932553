define("bottomline-execution/routes/trip-stops/new/from-site", ["exports", "@ember/routing/route", "bottomline-execution/mixins/trip-stop-material-on-truck"], function (_exports, _route, _tripStopMaterialOnTruck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_tripStopMaterialOnTruck.default, {
    setupController: function setupController(controller, model) {
      controller.set('materialsOnTruck', this.tripStopMaterialOnTruck());
      model = [];
      model.isLoaded = true;
      controller.set('model', model);
    }
  });

  _exports.default = _default;
});