define("bottomline-execution/errors/meter-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = MeterError;

  function MeterError(message) {
    var i18nKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
    this.name = 'MeterError', this.message = message, this.i18nKey = 'meter_coupling.' + i18nKey;
  }
});