define("bottomline-execution/routes/trip-stops/show", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    store: (0, _service.inject)(),
    model: function model(_ref) {
      var trip_stop_id = _ref.trip_stop_id;
      return this.get('store').peekRecord('trip-stop', trip_stop_id);
    }
  });

  _exports.default = _default;
});