define("bottomline-execution/services/bx/offline-syncer", ["exports", "bottomline-execution/services/offline-syncer"], function (_exports, _offlineSyncer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _offlineSyncer.default.extend({
    _synchronize: function _synchronize() {
      this._synchronizeExecutionData();
    }
  });

  _exports.default = _default;
});