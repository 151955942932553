define("bottomline-execution/templates/components/location-confirmer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JjAL7GIc",
    "block": "[[[6,[39,0],null,[[\"to\"],[\"location-confirmer\"]],[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"location-confirmer-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"location-confirmer\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"location-confirmer__map-container\"],[14,1,\"leafletmap\"],[12],[13],[1,\"\\n      \"],[10,2],[14,0,\"buttons-row\"],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"actionOnClick\",\"translationKey\"],[[28,[37,2],[[30,0],\"cancel\"],null],\"cancel\"]]]],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"actionOnClick\",\"translationKey\"],[[28,[37,2],[[30,0],\"confirm\"],null],\"confirm\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"ember-wormhole\",\"bl-components/button\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/components/location-confirmer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});