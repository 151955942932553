define("bottomline-execution/components/input-number-base", ["exports", "@ember/component", "@ember/utils"], function (_exports, _component, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'div',
    value: null,
    min: null,
    max: null,
    allowEmpty: true,
    changeInput: null,
    _previousValue: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_previousValue', this.get('value'));
    },
    _value: function _value() {
      return this.get('value') || 0;
    },
    _isValid: function _isValid(value) {
      if ((0, _utils.isEmpty)(value) && this.get('allowEmpty')) return true;
      var parsedValue = parseFloat(value);
      if (!this._isNumber(parsedValue)) return false;
      var min = parseFloat(this.get('min'));
      var max = parseFloat(this.get('max'));
      if (this._isNumber(min) && parsedValue < min) return false;
      if (this._isNumber(max) && parsedValue > max) return false;
      return true;
    },
    _isNumber: function _isNumber(value) {
      return !isNaN(+value) && isFinite(value);
    },
    _changeValue: function _changeValue(change) {
      var htmlInputValue = this._value();

      var newValue = (0, _utils.isEmpty)(change) ? htmlInputValue : parseFloat(htmlInputValue) + change;

      if (this._isValid(newValue)) {
        this.set('_previousValue', newValue);
        this.set('value', newValue);
      } else {
        this.set('value', this.get('_previousValue'));
      }

      if (this.changeInput) {
        this.changeInput();
      }
    },
    click: function click() {
      return false;
    },
    actions: {
      changeValue: function changeValue() {
        this._changeValue();
      },
      ensureNumericality: function ensureNumericality() {
        var currentValue = parseFloat(this.get('value'));

        if (Number.isNaN(currentValue)) {
          this.set('value', null);
        } else {
          this.set('value', currentValue);
        }

        if (this.changeInput) {
          this.changeInput();
        }
      }
    }
  });

  _exports.default = _default;
});