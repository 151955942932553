define("bottomline-execution/components/input-number", ["exports", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    max: null,
    min: null,
    disabled: false,
    placeholder: null,
    hasError: (0, _object.computed)('numericValue', function () {
      if (this.get('alertNonNumeric')) {
        return this.get('numericValue') === undefined;
      } else {
        return false;
      }
    }),
    alertNonNumeric: false,
    numericValue: (0, _object.computed)('value', {
      get: function get() {
        return this._floatOrUndefined(this.get('value'));
      },
      set: function set(_key, value) {
        var convertedValue = this._floatOrUndefined(value);

        this.set('value', convertedValue);
        return convertedValue;
      }
    }),
    cssClassNames: (0, _object.computed)('hasError', 'isReadonly', function () {
      var _this = this;

      var classNameMapping = {
        hasError: 'has-error',
        isReadonly: 'is-readonly'
      };
      return Object.keys(classNameMapping).reduce(function (classNames, propertyName) {
        return _this.get(propertyName) ? classNames.concat(classNameMapping[propertyName]) : classNames;
      }, []).join(' ');
    }),
    click: function click() {
      return false;
    },
    _floatOrUndefined: function _floatOrUndefined(value) {
      var result = parseFloat(value);

      if ((0, _utils.isEmpty)(result) || isNaN(result)) {
        return undefined;
      }

      return result;
    }
  });

  _exports.default = _default;
});