define("bottomline-execution/components/location-confirmer", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/utils", "@ember/runloop"], function (_exports, _component, _service, _object, _utils, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _component.default.extend({
    locationService: (0, _service.inject)('location'),
    assetMap: (0, _service.inject)('asset-map'),
    i18n: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    leafletMapContainer: null,
    currentLocationMarker: null,
    savedLocationMarker: null,
    newLatitude: null,
    newLongitude: null,
    updateLocation: '',
    cancel: '',
    leafletMap: function leafletMap(lat, lng) {
      var leafletMap = null;

      if (!(0, _utils.isPresent)(this.get('leafletMapContainer'))) {
        leafletMap = new L.Map('leafletmap', {
          scrollWheelZoom: this.get('mapScrollable'),
          center: new L.LatLng(lat, lng),
          zoom: 30,
          layers: new L.TileLayer('https://b.tile.openstreetmap.org/{z}/{x}/{y}.png')
        });
        leafletMap.addControl(new L.Control.Fullscreen());
        this.set('leafletMapContainer', leafletMap);
      } else {
        leafletMap = this.get('leafletMapContainer');
      }

      return leafletMap;
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var _this$getLatLng = this.getLatLng(),
          _this$getLatLng2 = _slicedToArray(_this$getLatLng, 2),
          lat = _this$getLatLng2[0],
          lng = _this$getLatLng2[1];

      if (!(lat && lng)) {
        // Fallback to a previously saved location coordinates.
        var _this$getSavedLatLng = this.getSavedLatLng();

        var _this$getSavedLatLng2 = _slicedToArray(_this$getSavedLatLng, 2);

        lat = _this$getSavedLatLng2[0];
        lng = _this$getSavedLatLng2[1];
      }

      if (!(lat && lng)) {
        // Fallback to one of geographical midpoints of Europe.
        lat = 50.117286;
        lng = 9.247769;
      }

      this.initMarkers(lat, lng);
      var leafletMap = this.leafletMap(lat, lng);
      this.fitMap(leafletMap, lat, lng);
      leafletMap.on("move", function () {
        var marker = _this.getCurrentLocationMarker(leafletMap, lat, lng);

        marker.setLatLng(leafletMap.getCenter());
        leafletMap.on("dragend", function () {
          var _leafletMap$getCenter = leafletMap.getCenter(),
              lat = _leafletMap$getCenter.lat,
              lng = _leafletMap$getCenter.lng;

          _this.updateNewLatLng(lat, lng);
        });
      });
    },
    getLatLng: function getLatLng() {
      return [this.get('locationService.lat'), this.get('locationService.lng')];
    },
    getSavedLatLng: function getSavedLatLng() {
      return [this.get('model.latitude'), this.get('model.longitude')];
    },
    getNewLatLng: function getNewLatLng() {
      return [this.get('newLatitude'), this.get('newLongitude')];
    },
    updateNewLatLng: function updateNewLatLng(lat, lng) {
      this.setProperties({
        newLatitude: lat,
        newLongitude: lng
      });
    },
    getCurrentLocationMarker: function getCurrentLocationMarker(leafletMap, lat, lng) {
      var marker = this.get('currentLocationMarker');

      if (!(0, _utils.isPresent)(marker)) {
        marker = L.marker([lat, lng], {
          opacity: 0.4
        }).bindTooltip("<p>".concat(this.get('i18n').t('new_location'), "</p>"), {
          permanent: true
        });
        this.set('currentLocationMarker', marker);
      } else {
        this.set('currentLocationMarker', marker.setLatLng([lat, lng]));
      }

      marker.addTo(leafletMap).update();
      return marker;
    },
    getSavedLocationMarker: function getSavedLocationMarker(leafletMap) {
      if ((0, _utils.isPresent)(this.get('model.latitude'))) {
        var marker = this.get('savedLocationMarker');

        if (!(0, _utils.isPresent)(marker)) {
          marker = L.marker(this.getSavedLatLng()).bindTooltip("<p>".concat(this.get('model.site.Name'), "</p>"), {
            permanent: true
          });
          this.set('savedLocationMarker', marker);
        } else {
          this.set('savedLocationMarker', marker.setLatLng(this.getSavedLatLng()));
        }

        marker.addTo(leafletMap).update();
        return marker;
      } else {
        return null;
      }
    },
    fitMap: function fitMap(leafletMap, currentLat, currentLng) {
      var _this$getSavedLatLng3 = this.getSavedLatLng(),
          _this$getSavedLatLng4 = _slicedToArray(_this$getSavedLatLng3, 2),
          savedLat = _this$getSavedLatLng4[0],
          savedLng = _this$getSavedLatLng4[1];

      var _this$getNewLatLng = this.getNewLatLng(),
          _this$getNewLatLng2 = _slicedToArray(_this$getNewLatLng, 2),
          newLat = _this$getNewLatLng2[0],
          newLng = _this$getNewLatLng2[1];

      if (savedLat && savedLng && newLat && newLng) {
        var center = L.latLng([newLat, newLng]);
        var leafletBounds = L.latLngBounds([[savedLat, savedLng], [currentLat, currentLng], [newLat, newLng]]);
        var northEast = leafletBounds.getNorthEast();
        var southWest = leafletBounds.getSouthWest();
        var northEastSymmetric = [northEast.lat + (center.lat - northEast.lat) * 2, northEast.lng + (center.lng - northEast.lng) * 2];
        var southWestSymmetric = [southWest.lat + (center.lat - southWest.lat) * 2, southWest.lng + (center.lng - southWest.lng) * 2];
        leafletBounds.extend(L.latLngBounds(southWestSymmetric, northEastSymmetric));
        leafletMap.fitBounds(leafletBounds);
        return;
      }

      if (savedLat && savedLng) {
        leafletMap.fitBounds([[savedLat, savedLng], [currentLat, currentLng]]);
        return;
      }

      leafletMap.fitBounds([[currentLat, currentLng]]);
    },
    initMarkers: function initMarkers(lat, lng) {
      var leafletMap = this.leafletMap(lat, lng);
      this.getCurrentLocationMarker(leafletMap, lat, lng);
      this.getSavedLocationMarker(leafletMap);
      this.updateNewLatLng(lat, lng);
    },
    currentLocationObserver: (0, _object.observer)('locationService.lat', 'locationService.lng', function () {
      var _this2 = this;

      (0, _runloop.once)(function () {
        var _this2$getLatLng = _this2.getLatLng(),
            _this2$getLatLng2 = _slicedToArray(_this2$getLatLng, 2),
            lat = _this2$getLatLng2[0],
            lng = _this2$getLatLng2[1];

        if (lat && lng) {
          _this2.initMarkers(lat, lng);

          var leafletMap = _this2.leafletMap(lat, lng);

          _this2.fitMap(leafletMap, lat, lng);
        }
      });
    }),
    actions: {
      cancel: function cancel() {
        this.get('cancel')();
      },
      confirm: function confirm() {
        var _this3 = this;

        this.get('updateLocation')(this.get('model'), this.get('newLatitude'), this.get('newLongitude')).then(function () {
          _this3.get('toast').info(_this3.get('i18n').t('location_confirmer.success'));

          _this3.get('cancel')();
        }).catch(function () {
          _this3.get('toast').error(_this3.get('i18n').t('location_confirmer.error'));

          _this3.get('cancel')();
        });
      }
    }
  });

  _exports.default = _default;
});