define("bottomline-execution/components/questionnaire-for", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    ajax: (0, _service.inject)(),
    session: (0, _service.inject)(),
    store: (0, _service.inject)(),
    cancelAction: '',
    classNames: ['questions-modal'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      var questionListId = this.get('questionListId');
      var questionable_type = this.get('item').constructor.modelName;
      var questionable_id = this.get('item.id');
      this.get('store').query('question', {
        question_list_id: questionListId,
        questionable_type: questionable_type,
        questionable_id: questionable_id
      }).then(function (questions) {
        questions.map(function (question) {
          if (question.get('answer.id')) {
            return question.get('answer');
          } else {
            var newAnswer = _this.get('store').createRecord('answer', {
              questionable_type: questionable_type,
              questionable_id: questionable_id,
              question: question
            });

            return newAnswer;
          }
        });

        _this.set('questions', questions);
      });
    },
    actions: {
      save: function save() {
        var _this2 = this;

        var data = this.get('questions').map(function (question) {
          return _this2.get('store').serialize(question.get('answer.content'));
        });
        this.get('ajax').post('/answers', {
          data: JSON.stringify({
            answers: data
          })
        }).then(function () {
          _this2.get('afterAction')();
        }).catch(function (response) {
          _this2.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.get('questions').forEach(function (question) {
          var answer = question.get('answer.content');

          if (answer.get('isNew')) {
            answer.deleteRecord();
          }
        });
        this.get('cancelAction')();
      },
      close: function close() {
        this.get('cancelAction')();
      },
      continue: function _continue() {
        this.get('afterAction')();
      }
    }
  });

  _exports.default = _default;
});