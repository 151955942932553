define("bottomline-execution/components/f7-search-bar", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend({
    tagName: '',
    searchList: '.list-block-search',
    searchIn: undefined,
    placeholder: 'Search',
    cancelText: 'cancel',
    query: '',
    didInsertElement: function didInsertElement() {
      var searchList = this.$(this.get('searchList'));

      if (searchList.length < 1) {
        throw new Error('There is no search list available within the search bar component.');
      }

      if (searchList.length > 1) {
        throw new Error('There is more then one search list available within the search component.');
      }

      this.get('f7').initSearchbar(this.$());
    },
    onQueryChanged: function () {
      this.sendAction('action', this.get('query'));
    }.observes('query')
  });

  _exports.default = _default;
});