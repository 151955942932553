define("bottomline-execution/utils/alpha-numeric-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alphaNumericSort = alphaNumericSort;

  function alphaNumericSort(a, b) {
    return a.localeCompare(b, undefined, {
      numeric: true
    });
  }
});