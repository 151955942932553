define("bottomline-execution/utils/ensure-promise", ["exports", "rsvp"], function (_exports, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ensurePromise = ensurePromise;

  function ensurePromise(value) {
    return _rsvp.Promise.resolve(value);
  }
});