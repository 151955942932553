define("bottomline-execution/models/bx/site", ["exports", "bottomline-execution/models/site", "@ember/object/computed"], function (_exports, _site, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _site.default.extend({
    isDeliveryLocation: (0, _computed.equal)('SiteType', 'site')
  });

  _exports.default = _default;
});