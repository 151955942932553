define("bottomline-execution/models/bx/virtual-trip-stop", ["exports", "bottomline-execution/models/bx/trip-stop"], function (_exports, _tripStop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint require-yield: "off" */
  var _default = _tripStop.default.extend({
    cancel: function cancel() {
      this._super.apply(this, arguments);

      if (this.get('state') !== 'finished') {
        this.set('state', 'planned');
      }
    }
  });

  _exports.default = _default;
});