define("bottomline-execution/templates/components/trip-state-updater", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HtRPx1Fo",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"nextStates\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"additionalCssClasses\",\"isEnabled\",\"isVisible\",\"actionOnClick\",\"translationKey\"],[\"col-100\",[28,[37,3],[[30,1,[\"disabled\"]]],null],[28,[37,3],[[30,1,[\"isHidden\"]]],null],[28,[37,4],[[30,0],[30,1,[\"action\"]],[30,1],[30,0,[\"trip\"]]],null],[30,1,[\"action\"]]]]]],[1,\"\\n\"]],[1]],null]],[\"state\"],false,[\"each\",\"-track-array\",\"bl-components/button\",\"not\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/components/trip-state-updater.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});