define("bottomline-execution/services/truck-settings-ajax", ["exports", "@ember/service", "@ember/utils", "@ember/object/computed"], function (_exports, _service, _utils, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    appState: (0, _computed.alias)('shared.appState'),
    loadSettings: function loadSettings(truckId) {
      var _this = this;

      if ((0, _utils.isEmpty)(truckId)) return Promise.resolve();
      return this.get('ajax').request(this._settingsUrl(truckId)).then(function (data) {
        var settingsId = [truckId, 'settings'].join('');

        var currentSettings = _this.get('store').peekRecord('truck-setting', settingsId) || _this.get('store').createRecord('truck-setting', {
          id: settingsId
        });

        currentSettings.setProperties(data);
        currentSettings.save();

        _this.get('appState').notifyPropertyChange('truckSettings');

        return currentSettings;
      }).catch(function (e) {
        console.error('Error on endpoint, configuration not loaded', e);
      });
    },
    _settingsUrl: function _settingsUrl(truckId) {
      if (this.get('appState.useBeBackend')) return "/bottomline_execution_configuration?truck_id=".concat(truckId); // BP-7011: The truck may be part of more than one compound vehicle, this is currently not supported by BE

      var compoundVehicleId = this.get('store').peekAll('compound-vehicle').findBy('TruckID', truckId).get('id');
      var truckServiceUrl = this.get('store').adapterFor('truck').get('truckService');
      return "".concat(truckServiceUrl, "/trucks/").concat(compoundVehicleId, "/settings");
    }
  });

  _exports.default = _default;
});