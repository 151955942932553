define("bottomline-execution/templates/truck-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "6tVWXoKC",
    "block": "[[[10,0],[14,0,\"view\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"page\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"page-content\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"content-block-title\"],[12],[1,\"\\n        \"],[1,[28,[35,0],null,[[\"key\"],[\"truck_setting.settings\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"list-block\"],[12],[1,\"\\n        \"],[10,\"ul\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"col-100\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"sortedTruckSettings\"]]],null]],null],null,[[[1,\"                \"],[1,[28,[35,3],null,[[\"value\",\"label\"],[[30,1,[\"value\"]],[30,1,[\"label\"]]]]]],[1,\"\\n\"]],[1]],null],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"content-block\"],[12],[1,\"\\n        \"],[1,[28,[35,4],null,[[\"actionOnClick\",\"translationKey\"],[[28,[37,5],[[30,0],\"return\"],null],\"truck_setting.cancel\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"truckSetting\"],false,[\"editable-t\",\"each\",\"-track-array\",\"truck-setting-item\",\"bl-components/button\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/truck-settings.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});