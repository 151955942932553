define("bottomline-execution/templates/components/truck-setting-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+dCjPNtf",
    "block": "[[[10,0],[14,0,\"item-content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"item-inner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item-title label\"],[12],[1,\"\\n      \"],[1,[30,0,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"value\"]]],null],[[[1,\"      \"],[10,0],[14,0,\"item-input\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"label-switch\"],[12],[1,\"\\n          \"],[8,[39,2],[[24,\"disabled\",\"disabled\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"value\"]]]],null],[1,\"\\n          \"],[10,0],[14,0,\"checkbox\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"item-input-info full-width\"],[12],[1,\"\\n        \"],[1,[30,0,[\"value\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"is-boolean\",\"input\"]]",
    "moduleName": "bottomline-execution/templates/components/truck-setting-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});