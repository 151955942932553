define("bottomline-execution/routes/trips/index", ["exports", "@ember/object/computed", "@ember/routing/route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _computed, _route, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, {
    appState: (0, _computed.alias)('shared.appState'),
    model: function model() {
      return this.modelFor('trips').get('trips');
    },
    setupController: function setupController(controller, model) {
      var origin = controller.target.currentRouteName;
      if (origin === 'shifts.index') this._flagSiteStoragesLoaded(controller);
      var tripsToShow = model.filter(function (trip) {
        return trip.get('started');
      });
      tripsToShow.forEach(function (trip) {
        return trip.calculateEndInventoryAndCorrection();
      });
      tripsToShow.forEach(function (trip) {
        return trip.set('isShown', true);
      });

      this._super(controller, model);
    },
    _flagSiteStoragesLoaded: function _flagSiteStoragesLoaded(controller) {
      var siteStoragesLoader = this.controllerFor('application').get('siteStoragesLoader');
      siteStoragesLoader.then(function () {
        controller.set('isReportDataLoaded', true);
      });
    }
  });

  _exports.default = _default;
});