define("bottomline-execution/mixins/timestamp", ["exports", "@ember/object/mixin", "@ember/utils"], function (_exports, _mixin, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    setTimeIfEmpty: function setTimeIfEmpty(attribute) {
      var timestamp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();

      if ((0, _utils.isEmpty)(this.get(attribute))) {
        this.set(attribute, timestamp);
      }
    }
  });

  _exports.default = _default;
});